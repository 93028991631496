import React, { useEffect } from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import EmailLogo from '../../assets/email-icon.svg';
import {
  Container,
  Grid,
  Typography,
  Button,
  Divider,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  InputAdornment,
  IconButton,
  OutlinedInput,
  InputLabel,
  FormControl,
  FormHelperText,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { MuiTelInput } from 'mui-tel-input';
import PropTypes from 'prop-types';

import Responsive from '../../utils/responsive';
import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import useValidation from '../../hooks/useValidation';
import { connect } from 'react-redux';
import { sendMobForOTP } from '../Login/redux/actions/submit-mobileno-action';
import { loading } from '../../store/actions/rootActions';
import { pushMessage } from '../../components/MessageBox/redux/actions/message-box-action';
import { GAEvents, scrollToInput, sendDefaultPageEvent } from '../../utils/helper-funtions';
import { hasAnyErrorPresent } from '../../utils/validation';
import { useHistory } from 'react-router-dom';
import {
  getUserByUUID,
  setBuisnessOutReachData,
} from './redux/actions/buisness-outreach-action';
import { putOutReachUserData } from '../B2BOnboardingSteps/redux/actions/b2b-onboarding-steps-action';

const theme = createTheme();

const BusinessOutreachForm = ({
  sendMobForOTP,
  loading,
  pushMessage,
  buisnessOutReach,
  setBuisnessOutReachData,
  getUserByUUID,
  putOutReachUserData,
}) => {
  const {
    company_name,
    mobile_number,
    email,
    // business_type,
    registration_type,
  } = buisnessOutReach;
  console.log('buisnessOutReach registration_type', registration_type);

  const mobileNumberLength = 10;
  const { validateInput, errors } = useValidation();
  const history = useHistory();

  useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const extractedUuid = params.get('uuid');
    console.log('extractedUuid', extractedUuid);
    getUserByUUID(extractedUuid);
    setBuisnessOutReachData({ uuid: extractedUuid });
    sendDefaultPageEvent( null, 'BusinessOutReach', window.location.href, window.location.pathname)
  }, [setBuisnessOutReachData]);
  const onChangeCompanyName = (e) => {
    const input = e?.target?.value;
    console.log('onChangeCompanyName', input);
    const regex = /^[a-zA-Z0-9\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]*$/;
    if (regex.test(input) || input === '') {
      validateInput('company_name', input);
      setBuisnessOutReachData({ company_name: input });
    }
  };

  const onPasteCompanyName = (event) => {
    const input = event.clipboardData.getData('Text');
    const regex = /^[a-zA-Z0-9\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]*$/;
    console.log('Pasted content:', input);
    if (regex.test(input) || input === '') {
      validateInput('company_name', input);
      setBuisnessOutReachData({ company_name: input });
    }
    event.preventDefault();
  };

  const handleChange = (event, newValue) => {
    const { name, value } = event?.target || {};
    const newValueTrimmed = newValue
      ? newValue?.nationalNumber?.slice(0, 10)
      : '';

    if (name) {
      setBuisnessOutReachData({ [name]: value });
      if (name === 'email') {
        validateInput('outReachEmail', value);
      } else {
        if (name !== 'business_type') validateInput([name], value);
      }
    } else if (newValue) {
      setBuisnessOutReachData({ mobile_number: newValueTrimmed });
      validateInput('mobileNumber', newValueTrimmed);
    }
  };

  const successCallback = () => {
    loading(false);
    sessionStorage.setItem("FromOutReach", true);
    //Google GA for verify button press
    const mobileNo = mobile_number ? '<mobile_no>' : '';
    const _email = email ? '<email>' : '';
    GAEvents('BussinessOutreach', 'BussinessOutreach', 'BussinessOutreach', {
      'company_name': company_name,
      'mobile_number': mobileNo,
      'email': _email,
      "registration_type": registration_type
    })
    history.push('/otp', { fromOutReach: true, mobile_number });
  };

  const errorCallback = (message) => {
    loading(false);
    if (message) {
      pushMessage(message);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('handleSubmit errors', errors);

    if (!company_name) {
      validateInput('company_name', '');
      scrollToInput('company_name');
    } else if (!mobile_number) {
      validateInput('mobileNumber', '');
      scrollToInput('mobileNumber');
    } else if (!email) {
      validateInput('outReachEmail');
      scrollToInput('email');
    } else if (!hasAnyErrorPresent(errors)) {
      var data = { phone: mobile_number, mobile_number };
      console.log('buisnessOutReach1122', buisnessOutReach, data);
      await putOutReachUserData(
        {
          company_name,
          mobile_number,
          email,
          registration_type: registration_type || '2',
        },
        buisnessOutReach?.id,
        buisnessOutReach?.userId,
        () => {
          sendMobForOTP(data, successCallback, errorCallback);
        },
        errorCallback
      );
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xl">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: 'auto' }}
        >
          <Grid item xs={12}>
            <div className="my-3 ms-2 d-flex align-items-center">
              <ArrowBackIosIcon onClick={() => history.replace('/profile')} />
              <ElynkerLogoComponent>
                <ElynkerLogo className="ms-3" width={'100px'} height={'30px'} />
              </ElynkerLogoComponent>
            </div>
            <Divider
              component="div"
              className="main-divider mb-3"
              role="presentation"
            />

            <form style={{ marginTop: '32px' }} onSubmit={handleSubmit}>
              <Typography
                variant="h4"
                align="left"
                className="fs-26 mb-2 fw-bold font-skmodernist"
                gutterBottom
              >
                Confirm your details
              </Typography>

              <Typography
                variant="body1"
                align="left"
                className="fs-14 text-grey fw-normal font-gotham mb-3"
                gutterBottom
              >
                Register for free and connect with suppliers, retailers, and
                experts. Boost your business today!
              </Typography>

              <Grid container rowSpacing={2} columnSpacing={4} alignItems="end">
                <Grid item md={6} lg={6} xs={12}>
                  <InputLabel
                    htmlFor="companyName"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    Company Name <span className="text-danger">*</span>
                  </InputLabel>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <OutlinedInput
                      id="company_name"
                      name="company_name"
                      type="text"
                      placeholder="company name"
                      value={company_name}
                      onChange={onChangeCompanyName}
                      onPaste={onPasteCompanyName}
                      error={!!errors.company_name}
                      helperText={errors.company_name}
                    />
                  </FormControl>
                  <FormHelperText
                    sx={{ color: 'red' }}
                    id="company-name-helper-text"
                  >
                    {errors.company_name}
                  </FormHelperText>
                </Grid>

                <Grid item md={6} lg={6} xs={12}>
                  <InputLabel
                    htmlFor="mobileNumber"
                    className="text-start fs-14 text-black fw-normal font-skmodernist"
                  >
                    Mobile Number <span className="text-mandatory">*</span>
                    <br />
                    <span className="text-gray">
                      (OTP will be sent to this number)
                    </span>
                  </InputLabel>
                  <div className="position-relative mb-0">
                    <MuiTelInput
                      forceCallingCode
                      focusOnSelectCountry
                      color={'error'}
                      onlyCountries={['IN', 'AU']}
                      defaultCountry={'IN'}
                      fullWidth
                      sx={{ my: 1 }}
                      value={mobile_number}
                      disableDropdown
                      name="mobileNumber"
                      id="mobileNumber"
                      // error={errors.mobileNumber}
                      // helperText={errors.mobileNumber}
                      onChange={handleChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <div>
                              {`${mobile_number?.length} /
                                ${mobileNumberLength}`}
                            </div>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <FormHelperText
                    sx={{ color: 'red' }}
                    id="mobile-number-helper-text"
                  >
                    {errors.mobileNumber}
                  </FormHelperText>
                </Grid>

                <Grid item md={6} lg={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel
                      id="business-type-radio"
                      className="fs-14 fw-normal font-skmodernist text-black"
                    >
                      What is your Business Type
                      <span className="text-mandatory">*</span>
                    </FormLabel>
                    <Divider
                      component="div"
                      className="divider mt-3 mb-1"
                      role="presentation"
                    />
                    <RadioGroup
                      row
                      aria-labelledby="business-type-radio"
                      name="business_type"
                      id="business_type"
                      value={registration_type || '2'}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label="Product"
                      />
                      <FormControlLabel
                        value="3"
                        control={<Radio />}
                        label="Service"
                      />
                    </RadioGroup>
                    <Divider
                      component="div"
                      className="divider mb-1 mt-1"
                      role="presentation"
                    />
                  </FormControl>
                  <FormHelperText
                    sx={{ color: 'red' }}
                    id="company-name-helper-text"
                  >
                    {errors.business_type}
                  </FormHelperText>
                </Grid>

                <Grid item md={6} lg={6} xs={12}>
                  <InputLabel
                    htmlFor="email"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    Email ID<span className="text-mandatory">*</span>
                  </InputLabel>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <OutlinedInput
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Enter your email id"
                      value={email}
                      onChange={handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle email visibility"
                            edge="end"
                          >
                            <img
                              src={EmailLogo}
                              alt="email-logo"
                              className="email-logo"
                            />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <FormHelperText
                      sx={{ color: 'red' }}
                      id="company-name-helper-text"
                    >
                      {errors.outReachEmail}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>

              {Responsive(
                <>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    className="mt-5 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
                    sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
                  >
                    Verify With OTP
                  </Button>
                </>,
                <>
                  <Box className="d-flex justify-content-end">
                    <Button
                      type="submit"
                      variant="contained"
                      className="mt-5 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
                      sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
                    >
                      Verify With OTP
                    </Button>
                  </Box>
                </>
              )}

              <Typography className="my-4 text-grey fs-13 font-gotham">
                By signing up, you agree to <b>Terms.</b> See how we use your
                data in our <b>Privacy Policy.</b>
              </Typography>
            </form>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};
const mapStateToProps = (state) => {
  return {
    mobile_number: state.mobile.mobile_number,
    country_code: state.mobile.country_code,
    country_code_name: state.mobile.country_code_name,
    isLoading: state.root.isLoading,
    all_template: state.mobile.all_template,
    // set_template: state.template.set_template
    enquiry: state.b2bVendorlisting,
    buisnessOutReach: state.buisnessOutReach.formState,
  };
};
BusinessOutreachForm.propTypes = {
  country_code_name: PropTypes.string,
  mobile_number: PropTypes.string,
  country_code: PropTypes.string,
  sendMobForOTP: PropTypes.func,
  loading: PropTypes.any,
  pushMessage: PropTypes.func,
  buisnessOutReach: PropTypes.any,
  setBuisnessOutReachData: PropTypes.any,
  getUserByUUID: PropTypes.func,
  putOutReachUserData: PropTypes.func,
};

export default connect(mapStateToProps, {
  sendMobForOTP,
  loading,
  pushMessage,
  setBuisnessOutReachData,
  getUserByUUID,
  putOutReachUserData,
})(BusinessOutreachForm);
