import React, { useState, useEffect, useRef } from 'react';
import '../../../common/fontsize.css';
import '../../../index.css';
import {
  Container,
  Grid,
  Typography,
  Button,
  Backdrop,
  CircularProgress,
  Box,
  FormHelperText,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Dialog,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import { ReactComponent as ElynkerLogo } from '../../../assets/elynker-logo.svg';
// import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import AddBtnIcon from '../../../assets/add-btn-icon.svg';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as DownloadIcon } from '../../../assets/download-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/cat-delete-icon.svg';
import { ReactComponent as CloseIcon } from '../../../assets/close-icon.svg';
import Accordion from '@mui/material/Accordion';
// import Resizer from 'react-image-file-resizer';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { connect } from 'react-redux';
import { loading } from '../../../store/actions/rootActions';
import { updateUserData } from '../FLOnboardingSteps/redux/actions/fl-onboarding-steps-action';
import PropTypes from 'prop-types';
import {
  deleteBanner,
  getBannerData,
  uploadBannerData,
} from './redux/actions/fl-add-bio-action';
import moment from 'moment/moment';
import { saveAs } from 'file-saver';
import { BANNER_BASE_URL } from '../../../utils/APIs/api';
import { pushMessage } from '../../../components/MessageBox/redux/actions/message-box-action';
import { useMediaQuery } from 'react-responsive';
import useValidation from '../../../hooks/useValidation';
import { compressImages } from '../../../utils/compressFile';
import { ReactComponent as ResumeIcon } from '../../../assets/resume-icon.svg';
import { stickyButtonStyle } from '../../../utils/sx-styles';
import { GAEvents, scrollToInput } from '../../../utils/helper-funtions';
import { CLICKED, DIALOG_TITLE, FL_ADDBIO_TITLE, LOAD } from '../../../utils/constants';

const theme = createTheme();
const categoryText = 'Great. Now Write a Bio to Tell the World About Yourself';

const FLAddBioForm = ({
  onNextHandler,
  onBackHandler,
  userData,
  updateUserData,
  getBannerData,
  projects,
  uploadBannerData,
  isLoading,
  loading,
  deleteBanner,
  pushMessage,
}) => {
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' });
  // const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  // const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  // const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });
  // const accordianRef = React.useRef(null);
  // React.useEffect(() => {
  //   console.log(accordianRef.current.target);
  // }, []);
  const { validateInput, errors } = useValidation();
  const [count, setCount] = useState(
    userData?.registration?.freelancer_bio?.length | 0
  );
  const [addBio, setAddBio] = useState(userData?.registration?.freelancer_bio);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [doc, setDoc] = useState();
  const bannerUploadRef = useRef(null);

  useEffect(() => {
    GAEvents(FL_ADDBIO_TITLE, "Freelancer Onboarding", LOAD,{
      page_title: 'About you',
      page_location: window.location.href,
      page_path: window.location.pathname,
      user_id: userData?.id || sessionStorage.getItem('random_id'),
      reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id')
  });
    console.log('User data : ' + userData?.id);
    getBannerData(userData?.registration?.id);
  }, []);

  function successCallback(res) {
    //clear data
    pushMessage(res?.message);
    getBannerData(userData?.registration?.id);
  }

  function errorCallback(err) {
    const type = 'error';
    // validateInput('flWorkUpload', err);
    pushMessage(err, type);
  }

  const onDeleteSuccess = (res) => {
    console.log('===onDeleteSuccess', res);
    loading(false);
    pushMessage(res?.message);
    getBannerData(userData?.registration?.id);
  };

  function nextSuccessCallback() {
    //clear data
    loading(false);
    onNextHandler();
  }

  // const compressImages = (files) => {
  //     return Promise.all(
  //         Array.from(files).map((file) => {
  //             return new Promise((resolve) => {
  //                 Resizer.imageFileResizer(
  //                     file,
  //                     500, // New width
  //                     500, // New height
  //                     'JPEG', // Format
  //                     75, // Quality
  //                     0, // Rotation
  //                     (uri) => {
  //                         resolve(uri);
  //                     },
  //                     'file'
  //                 );
  //             });
  //         })
  //     );
  // };
  const handleBannerUploadFile = async (e) => {
    const selectedFiles = Array.from(e.target.files);

    if (selectedFiles.length === 0) return;

    const allowedFileTypes = [
      'application/pdf',
      'image/jpeg',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];

    const filteredFiles = selectedFiles.filter((file) =>
      allowedFileTypes.includes(file.type)
    );

    if (filteredFiles.length === 0) {
      pushMessage('Please upload a PDF, JPEG, or DOCX file.');
      return;
    }

    const validFiles = [];
    for (let file of filteredFiles) {
      if (file.size > 2000000) {
        validateInput('flWorkUpload', file.size);
        return;
      } else {
        validateInput('flWorkUpload', 0);
      }

      let compressedFile;
      if (file.type.startsWith('image/')) {
        compressedFile = await compressImages([file]);
      } else {
        compressedFile = [file];
      }

      validFiles.push(compressedFile[0]);
    }

    loading(true);
    console.log('compressedFiles:', validFiles);

    for (let file of validFiles) {
      await uploadBannerData(
        file,
        file?.name,
        userData?.registration?.id,
        successCallback,
        errorCallback
      );
    }

    loading(false);
    e.target.value = null;
  };

  const handleBannerUploadClick = () => {
    bannerUploadRef.current.click();
  };

  const handleBackButton = async () => {
    loading(true);
    let newUserData = {
      active_steps: 0,
      steps_completed: false,
      freelancer_bio: addBio,
    };
    await updateUserData(
      userData,
      newUserData,
      userData.registration.id,
      userData.accessToken,
      onBackHandler
    );
    loading(false);
  };

  const submitBio = async () => {
    if (!addBio) {
      validateInput('about', '');
      scrollToInput('about');
      return;
    }
    loading(true);
    let newUserData = {
      active_steps: 3,
      steps_completed: false,
      freelancer_bio: addBio,
    };
    console.log('==userData+newUserData', newUserData);
    await updateUserData(
      userData,
      newUserData,
      userData.registration.id,
      userData.accessToken,
      nextSuccessCallback
    );
  };

  const onChangeBio = (e) => {
    if (e.target.value.length > 5000) {
      pushMessage('The number of characters exceeded.');
      return;
    } else {
      let bio = e.target.value;
      setCount(bio.length);
      setAddBio(bio);
      validateInput('about', bio);
    }
  };

  async function handleSkipButton() {
    loading(true);
    let newUserData = {
      active_steps: 3,
      steps_completed: false,
    };
    await updateUserData(
      userData,
      newUserData,
      userData.registration.id,
      userData.accessToken
    );
    loading(false);
    onNextHandler();
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="lg">
        {isLoading && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          maxWidth="sm"
          fullWidth
        >
          {/* <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle> */}
          <DialogTitle className="custom-border-header pb-0">
            <Box className="position-relative d-flex justify-content-between">
              <Typography
                className="fs-22 fw-bold font-skmodernist"
                id="alert-dialog-title"
              >
                {DIALOG_TITLE}
              </Typography>
              <Box>
                <CloseIcon
                  onClick={() => {
                    handleClose();
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete the Project?
            </DialogContentText>
          </DialogContent>

          <DialogActions className="modal-footer">
            <Button onClick={handleClose} className="btn-cancel me-2">
              No
            </Button>
            <Button
              onClick={() => {
                handleClose();
                deleteBanner(doc?.id, onDeleteSuccess, () =>
                  console.log('Please try again, Error while deleting project.')
                );
              }}
              className="btn-save"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: '67vh' }}
        >
          <Grid item xs={12}>
            {/* <div className='my-3 ms-2'>
                            <ElynkerLogo width={'100px'} height={'30px'} />
                        </div>
                        <Divider component="div" className="main-divider mb-3" role="presentation" /> */}
            <Typography
              variant="h4"
              align="left"
              alignItems="center"
              className="fs-22 mt-4 mb-2 fw-bold font-skmodernist"
              gutterBottom
              style={{ whiteSpace: 'pre-line' }}
            >
              {categoryText}
            </Typography>

            <Typography
              variant="body1"
              align="left"
              className="fs-14 text-grey fw-normal font-gotham"
              gutterBottom
            >
              Help people get to know you at a glance. What work do you do best?
              Tell them clearly, using paragraphs or bullet points. You can
              always edit later; just make sure you proofread now.
            </Typography>

            <form
              style={{ marginTop: '40px' }}
              onSubmit={(e) => {
                e.preventDefault();
                GAEvents(FL_ADDBIO_TITLE, "Freelancer Onboarding", CLICKED,{
                  userId: userData?.id || sessionStorage.getItem('random_id'),
                  reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                  title: "Next"
                });
                submitBio();
              }}
            >
              <Grid md={7} lg={7} xs={12} className="mb-3">
                <div className="d-flex justify-content-between">
                  <InputLabel
                    htmlFor="inputCompanyName"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    About you <span className="text-mandatory">*</span>
                  </InputLabel>
                  <Typography
                    variant="body1"
                    align="left"
                    className="fs-12 text-lightgrey fw-normal mb-0 font-gotham"
                    gutterBottom
                  >
                    {5000 - count} Characters Left
                  </Typography>
                </div>

                <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                  <TextareaAutosize
                    id="inputAdditionalInfo"
                    placeholder="Enter about you"
                    minRows={3} // You can adjust the number of rows as needed
                    maxRows={2} // You can adjust the number of rows as needed
                    maxLength={5000}
                    onChange={onChangeBio}
                    value={addBio}
                  />
                </FormControl>
                <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                  {errors?.about}
                </FormHelperText>
              </Grid>
              <Grid
                md={5}
                lg={5}
                xs={12}
                className=" mb-3"
                sx={{
                  marginBottom: isTabletOrMobile ? 100 : 0,
                }}
              >
                <InputLabel
                  htmlFor="inputCompanyName"
                  className="text-start fs-14 text-black font-skmodernist"
                >
                  Upload your work
                </InputLabel>

                <input
                  type="file"
                  style={{ display: 'none' }}
                  ref={bannerUploadRef}
                  onChange={handleBannerUploadFile}
                  multiple
                  id="file"
                  accept=".doc,.docx,.pdf,image/png, image/jpeg"
                />
                <Button
                  type="button"
                  fullWidth
                  variant="outlined"
                  className="add-button mt-2 fs-15 font-skmodernist fw-bold"
                  sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
                  onClick={()=>{
                    GAEvents(FL_ADDBIO_TITLE, "Freelancer Onboarding", CLICKED,{
                      userId: userData?.id || sessionStorage.getItem('random_id'),
                      reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                      title: "Upload"
                    });
                    handleBannerUploadClick();
                  }}
                >
                  <img
                    src={AddBtnIcon}
                    alt="add-btn-icon"
                    className="user-logo"
                  />
                  <Typography className="ms-2 text-primary-1">
                    Upload
                  </Typography>
                </Button>
                <Typography class="text-muted fs-12">
                  (PDF or DOCX or JPEG files only, maximum size: 2 MB)
                </Typography>
                <FormHelperText
                  sx={{ color: 'red' }}
                  id="work-upload-helper-text"
                >
                  {errors?.flWorkUpload}
                </FormHelperText>
              </Grid>

              <Grid
                md={5}
                lg={5}
                xs={12}
                className="custom-accordion product-accordion"
              >
                <Accordion
                  // defaultExpanded={
                  //   projects && projects.length > 0 ? true : false
                  // }
                  defaultExpanded={true}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="fs-15 font-skmodernist text-black fw-bold"
                  >
                    Projects
                  </AccordionSummary>
                  <AccordionDetails>
                    {projects &&
                      projects?.map((item, index) => {
                        const formatedDate = moment(item?.createdAt).format(
                          'DD-MM-YYYY'
                        );
                        console.log('Formatted date : ' + formatedDate);
                        const filename = item?.banner_image?.replace(
                          /^.*[\\/]/,
                          ''
                        );
                        return (
                          <div
                            className="d-flex justify-content-between align-items-center my-2"
                            key={index}
                          >
                            <div>
                              <div className="d-flex align-items-center">
                                <div>
                                  <ResumeIcon style={{ width: '20px' }} />
                                </div>
                                <Typography
                                  variant="body1"
                                  align="left"
                                  className="fs-14 text-black fw-normal mb-0 ms-3 font-skmodernist"
                                  gutterBottom
                                  sx={{
                                    wordBreak: 'break-all',
                                  }}
                                >
                                  {filename}
                                </Typography>
                              </div>
                              <div style={{ marginLeft: '36px' }}>
                                <Typography
                                  variant="body1"
                                  align="left"
                                  className="fs-13 text-grey fw-normal mb-0 ms-0 font-gotham"
                                  gutterBottom
                                >
                                  Uploaded on: {formatedDate}
                                </Typography>
                              </div>
                            </div>
                            <div className="d-flex">
                              {/* <a  className="cat-edit-icon"
                                                            href="#"
                                                            download
                                                            onClick={e => downloadFile(e, item)}
                                                            style={{alignSelf: "center"}}
                                                        >
                                                            <DownloadIcon />
                                                        </a> */}
                              {/* <IconButton
                                                            className="cat-edit-icon"
                                                            onClick={()=>{
                                                                downloadPDF(item);
                                                            }
                                                            }
                                                        >
                                                            <DownloadIcon />
                                                        </IconButton> */}
                              <IconButton
                                className="cat-edit-icon"
                                onClick={() => {
                                  GAEvents(FL_ADDBIO_TITLE, "Freelancer Onboarding", CLICKED,{
                                    userId: userData?.id || sessionStorage.getItem('random_id'),
                                    reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                    title: "Download"
                                  });
                                  saveAs(
                                    BANNER_BASE_URL + item?.banner_image,
                                    item?.banner_image
                                  );
                                  // if(item?.freelancer_resume.includes('.pdf')){
                                  //     downloadPDF(item);
                                  // }else{
                                  //     saveAs(RESUME_BASE_URL + item?.freelancer_resume, item?.freelancer_resume)
                                  // }
                                }}
                              >
                                <DownloadIcon />
                              </IconButton>
                              <IconButton
                                className="cat-delete-icon"
                                onClick={() => {
                                  GAEvents(FL_ADDBIO_TITLE, "Freelancer Onboarding", CLICKED,{
                                    userId: userData?.id || sessionStorage.getItem('random_id'),
                                    reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                    title: "Delete"
                                  });
                                  setDoc(item);
                                  handleOpen();
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          </div>
                        );
                      })}
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <div style={{ marginTop: isTabletOrMobile ? '200px' : '30px' }}>
                {isTabletOrMobile && (
                  <Box sx={{ width: '100%' }}>
                    <Box sx={[stickyButtonStyle]}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        className="mt-4 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
                        sx={{
                          borderRadius: '6px',
                          textTransform: 'capitalize',
                        }}
                      >
                        Next Step
                      </Button>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Button
                          variant="text"
                          type="button"
                          fullWidth
                          className="mt-2 mb-3  fs-15 text-capitalize fw-semibold text-black"
                          onClick={()=>{
                            GAEvents(FL_ADDBIO_TITLE, "Freelancer Onboarding", CLICKED,{
                              userId: userData?.id || sessionStorage.getItem('random_id'),
                              reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                              title: "Back"
                            });
                            handleBackButton();
                          }}
                        >
                          Back
                        </Button>
                        <Button
                          onClick={()=>{
                            GAEvents(FL_ADDBIO_TITLE, "Freelancer Onboarding", CLICKED,{
                              userId: userData?.id || sessionStorage.getItem('random_id'),
                              reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                              title: "Skip for Now"
                            });
                            handleSkipButton();
                          }}
                          variant="text"
                          type="button"
                          fullWidth
                          className="mt-2 mb-3  fs-15 text-capitalize fw-semibold text-black"
                        >
                          Skip for Now
                        </Button>
                      </div>
                    </Box>
                  </Box>
                )}

                {isDesktopOrLaptop && (
                  <>
                    <Box className="d-flex justify-content-between align-items-center my-4">
                      <Button
                        variant="text"
                        type="button"
                        className="btn-web-back"
                        onClick={()=>{
                          GAEvents(FL_ADDBIO_TITLE, "Freelancer Onboarding", CLICKED,{
                            userId: userData?.id || sessionStorage.getItem('random_id'),
                            reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                            title: "Back"
                          });
                          handleBackButton();
                        }}
                      >
                        Back
                      </Button>
                      <Button
                        onClick={()=>{
                          GAEvents(FL_ADDBIO_TITLE, "Freelancer Onboarding", CLICKED,{
                            userId: userData?.id || sessionStorage.getItem('random_id'),
                            reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                            title: "Skip for Now"
                          });
                          handleSkipButton();
                        }}
                        variant="text"
                        type="button"
                        className="btn-web-back"
                      >
                        Skip for Now
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        className="btn-web-next"
                      >
                        Next Step
                      </Button>
                    </Box>
                  </>
                )}
              </div>
            </form>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

FLAddBioForm.propTypes = {
  updateUserData: PropTypes.func,
  loading: PropTypes.func,
  isLoading: PropTypes.bool,
  userData: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  onNextHandler: PropTypes.func,
  onBackHandler: PropTypes.func,
  registration: PropTypes.object,
  getBannerData: PropTypes.func,
  projects: PropTypes.array,
  uploadBannerData: PropTypes.func,
  deleteBanner: PropTypes.func,
  pushMessage: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { userData, registration } = state.signin;
  const { isLoading } = state.root;
  const { projects } = state.flAddBio;

  // console.log("State :", state);
  return {
    isLoading,
    userData,
    registration,
    projects,
  };
};
export default connect(mapStateToProps, {
  updateUserData,
  loading,
  getBannerData,
  uploadBannerData,
  deleteBanner,
  pushMessage,
})(FLAddBioForm);
