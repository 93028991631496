/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect, useMemo } from 'react';
import '../../../common/fontsize.css';
import '../../../index.css';
import {
  Container,
  Grid,
  Typography,
  Button,
  TextField,
  Backdrop,
  CircularProgress,
  Box,
  Autocomplete,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import { ReactComponent as ElynkerLogo } from '../../../assets/elynker-logo.svg';
import Divider from '@mui/material/Divider';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import AddBtnIcon from '../../../assets/add-btn-icon.svg';
import ImageDialog from '../../../components/ImageOverlay';

import { ReactComponent as AddCertificationIcon } from '../../../assets/add-certification-icon.svg';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
// import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
// import { ReactComponent as CloseIcon } from '../../../assets/close-icon.svg';
import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as EditIcon } from '../../../assets/cat-edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/cat-delete-icon.svg';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loading } from '../../../store/actions/rootActions';
import {
  formatedDate,
  DATE_MIN_YEAR,
  DATE_MAX_YEAR,
  DIALOG_TITLE,
  FL_PROFILE_DETAILS_TITLE,
  CLICKED,
  LOAD,
} from '../../../utils/constants';
import {
  COMPANY_LOGO_BASE_URL,
  POSTOFFICE_PINCODE,
} from '../../../utils/APIs/api';
import {
  addCertificateData,
  deleteCertificateData,
  getCertificateData,
  getMasterEducationDetails,
  getMasterLanguageDetails,
  resetFLProfileData,
  setAddress1,
  setAddress2,
  setAreaName,
  setCity,
  setPincode,
  // setLocationCoords,
  setProfileDate,
  setProfileEducation,
  setProfileImage,
  setProfileLanguage,
  setState,
  updateCertificateData,
} from './redux/actions/fl-profile-details-action';
import moment from 'moment/moment';
// import Chip from '@mui/material/Chip';
import {
  setWhatsappNumber,
  uploadCompanyLogoImage,
} from '../../ProfileDetails/redux/actions/profile-details-form-action';
import { updateUserData } from '../FLOnboardingSteps/redux/actions/fl-onboarding-steps-action';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { pushMessage } from '../../../components/MessageBox/redux/actions/message-box-action';
import axios from 'axios';

import {
  GAEvents,
  handleErrorsAndScroll,
  scrollToInput,
} from '../../../utils/helper-funtions';

import CustomDropdown from '../../../components/CustomDropdown/CustomDropdown';
// import { ReactComponent as LocatemeLogo } from '../../../assets/locateme-icon.svg';
import useValidation from '../../../hooks/useValidation';
import { hasAnyErrorPresent } from '../../../utils/validation';
import { useMediaQuery } from 'react-responsive';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';

const theme = createTheme();
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const categoryText =
  'A few last Details, then you can check and Publish your Profile';

import { compressImages } from '../../../utils/compressFile';
import { stickyButtonStyle } from '../../../utils/sx-styles';

const FLProfileDetailsForm = ({
  onNextHandler,
  loading,
  onBackHandler,
  isLoading,
  profileImage,
  userData,
  getCertificateData,
  certificates,
  addCertificateData,
  updateCertificateData,
  deleteCertificateData,
  setProfileImage,
  setProfileEducation,
  education,
  updateUserData,
  resetFLProfileData,
  setProfileDate,
  profileDob,
  setProfileLanguage,
  language,
  uploadCompanyLogoImage,
  setAddress1,
  setAddress2,
  city,
  states,
  address1,
  address2,
  setCity,
  // allCity,
  // allState,
  setState,
  locationCoords,
  // setLocationCoords,
  // getCityMaster,
  // getStateMaster,
  pushMessage,
  getMasterEducationDetails,
  select_education,
  getMasterLanguageDetails,
  select_language,
  setWhatsappNumber,
  whatsapp,
  areaname,
  setAreaName,
  setPincode,
  // pincode,
}) => {
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' });
  // const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  // const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  // const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });
  const history = useHistory();
  // const [dobDate, setDOBDate] = useState(null);//moment()
  // const [profEdu, setProfEdu] = useState();
  // const [language, setLanguage] = useState();
  console.log('profileDob1234', profileDob);

  const profileUploadRef = useRef(null);
  const [doc, setDoc] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [defaultPincodeVal, setDefaultPincodeVal] = useState({ pincode: [''] });

  const [mapOtions, setMapOtions] = useState([]);
  const [searchLoad, setSearchLoad] = useState(false);
  const [locSearchVal, setLocSearchVal] = useState('');

  const [open, setOpen] = useState(false);
  const closePopper = () => setOpen(false);
  const openPopper = () => setOpen(true);
  const searchLocInpRef = useRef(null);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawer = () => {
    if (isDrawerOpen) {
      setIsEditing(false);
      setCertName('');
      setCertDesc('');
      setCertDate(null);
    }
    setIsDrawerOpen(!isDrawerOpen);
  };
  const [certName, setCertName] = useState('');
  const [certDesc, setCertDesc] = useState('');
  const [certDate, setCertDate] = useState(null); //moment()
  const mobile_number = userData.user?.mobile_number;
  const isExistingMobileasWhatsapp = whatsapp === mobile_number ? true : false;
  const [isNotExistingMobile, setisNotExistingMobile] = React.useState(
    isExistingMobileasWhatsapp
  );
  const hanldleIsExistingMobile = () => {
    setWhatsappNumber(userData.user?.mobile_number);
    setisNotExistingMobile((prev) => !prev);
  };
  const handleWhatsappChange = (e) => {
    setisNotExistingMobile(false);
    const input = e.target.value;
    const regex = /^[0-9]{0,10}$/;
    if (regex.test(input)) setWhatsappNumber(input);
    validateInput('whatsapp', input);
  };
  // for reupload photo modal
  const [openReupload, setOpenReupload] = useState(false);
  const handleOpenReupload = () => setOpenReupload(true);
  const handleCloseReupload = () => setOpenReupload(false);

  //for delete modal
  const [openDelModal, setDelModalOpen] = useState(false);
  const handleOpen = () => setDelModalOpen(true);
  const handleClose = () => setDelModalOpen(false);

  const { validateInput, errors } = useValidation();

  const handleAddress1Change = (e) => {
    const input = e.target.value;
    setAddress1(input);
    validateInput('flAddress1', input);
  };

  function onDeleteSuccess(res) {
    pushMessage(res?.message);
    getCertificateData(userData?.registration?.id);
  }

  // async function getCurrentLocation() {
  //   if ('geolocation' in navigator) {
  //     navigator.geolocation.getCurrentPosition(async function (position) {
  //       setLocationCoords({
  //         latitude: position.coords.latitude,
  //         longitude: position.coords.longitude,
  //       });
  //       try {
  //         // const response = await geocodeByLatLng({
  //         //   lat: position.coords.latitude,
  //         //   lng: position.coords.longitude,
  //         // });
  //         // // console.log('latlong address', response);
  //         // if (response.length > 0) {
  //         //   handleLatLongGeocodeResponse(response[0]);
  //         // }
  //         const response = await axios.get(
  //           `https://api.mapbox.com/search/searchbox/v1/reverse?longitude=${position.coords.longitude}&latitude=${position.coords.latitude}&language=${LANG}&access_token=${MAPBOX_ACC}`
  //         );
  //         if (response.status === 200) {
  //           console.log('REVERSE SEARCH RES', response.data);
  //           setMapOtions(response.data?.features);
  //           setOpen(true);
  //         }
  //       } catch (error) {
  //         console.log('Error fetch address by coordinates', error);
  //       }
  //     });
  //   } else {
  //     console.log('Geolocation is not available in your browser.');
  //   }
  // }

  // const handleLocateMe = async () => {
  //   const res = await handleLocationPermission(pushMessage);
  //   if (res.result) {
  //     if (res.result.state === 'granted') {
  //       getCurrentLocation();
  //     } else {
  //       pushMessage('Location Permission not granted.');
  //     }
  //   }
  // };

  // const handleLangChange = (newChips) => {
  //     if (newChips.length > 3) {
  //         pushMessage("You can add upto 3 languages.")
  //         return;
  //     }
  //     console.log("SElected Language :", newChips);
  //     // setChips(newChips)
  //     setProfileLanguage(newChips);
  // }

  /* eslint-disable no-unused-vars */
  const updateUserDataSuccess = (res) => {
    //reset profile data state
    resetFLProfileData();
    onNextHandler();
    history.replace('/congratulations');
  };

  const updateUserDataError = (err) => {};
  /* eslint-enable no-unused-vars */

  const submitFLProfileDetails = async () => {
    if (!education || education?.length === 0) {
      // alert('Please add your education');
      validateInput('flEducation', '');
      scrollToInput('flEducation');
      return;
    }
    if (!address1) {
      // alert('Please add your address');
      validateInput('flAddress1', address1);
      scrollToInput('flAddress1');
      return;
    }

    if (!address2) {
      // alert('Please search and select your address');
      validateInput('flAddress2', '');
      scrollToInput('flAddress2');
      return;
    }

    if (!defaultPincodeVal || !defaultPincodeVal?.pincode[0]) {
      validateInput('pincode', '');
      return;
    }

    try {
      if (profileImage) {
        if (profileImage.length > 0) {
          if (profileImage[0].name) loading(true);
          await uploadCompanyLogoImage(
            profileImage,
            userData.registration.id,
            userData.accessToken
          );
          loading(false);
        }
      }
    } catch (err) {
      console.log('FL Upload photo ', err);
    }

    if (!hasAnyErrorPresent(errors)) {
      console.log('language', language);

      let langStr = '';

      language.forEach((itm, idx) => {
        if (idx === language?.length - 1) {
          langStr += itm.value;
        } else {
          langStr += itm.value + ',';
        }
      });
      //   console.log('profileDob123', profileDob);

      let newUserData = {
        active_steps: 6,
        steps_completed: true,
        education: education?.status,
        language: langStr || '',
        ...(whatsapp && { whatsapp_number: whatsapp }),
        ...(profileDob && { dob: moment(profileDob).format('YYYY-MM-DD') }),
        address1: address1,
        address2: areaname,
        latitude: `${locationCoords?.latitude}`,
        longitude: `${locationCoords?.longitude}`,
        city: city,
        state: states,
        pincode: defaultPincodeVal?.pincode[0]?.toString(),
      };
      // console.log(newUserData);
      loading(true);
      await updateUserData(
        userData,
        newUserData,
        userData?.registration?.id,
        userData?.accessToken,
        updateUserDataSuccess,
        updateUserDataError
      );

      loading(false);
      // onNextHandler();
    } else {
      handleErrorsAndScroll(errors);
    }
  };

  function successCallback(res) {
    //clear data
    // getResumeData(userData?.registration?.id);
    loading(false);
    pushMessage(res?.message);
    setIsEditing(false);
    setCertName('');
    setCertDesc('');
    setCertDate(null);
    setIsDrawerOpen(false);
    loading(false);
    getCertificateData(userData?.registration?.id);
  }

  function errorCallback(err) {
    const type = 'error';
    pushMessage(err?.response?.data?.error, type);
    loading(false);
  }

  const handleProfileUploadFile = async (e) => {
    const selectedFile = e?.target?.files;
    if (
      selectedFile[0]?.type.includes('jpeg') ||
      selectedFile[0]?.type.includes('jpg') ||
      selectedFile[0]?.type.includes('png')
    ) {
      let compressedImage = await compressImages(selectedFile);
      setProfileImage(compressedImage);
      console.log('Resume File:', compressedImage);
    } else {
      pushMessage('Please add Valid file type');
    }
  };
  const handleProfileImageClick = () => {
    if (profileImage !== null && profileImage?.length > 0) {
      // show alert message
      handleOpenReupload();
    } else {
      profileUploadRef.current.click();
    }
  };
  // const onEducationChange = async (e) => {
  //     const input = e.target.value;
  //     setProfileEducation(e.target.value);
  //     validateInput('flEducation', input);
  // }

  const onEducationChange = (e, value) => {
    let obj = { ...education };
    // console.log("values@@:",value)

    obj.status = value?.value;
    console.log('object values :', obj.status);
    validateInput('flEducation', obj?.status);
    setProfileEducation(obj);
  };

  const saveCertificate = () => {
    // console.log("===doc.id, certName, certDesc, certDate?.format('YYYY-MM-DD').toString(), userData?.registration?.id", doc.id, certName, certDesc, certDate?.format('YYYY-MM-DD').toString(), userData?.registration?.id);
    if (certName == '' || certName?.length == 0) {
      pushMessage('Name field is required.');
      return;
    }
    console.log('Cert date: ' + certDate?.format('YYYY-MM-DD').toString());
    console.log('cert date type : ' + typeof certDate);
    if (isEditing) {
      updateCertificateData(
        doc.id,
        certName,
        certDesc,
        certDate && certDate?.format('YYYY-MM-DD').toString(),
        userData?.registration?.id,
        successCallback,
        errorCallback
      );
    } else {
      addCertificateData(
        certName,
        certDesc,
        certDate && certDate.format('YYYY-MM-DD').toString(),
        userData?.registration?.id,
        successCallback,
        errorCallback
      );
    }

    //close drawer
    //hide loading
  };

  const handleCertNameChange = (e) => {
    const input = e.target.value;
    setCertName(input);
  };
  const handleCertDescChange = (e) => {
    const input = e.target.value;
    setCertDesc(input);
  };

  useEffect(() => {
    GAEvents(FL_PROFILE_DETAILS_TITLE, "Freelancer Onboarding", LOAD,{
      page_title: 'Profile Details',
      page_location: window.location.href,
      page_path: window.location.pathname,
      userId: userData?.id || sessionStorage.getItem('random_id'),
      reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id')
  });
    getCertificateData(userData?.registration?.id);
  }, []);

  // const getAddress2DefaultVal = () => {
  //   const address2Val = userData?.registration?.address2;
  //   if (address2Val) {
  //     return address2Val;
  //   } else {
  //     return null;
  //   }
  // };

  useEffect(() => {
    const getData = setTimeout(async () => {
      if (locSearchVal && searchLoad) {
        try {
          const response = await axios.get(
            // `https://api.mapbox.com/search/searchbox/v1/forward?q=${locSearchVal}&language=${LANG}&limit=${LIMIT}&country=${COUNTRY}&access_token=${MAPBOX_ACC}`
            POSTOFFICE_PINCODE + locSearchVal
          );
          if (response.status === 200) {
            if (response?.data?.status_code == 200) {
              console.log('SEARCH RESULT', response.data);
              setMapOtions(response.data?.data);
              setSearchLoad(false);
            } else {
              setMapOtions([]);
              setSearchLoad(false);
            }
          } else {
            pushMessage('No City Found!!');
          }
        } catch (err) {
          console.log('');
        }
      }
    }, 250);

    return () => clearTimeout(getData);
  }, [locSearchVal]);

  // const getLocationString = (option) => {
  //   const fulladdress = option?.properties?.full_address
  //     ? `${option?.properties?.full_address}`
  //     : '';
  //   const address = option?.properties?.address
  //     ? `${option?.properties?.address}, `
  //     : '';
  //   const name = option?.properties?.name
  //     ? `${option?.properties?.name}, `
  //     : '';
  //   const place_formatted = option?.properties?.place_formatted
  //     ? `${option?.properties?.place_formatted}, `
  //     : '';
  //   return fulladdress || address + name + place_formatted;
  // };
  const [viewImagedialogOpen, setViewImagedialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const handleViewImageClick = (image) => {
    console.log('===handleViewImageClick', image);
    setSelectedImage(image);
    setViewImagedialogOpen(true);
  };

  // Function to handle closing the dialog
  const handleViewImageCloseDialog = () => {
    setViewImagedialogOpen(false);
    setSelectedImage('');
  };

  useEffect(() => {
    getMasterEducationDetails();
  }, []);

  const educationOptions = useMemo(() => {
    return select_education || {};
  }, [select_education]);
  // console.log("eduationOptions: ", typeof (educationOptions))

  const getEducationStatusObject = () => {
    let statusObj = null;
    const valuesArray = Object.values(educationOptions);
    // console.log('ValuesArray data @@@@: ', valuesArray);
    statusObj = valuesArray.find((item) =>
      item?.value?.toLowerCase().includes(education?.status?.toLowerCase())
    );
    // console.log("statusObj_data :", statusObj);
    if (statusObj) {
      return statusObj;
    } else {
      return null;
    }
  };

  useEffect(() => {
    getMasterLanguageDetails();
  }, []);

  const languageOptions = select_language || [];
  // console.log("languageOptions @@@ :", languageOptions);

  useEffect(() => {
    console.log('language reducer state', language);
  }, [language]);

  const handleLangChange = (e, newChips) => {
    if (newChips.length > 3) {
      pushMessage('You can add upto 3 languages.');
      return;
    }
    setProfileLanguage(newChips);
  };

  useEffect(() => {
    if (profileImage && profileImage.length > 0) {
      let imgArr = Array.from(profileImage);
      if (!imgArr[0].size) {
        setProfileImage(null);
      }
    }
  }, [profileImage]);

  const getInitialPincodeData = async (pincode) => {
    try {
      const response = await axios.get(
        // `https://api.mapbox.com/search/searchbox/v1/forward?q=${locSearchVal}&language=${LANG}&limit=${LIMIT}&country=${COUNTRY}&access_token=${MAPBOX_ACC}`
        POSTOFFICE_PINCODE + pincode
      );
      if (response.status === 200) {
        if (response?.data?.status_code == 200) {
          console.log('SEARCH RESULT', response.data);
          setMapOtions(response.data?.data);
          setSearchLoad(false);
          return response.data?.data;
        } else {
          setMapOtions([]);
          setSearchLoad(false);
          return [];
        }
      } else {
        pushMessage('No City Found!!');
        return [];
      }
    } catch (err) {
      console.log('');
      return [];
    }
  };

  function setDefaultAddressValues() {
    setDefaultPincodeVal({ pincode: [userData?.registration?.pincode] });
    // setAddress2(userData?.registration?.address2);
    // setAreaName(userData?.registration?.address2);
    // setCity(userData?.registration?.city);
    // setState(userData?.registration?.state);
  }

  useEffect(() => {
    async function getPincodeData(pincode) {
      try {
        const pincodeArr = await getInitialPincodeData(pincode);
        console.log('pincodeArr', pincodeArr);
        if (pincodeArr.length > 0) {
          const picodeDataExac = pincodeArr.find(
            (pin) =>
              pin.city[0] === userData?.registration?.city &&
              pin.statename[0] === userData?.registration?.state &&
              pin.pincode[0] === userData?.registration?.pincode
          );
          if (picodeDataExac) {
            setDefaultPincodeVal(picodeDataExac);
            setAddress2(picodeDataExac);
            setAreaName(picodeDataExac?.areaname[0]?.toString());
            setCity(picodeDataExac?.city[0]?.toString());
            setState(picodeDataExac?.statename[0]?.toString());
          } else {
            setDefaultAddressValues();
          }
        } else {
          setDefaultAddressValues();
        }
      } catch (error) {
        setDefaultAddressValues();
      }
    }
    let pinDeb = setTimeout(() => {
      if (userData?.registration?.pincode) {
        getPincodeData(userData?.registration?.pincode);
        setLocSearchVal(userData?.registration?.pincode);
      }
    }, 1000);

    return () => clearTimeout(pinDeb);
  }, [userData?.registration?.pincode]);

  function backSuccessCallback() {
    loading(false);
    onBackHandler();
  }

  const handleBackButton = async () => {
    loading(true);
    let newUserData = {
      active_steps: 5,
      steps_completed: true,
    };
    await updateUserData(
      userData,
      newUserData,
      userData.registration.id,
      userData.accessToken,
      backSuccessCallback
    );
    loading(false);
  };

  async function handleSkipButton() {
    loading(true);
    let newUserData = {
      active_steps: 6,
      steps_completed: false,
    };
    await updateUserData(
      userData,
      newUserData,
      userData.registration.id,
      userData.accessToken
    );
    loading(false);
    onNextHandler();
    updateUserDataSuccess();
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="lg">
        {isLoading && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}

        <Dialog
          open={openReupload}
          onClose={handleCloseReupload}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          maxWidth="sm"
          fullWidth
        >
          {/* <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle> */}
          <DialogTitle className="custom-border-header pb-0">
            <Box className="position-relative d-flex justify-content-between">
              <Typography
                className="fs-22 fw-bold font-skmodernist"
                id="alert-dialog-title"
              >
                {DIALOG_TITLE}
              </Typography>
              <Box>
                <CloseIcon
                  onClick={handleCloseReupload}
                  style={{ cursor: 'pointer' }}
                />
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to replace the Existing profile photo?
            </DialogContentText>
          </DialogContent>

          <DialogActions className="modal-footer">
            <Button onClick={handleCloseReupload}>No</Button>
            <Button
              onClick={() => {
                handleCloseReupload();
                profileUploadRef.current.click();
              }}
              className="btn-save"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openDelModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          maxWidth="sm"
          fullWidth
        >
          {/* <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle> */}
          <DialogTitle className="custom-border-header pb-0">
            <Box className="position-relative d-flex justify-content-between">
              <Typography
                className="fs-22 fw-bold font-skmodernist"
                id="alert-dialog-title"
              >
                {DIALOG_TITLE}
              </Typography>
              <Box>
                <CloseIcon
                  onClick={() => {
                    handleClose();
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete the Certificate?
            </DialogContentText>
          </DialogContent>

          <DialogActions className="modal-footer">
            <Button onClick={handleClose}>No</Button>
            <Button
              onClick={() => {
                handleClose();
                deleteCertificateData(doc?.id, onDeleteSuccess, () =>
                  console.log('Please try again, Error while deleting Resume.')
                );
              }}
              className="btn-save"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: 'auto', marginBottom: '100px' }}
        >
          <Grid item xs={12}>
            {/* <div className='my-3 ms-2'>
                            <ElynkerLogo width={'100px'} height={'30px'} />
                        </div>
                        <Divider component="div" className="main-divider mb-3" role="presentation" /> */}
            <Typography
              variant="h4"
              align="left"
              alignItems="center"
              className="fs-22 mt-4 mb-2 fw-bold font-skmodernist"
              gutterBottom
              style={{ whiteSpace: 'pre-line' }}
            >
              {categoryText}
            </Typography>

            <Typography
              variant="body1"
              align="left"
              className="fs-14 text-grey fw-normal font-gotham"
              gutterBottom
            >
              A professional photo helps you build trust with your clients. To
              keep things safe and simple, they&apos;ll pay you through us -
              which is why we need your personal information.
            </Typography>

            <form
              style={{ marginTop: '30px' }}
              onSubmit={(e) => {
                e.preventDefault();
                GAEvents(FL_PROFILE_DETAILS_TITLE, "Freelancer Onboarding", CLICKED,{
                  userId: userData?.id || sessionStorage.getItem('random_id'),
                  reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                  title: "Next"
                });
                submitFLProfileDetails();
              }}
            >
              <Grid
                container
                rowSpacing={0}
                columnSpacing={12}
                alignItems="start"
              >
                <Grid item md={6} lg={6} xs={12} className="mb-3">
                  <InputLabel
                    htmlFor="inputCIN"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    Upload Photo{' '}
                    <span className="text-muted fs-12">
                      (JPEG or PNG files only)
                    </span>
                  </InputLabel>
                  {profileImage &&
                    Array.from(profileImage).map((img, imgIdx) => {
                      if (img?.size)
                        return (
                          <div
                            key={imgIdx}
                            style={{
                              position: 'relative',
                              display: 'inline-block',
                              marginRight: '10px',
                            }}
                          >
                            <img
                              key={imgIdx}
                              style={{
                                display: img ? 'block' : 'none',
                                objectFit: 'contain',
                                marginRight: '10px',
                              }}
                              width={'100px'}
                              onClick={() =>{
                                GAEvents(FL_PROFILE_DETAILS_TITLE, "Freelancer Onboarding", CLICKED,{
                                  userId: userData?.id || sessionStorage.getItem('random_id'),
                                  reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                  title: "Image Open"
                                });
                                handleViewImageClick(URL.createObjectURL(img));
                              }}
                              height={'100px'}
                              src={URL.createObjectURL(img)}
                            />
                            <IconButton
                              style={{
                                position: 'absolute',
                                top: '4px',
                                right: '-7px',
                                color: '#727272',
                                padding: '5px',
                                borderRadius: '50%',
                                backgroundColor: 'blue',
                              }}
                              aria-label="Delete Image"
                              onClick={() => {
                                GAEvents(FL_PROFILE_DETAILS_TITLE, "Freelancer Onboarding", CLICKED,{
                                  userId: userData?.id || sessionStorage.getItem('random_id'),
                                  reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                  title: "Image Delete"
                                });
                                setProfileImage(null);
                              }}
                            >
                              <CloseIcon
                                style={{
                                  color: 'white',
                                  width: 16,
                                  height: 16,
                                }}
                              />
                            </IconButton>
                          </div>
                        );
                    })}
                  {!profileImage?.length && (
                    <img
                      style={{
                        display: userData?.registration?.image_path
                          ? 'block'
                          : 'none',
                        objectFit: 'contain',
                        marginRight: '10px',
                      }}
                      width={'100px'}
                      height={'100px'}
                      src={
                        COMPANY_LOGO_BASE_URL + userData.registration.image_path
                      }
                    />
                  )}
                  <input
                    type="file"
                    style={{ display: 'none' }}
                    ref={profileUploadRef}
                    onChange={handleProfileUploadFile}
                    id="file"
                    accept=".png,.jpeg,.jpg"
                  />
                  <Button
                    type="button"
                    fullWidth
                    variant="outlined"
                    className="add-button mt-2 fs-15 font-skmodernist fw-bold"
                    sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
                    onClick={()=>{
                      GAEvents(FL_PROFILE_DETAILS_TITLE, "Freelancer Onboarding", CLICKED,{
                        userId: userData?.id || sessionStorage.getItem('random_id'),
                        reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                        title: "Add Image"
                      });
                      handleProfileImageClick();
                    }}
                  >
                    <img
                      src={AddBtnIcon}
                      alt="add-btn-icon"
                      className="user-logo"
                    />
                  </Button>
                </Grid>
                <Grid item md={6} lg={6} xs={12} className="mb-3">
                  <InputLabel
                    htmlFor="dateOfBirth"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    Date of Birth
                  </InputLabel>
                  <LocalizationProvider
                    className="test-0"
                    dateAdapter={AdapterMoment}
                  >
                    <DemoContainer
                      className="test-1"
                      components={['DatePicker']}
                    >
                      <DatePicker
                        className="test-2"
                        format="DD-MM-YYYY"
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                        maxDate={moment().subtract(DATE_MAX_YEAR, 'years')}
                        minDate={moment().subtract(DATE_MIN_YEAR, 'years')}
                        value={moment(profileDob)}
                        onChange={(newValue) => setProfileDate(newValue)}
                        disableFuture
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
                <Grid item md={6} lg={6} xs={12}>
                  <InputLabel
                    htmlFor="inputCompanyAddress"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    Add WhatsApp number
                  </InputLabel>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <OutlinedInput
                      id="whatsapp"
                      type="number"
                      placeholder="Add WhatsApp number"
                      onKeyDown={(evt) =>
                        ['e', 'E', '+', '-'].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      inputProps={{
                        maxLength: 10,
                      }}
                      fullWidth
                      margin="none"
                      value={
                        isNotExistingMobile
                          ? userData.user?.mobile_number
                          : whatsapp
                      }
                      onChange={handleWhatsappChange}
                    />
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <Checkbox
                        edge="start" // Aligning checkbox to the left
                        onChange={hanldleIsExistingMobile}
                        checked={isNotExistingMobile}
                        inputProps={{
                          'aria-labelledby': 'checkbox-list-secondary',
                        }}
                        sx={{
                          color: '#D4D4D4',
                          '&.Mui-checked': {
                            color: '#416AD4',
                          },
                        }}
                      />
                      <Typography
                        sx={{
                          color: '#7c7d7d',
                          mr: 1,
                          fontSize: theme.typography.fontSize,
                        }}
                      >
                        Same as Registered number
                      </Typography>
                    </Box>
                  </FormControl>

                  <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                    {errors.whatsapp}
                  </FormHelperText>
                </Grid>
                <Grid item md={6} lg={6} xs={12} className="mb-3">
                  <InputLabel
                    htmlFor="inputEducation"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    Education <span className="text-mandatory">*</span>
                  </InputLabel>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <CustomDropdown
                      id="flEducation"
                      // disabled={userSelect === "view"}
                      value={getEducationStatusObject()}
                      options={educationOptions}
                      inputPlaceholder="Select Education"
                      onChange={onEducationChange}
                      labelKey="value"
                      optionKey="id"
                    />
                  </FormControl>
                  <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                    {errors.flEducation}
                  </FormHelperText>
                </Grid>
                <Grid item md={6} lg={6} xs={12} className="mb-3">
                  <InputLabel
                    htmlFor="inputCIN"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    Language
                  </InputLabel>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      options={languageOptions}
                      value={language}
                      onChange={handleLangChange}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option?.value}
                      isOptionEqualToValue={(option, value) => {
                        return option == value;
                      }}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option?.value}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={language?.length > 0 ? '' : 'Languages'}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                container
                rowSpacing={0}
                columnSpacing={12}
                alignItems="end"
              >
                <Grid item md={6} lg={6} xs={12} className="mb-1">
                  <InputLabel
                    htmlFor="inputCompanyAddress"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    Address <span className="text-mandatory">*</span>
                  </InputLabel>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <OutlinedInput
                      id="flAddress1"
                      // label="Mobile Number"
                      type="text"
                      placeholder="Building/Street"
                      // required
                      inputProps={{
                        maxLength: 200,
                      }}
                      fullWidth
                      margin="none"
                      value={address1}
                      onChange={handleAddress1Change}
                    />
                  </FormControl>
                  <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                    {errors.flAddress1}
                  </FormHelperText>
                </Grid>
                <Grid item md={6} lg={6} xs={12}>
                  <InputLabel
                    htmlFor="inputLocation"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    Enter your Pincode
                    <span className="text-mandatory">*</span>
                  </InputLabel>

                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <Autocomplete
                      disablePortal
                      ref={searchLocInpRef}
                      id={'SearchLocation'}
                      options={mapOtions}
                      fullWidth
                      value={defaultPincodeVal}
                      onInputChange={(e, value) => {
                        const numericValue = value.replace(/[^0-9]/g, '');
                        setLocSearchVal(numericValue);
                        validateInput('pincode', numericValue);
                        setSearchLoad(true);
                      }}
                      onChange={(e, value) => {
                        if (e?.target?.value?.length == 0) {
                          setAreaName('');
                          setCity('');
                          setState('');
                          setMapOtions([]);
                          setPincode(null);
                          setDefaultPincodeVal(null);
                          setSearchLoad(false);
                        } else {
                          setAddress2(value);
                          validateInput(
                            'pincode',
                            value?.pincode[0]?.toString()
                          );
                          setCity(value?.city[0]?.toString());
                          setState(value?.statename[0]?.toString());
                          setAreaName(value?.areaname[0]?.toString());
                          setDefaultPincodeVal(value);
                          setPincode(value);
                          setSearchLoad(false);
                        }
                      }}
                      open={open}
                      onOpen={openPopper}
                      onClose={closePopper}
                      getOptionLabel={(option) =>
                        option?.pincode[0]?.toString()
                      }
                      isOptionEqualToValue={(option, value) =>
                        option['pincode'] === value['pincode']
                      }
                      renderOption={(props, option) => (
                        <Box component="li" {...props}>
                          {option.areaname[0]} - {option.city[0]} -{' '}
                          {option.pincode}
                        </Box>
                      )}
                      sx={{
                        '.MuiAutocomplete-input': {
                          paddingLeft: '14px !important',
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant="outlined"
                          placeholder={'eg. 400070'}
                          inputProps={{
                            ...params.inputProps,
                            maxLength: 6,
                            inputMode: 'numeric',
                            onKeyDown: (e) => {
                              if (
                                !/^[0-9]$/.test(e.key) &&
                                e.key !== 'Backspace' &&
                                e.key !== 'Delete'
                              ) {
                                e.preventDefault();
                              }
                            },
                          }}
                        />
                      )}
                      noOptionsText={'Please enter valid Pincode'}
                    />
                  </FormControl>
                  <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                    {errors.pincode}
                  </FormHelperText>
                </Grid>
                <Grid item md={6} lg={6} xs={12}>
                  <InputLabel
                    htmlFor="inputCompanyAddress"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    Town/Area
                  </InputLabel>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <OutlinedInput
                      id="inputCompanyAddress"
                      // label="Mobile Number"
                      type="text"
                      placeholder="Town/Area"
                      // required
                      inputProps={{
                        maxLength: 200,
                      }}
                      fullWidth
                      margin="none"
                      value={areaname}
                      disabled
                    />
                  </FormControl>
                  <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                    {errors.address}
                  </FormHelperText>
                </Grid>
                <Grid item md={6} lg={6} xs={12}>
                  <InputLabel
                    htmlFor="inputCompanyAddress"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    City/District
                  </InputLabel>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <OutlinedInput
                      id="inputCompanyAddress"
                      // label="Mobile Number"
                      type="text"
                      placeholder="City/District"
                      // required
                      inputProps={{
                        maxLength: 200,
                      }}
                      fullWidth
                      margin="none"
                      value={city}
                      disabled
                    />
                  </FormControl>
                  <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                    {errors.address}
                  </FormHelperText>
                </Grid>
                <Grid item md={6} lg={6} xs={12}>
                  <InputLabel
                    htmlFor="inputCompanyAddress"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    State
                  </InputLabel>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <OutlinedInput
                      id="inputCompanyAddress"
                      // label="Mobile Number"
                      type="text"
                      placeholder="State"
                      // required
                      inputProps={{
                        maxLength: 200,
                      }}
                      fullWidth
                      margin="none"
                      value={states}
                      disabled
                    />
                  </FormControl>
                  <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                    {errors.address}
                  </FormHelperText>
                </Grid>

                {/* <div className="">
                                <InputLabel
                                htmlFor="cityInput"
                                className="text-start fs-14 text-black font-skmodernist"
                                >
                                City
                                </InputLabel>
                                <FormControl fullWidth sx={{ my: 1 }}>
                                <CustomDropdown
                                    id="cityInput"
                                    value={city}
                                    options={allCity}
                                    inputPlaceholder="Select City"
                                    onChange={(e, value) => {
                                    // console.log('Value here5', e, value);
                                    setCity(value);
                                    }}
                                    labelKey="name"
                                    optionKey="id"
                                    autoComplete="address-level2"
                                />
                                </FormControl>
                            </div>
                            <div className="">
                                <InputLabel
                                htmlFor="stateInput"
                                className="text-start fs-14 text-black font-skmodernist"
                                >
                                State
                                </InputLabel>
                                <FormControl fullWidth sx={{ my: 1 }}>
                                <CustomDropdown
                                    id="stateInput"
                                    value={states}
                                    options={allState}
                                    labelKey="name"
                                    optionKey="id"
                                    inputPlaceholder="Select State"
                                    onChange={(e, value) => {
                                    // console.log('Value here6', value);
                                    setState(value);
                                    }}
                                    autoComplete="address-level1"
                                />
                                </FormControl>
                            </div> */}
                {/* <Grid item md={6} lg={6} xs={12} className="mb-1">
                  <InputLabel
                    htmlFor="inputLocation"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    Location <span className="text-mandatory">*</span>
                  </InputLabel>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <Autocomplete
                      disablePortal
                      ref={searchLocInpRef}
                      id={'flAddress2'}
                      options={mapOtions}
                      fullWidth
                      value={address2}
                      inputValue={locSearchVal}
                      onInputChange={(e, value) => {
                        setLocSearchVal(value);
                        setSearchLoad(true);
                      }}
                      onChange={(e, value) => {
                        setAddress2(value);
                        if (value?.geometry?.coordinates?.length > 0) {
                          setLocationCoords({
                            latitude: value?.geometry?.coordinates[1],
                            longitude: value?.geometry?.coordinates[0],
                          });
                          validateInput('flAddress2', 'address2');
                        }
                        setSearchLoad(false);
                      }}
                      open={open}
                      onOpen={openPopper}
                      onClose={closePopper}
                      defaultValue={getAddress2DefaultVal()}
                      getOptionLabel={getLocationString}
                      isOptionEqualToValue={(option, value) =>
                        option?.properties?.full_address ===
                        value?.properties?.full_address
                      }
                      sx={{
                        '.MuiAutocomplete-input': {
                          paddingLeft: '14px !important',
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant="outlined"
                          placeholder={'Search Address'}
                          inputProps={{
                            ...params.inputProps,
                            maxLength: 200,
                          }}
                        />
                      )}
                    />
                  </FormControl>
                  <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                    {errors.flAddress2}
                  </FormHelperText>
                </Grid> */}
              </Grid>

              {/* <Grid
                container
                rowSpacing={0}
                columnSpacing={12}
                justifyContent={'flex-end'}
                alignItems="end"
              >
                <Grid item md={6} lg={6} xs={12} className="mb-3 d-flex">
                  <Button type="button" variant="text" onClick={handleLocateMe}>
                    <LocatemeLogo />
                    <Typography
                      variant="body1"
                      align="left"
                      className="fs-14 text-primary fw-normal font-gotham ms-2"
                    >
                      Locate Me
                    </Typography>
                  </Button>
                </Grid>
              </Grid> */}

              <Grid
                container
                rowSpacing={0}
                columnSpacing={12}
                alignItems="end"
              >
                <Grid item md={6} lg={6} xs={12} className="mb-3 ">
                  <Button
                    className="d-flex p-0 align-items-center"
                    onClick={toggleDrawer}
                  >
                    <AddCertificationIcon />
                    <Typography
                      variant="body1"
                      align="left"
                      className="fs-14 text-primary fw-normal font-gotham ms-3 mb-0 text-capitalize"
                      gutterBottom
                    >
                      Add Certification
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
              <Divider
                component="div"
                className="main-divider mt-3"
                role="presentation"
              />
              <Drawer
                xs={12}
                anchor="bottom"
                open={isDrawerOpen}
                onClose={toggleDrawer}
                sx={{
                  '& .MuiDrawer-paper': {
                    height: 'auto',
                    borderTopLeftRadius: '20px',
                    borderTopRightRadius: '20px',
                  },
                }}
              >
                <div className="p-3">
                  <div className="d-flex justify-content-between mb-3">
                    <Typography
                      variant="h5"
                      align="left"
                      alignItems="center"
                      className="fs-18 mb-2 fw-bold font-skmodernist "
                      gutterBottom
                    >
                      {isEditing ? 'Edit' : 'Add'} Certification
                    </Typography>
                    <CloseIcon onClick={toggleDrawer} />
                  </div>
                  <div className="mb-3">
                    <InputLabel
                      htmlFor="inputEducation"
                      className="text-start fs-14 text-black font-skmodernist"
                    >
                      Name <span className="text-mandatory">*</span>
                    </InputLabel>
                    <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                      <OutlinedInput
                        id="inputEducation"
                        // label="Mobile Number"
                        type="text"
                        placeholder="Enter name"
                        autoFocus
                        fullWidth
                        margin="none"
                        value={certName}
                        onChange={handleCertNameChange}
                        inputProps={{
                          maxLength: 100,
                        }}
                      />
                    </FormControl>
                    <FormHelperText
                      sx={{ color: 'red' }}
                      id="email-helper-text"
                    >
                      {errors.flAddress2}
                    </FormHelperText>
                  </div>
                  <div className=" mb-3">
                    <InputLabel
                      htmlFor="inputCompanyName"
                      className="text-start fs-14 text-black font-skmodernist"
                    >
                      Description
                    </InputLabel>

                    <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                      <TextareaAutosize
                        id="inputAdditionalInfo"
                        placeholder="Enter description"
                        minRows={3} // You can adjust the number of rows as needed
                        maxRows={2} // You can adjust the number of rows as needed
                        value={certDesc}
                        onChange={handleCertDescChange}
                        maxLength={500}
                      />
                    </FormControl>
                  </div>
                  <div className="mb-5">
                    <InputLabel
                      htmlFor="dateOfBirth"
                      className="text-start fs-14 text-black font-skmodernist"
                    >
                      Issued on
                    </InputLabel>
                    <LocalizationProvider
                      className="test-0"
                      dateAdapter={AdapterMoment}
                    >
                      <DemoContainer
                        className="test-1"
                        components={['DatePicker']}
                      >
                        <DatePicker
                          className="test-2"
                          format="DD-MM-YYYY"
                          renderInput={(params) => (
                            <TextField {...params} fullWidth />
                          )}
                          value={moment(certDate)}
                          onChange={(newValue) => setCertDate(newValue)}
                          disableFuture
                        />
                        {/* <DesktopDatePicker  /> */}
                        {/* <MobileDatePicker /> */}
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                  <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    className="mb-3 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
                    sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
                    onClick={()=>{
                      GAEvents(FL_PROFILE_DETAILS_TITLE, "Freelancer Onboarding", CLICKED,{
                        userId: userData?.id || sessionStorage.getItem('random_id'),
                        reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                        title: "Certificate Save"
                      });
                      saveCertificate();
                    }}
                  >
                    Save
                  </Button>
                </div>
              </Drawer>

              {/* <Grid  rowSpacing={0} columnSpacing={12} alignItems="end"> */}
              <Grid
                md={12}
                lg={12}
                xs={12}
                className="custom-accordion product-accordion"
                sx={{ marginBottom: isTabletOrMobile ? 100 : 0 }}
              >
                <Accordion
                  defaultExpanded={
                    certificates && certificates.length > 0 ? true : false
                  }
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="fs-15 font-skmodernist text-black fw-bold"
                  >
                    Certification
                  </AccordionSummary>
                  <AccordionDetails>
                    {certificates &&
                      certificates.map((item, index) => {
                        const issueDate = formatedDate(item?.issued_on);
                        return (
                          <div className="mb-3" key={index}>
                            <div className="d-flex justify-content-between align-items-center mb-2">
                              <div>
                                <Typography className="fs-15 fw-normal font-skmodernist text-darkgrey">
                                  {item?.name}
                                </Typography>
                              </div>
                              <div className="d-flex">
                                <IconButton
                                  className="cat-edit-icon"
                                  onClick={() => {
                                    GAEvents(FL_PROFILE_DETAILS_TITLE, "Freelancer Onboarding", CLICKED,{
                                      userId: userData?.id || sessionStorage.getItem('random_id'),
                                      reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                      title: "Edit Certificate",
                                      item_title: 'item?.name'
                                    });
                                    setDoc(item);
                                    setIsEditing(true);
                                    setCertName(item?.name);
                                    setCertDesc(item?.description);
                                    setCertDate(moment(item?.issued_on));
                                    setIsDrawerOpen(true);
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>
                                <IconButton
                                  className="cat-delete-icon"
                                  onClick={() => {
                                    setDoc(item);
                                    handleOpen();
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </div>
                            </div>
                            <Typography
                              variant="body1"
                              align="left"
                              className="fs-14 text-grey fw-normal font-gotham mb-2"
                              gutterBottom
                            >
                              {item?.description}
                            </Typography>
                            <Typography className="fs-14 font-gotham text-primary mb-2">
                              Issued on: {issueDate}{' '}
                            </Typography>
                          </div>
                        );
                      })}
                  </AccordionDetails>
                </Accordion>
              </Grid>
              {/* </Grid> */}
              <div style={{ marginTop: !isTabletOrMobile ? '30px' : '100px' }}>
                {isTabletOrMobile && (
                  <>
                    <Box sx={{ width: '100%' }}>
                      <Box sx={[stickyButtonStyle]}>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          className="mt-4 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
                          sx={{
                            borderRadius: '6px',
                            textTransform: 'capitalize',
                          }}
                          onClick={() => {}}
                        >
                          Next Step
                        </Button>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Button
                            variant="text"
                            type="button"
                            fullWidth
                            className="mt-2 mb-3  fs-15 text-capitalize fw-semibold text-black"
                            onClick={()=>{
                              GAEvents(FL_PROFILE_DETAILS_TITLE, "Freelancer Onboarding", CLICKED,{
                                userId: userData?.id || sessionStorage.getItem('random_id'),
                                reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                title: "Back"
                              });
                              handleBackButton();
                            }}
                          >
                            Back
                          </Button>
                          <Button
                            onClick={()=>{
                              GAEvents(FL_PROFILE_DETAILS_TITLE, "Freelancer Onboarding", CLICKED,{
                                userId: userData?.id || sessionStorage.getItem('random_id'),
                                reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                title: "Skip for Now"
                              });
                              handleSkipButton();}}
                            variant="text"
                            type="button"
                            fullWidth
                            className="mt-2 mb-3  fs-15 text-capitalize fw-semibold text-black"
                          >
                            Skip for Now
                          </Button>
                        </div>
                      </Box>
                    </Box>
                  </>
                )}

                {isDesktopOrLaptop && (
                  <>
                    <Box className="d-flex justify-content-between align-items-center my-4">
                      <Button
                        variant="text"
                        type="button"
                        className="btn-web-back"
                        onClick={()=>{
                          GAEvents(FL_PROFILE_DETAILS_TITLE, "Freelancer Onboarding", CLICKED,{
                            userId: userData?.id || sessionStorage.getItem('random_id'),
                            reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                            title: "Back"
                          });
                          handleBackButton();
                        }}
                      >
                        Back
                      </Button>
                      <Button
                        onClick={()=>{
                          GAEvents(FL_PROFILE_DETAILS_TITLE, "Freelancer Onboarding", CLICKED,{
                            userId: userData?.id || sessionStorage.getItem('random_id'),
                            reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                            title: "Skip for Now"
                          });
                          handleSkipButton();}}
                        variant="text"
                        type="button"
                        className="btn-web-back"
                      >
                        Skip for Now
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        className="btn-web-next"
                      >
                        Next Step
                      </Button>
                    </Box>
                  </>
                )}
              </div>
            </form>
          </Grid>
        </Grid>
        <ImageDialog
          open={viewImagedialogOpen}
          onClose={handleViewImageCloseDialog}
          image={selectedImage}
        />
      </Container>
    </ThemeProvider>
  );
};

FLProfileDetailsForm.propTypes = {
  loading: PropTypes.func,
  isLoading: PropTypes.bool,
  onNextHandler: PropTypes.func,
  onBackHandler: PropTypes.func,
  userData: PropTypes.object,
  getCertificateData: PropTypes.func,
  certificates: PropTypes.array,
  addCertificateData: PropTypes.func,
  updateCertificateData: PropTypes.func,
  deleteCertificateData: PropTypes.func,
  setProfileImage: PropTypes.func,
  profileImage: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  education: PropTypes.string,
  setProfileEducation: PropTypes.func,
  updateUserData: PropTypes.func,
  resetFLProfileData: PropTypes.func,
  profileDob: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setProfileDate: PropTypes.func,
  setProfileLanguage: PropTypes.func,
  language: PropTypes.array,
  uploadCompanyLogoImage: PropTypes.func,
  address1: PropTypes.string,
  address2: PropTypes.object,
  locationCoords: PropTypes.object,
  setAddress1: PropTypes.func,
  setAddress2: PropTypes.func,
  city: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  states: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  // getCityMaster: PropTypes.func,
  // getStateMaster: PropTypes.func,
  allCity: PropTypes.array,
  allState: PropTypes.array,
  setCity: PropTypes.func,
  setState: PropTypes.func,
  setLocationCoords: PropTypes.func,
  pushMessage: PropTypes.func,
  getMasterEducationDetails: PropTypes.func,
  select_education: PropTypes.object,
  getMasterLanguageDetails: PropTypes.func,
  select_language: PropTypes.object,
  setWhatsappNumber: PropTypes.func,
  whatsapp: PropTypes.string,
  areaname: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  setAreaName: PropTypes.func,
  setPincode: PropTypes.func,
  pincode: PropTypes.any,
};

const mapStateToProps = (state) => {
  const { userData } = state.signin;
  const { isLoading } = state.root;
  const {
    certificates,
    profileImage,
    education,
    profileDob,
    language,
    address1,
    address2,
    locationCoords,
    city,
    states,
    allCity,
    allState,
    select_education,
    select_language,
    areaname,
    pincode,
  } = state.flProfileDetails;

  const { whatsapp } = state.profileDetails;
  // console.log("State :", state);
  return {
    isLoading,
    userData,
    certificates,
    profileImage,
    education,
    profileDob,
    language,
    city,
    states,
    allCity,
    allState,
    address1,
    address2,
    locationCoords,
    select_education,
    select_language,
    whatsapp,
    areaname,
    pincode,
  };
};
export default connect(mapStateToProps, {
  loading,
  getCertificateData,
  addCertificateData,
  updateCertificateData,
  deleteCertificateData,
  setProfileImage,
  setProfileEducation,
  updateUserData,
  resetFLProfileData,
  setProfileDate,
  setProfileLanguage,
  uploadCompanyLogoImage,
  setAddress1,
  setAddress2,
  setCity,
  setState,
  // setLocationCoords,
  // getCityMaster,
  // getStateMaster,
  pushMessage,
  getMasterEducationDetails,
  getMasterLanguageDetails,
  setWhatsappNumber,
  setAreaName,
  setPincode,
})(FLProfileDetailsForm);
