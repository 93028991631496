// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from 'moment';
import { resetAddCatalogData } from '../pages/AddCatalog/redux/actions/add-catalog-action';
import { resetB2BStepData } from '../pages/B2BOnboardingSteps/redux/actions/b2b-onboarding-steps-action';
import { resetComapanyData } from '../pages/CompanyDetails/redux/actions/company-details-form-action';
import { resetCompleteProfileData } from '../pages/CompleteProfile/redux/actions/start-connecting-action';
import { resetFLAddBioData } from '../pages/FreeLancer/FL-AddBio/redux/actions/fl-add-bio-action';
import { resetFLProfileData } from '../pages/FreeLancer/FL-Profile-Details/redux/actions/fl-profile-details-action';
import { resetHourlyRate } from '../pages/FreeLancer/FL-SetRate/redux/actions/fl-set-rate-action';
import { resetFLResumeData } from '../pages/FreeLancer/FL-UploadResume/redux/actions/fl-upload-resume-action';
import { resetFLUploadServiceData } from '../pages/FreeLancer/FL-UploadServiceCatalog/redux/actions/fl-upload-service-action';
import { resetFLUProfRoleData } from '../pages/FreeLancer/FLAddProfessionalRole/redux/actions/fl-add-profession-action';
import { resetFLAddServiceData } from '../pages/FreeLancer/FLAddService/redux/actions/add-service-action';
import { resetFLStepData } from '../pages/FreeLancer/FLOnboardingSteps/redux/actions/fl-onboarding-steps-action';
import { resetLoginData } from '../pages/Login/redux/actions/submit-mobileno-action';
import { resetMyCatalogData } from '../pages/MyCatalog/redux/actions/mycatalog-action';
import { resetMyServiceData } from '../pages/MyServices/redux/actions/mycatalog-action';
import { resetOfferedServiceData } from '../pages/OfferedServices/redux/actions/offered-services-form-action';
import { resetUserData } from '../pages/Otp/redux/actions/submit-code-action';
import { resetProfileData } from '../pages/ProfileDetails/redux/actions/profile-details-form-action';
import { resetUpdateProfileeData } from '../pages/UpdateProfile/store/actions/profileActions';
import ReactGA from 'react-ga4';
// const history = useHistory();

export async function handleLocationPermission(pushMessage = () => {}) {
  try {
    if (navigator.geolocation) {
      const result = await navigator.permissions.query({ name: 'geolocation' });
      if (result.state === 'granted') {
        report(result.state);
        return { result: result, error: null, currentPosition: null };
      } else if (result.state === 'prompt') {
        report(result.state);
        let ress = null;
        let locErr = null;
        navigator.geolocation.getCurrentPosition(
          (res) => {
            ress = res;
            return res;
          },
          (err) => {
            locErr = err;
            return err;
          }
        );
        return {
          result: result,
          error: null,
          currentPosition: { result: ress, error: locErr },
        };
      } else if (result.state === 'denied') {
        report(result.state);
        pushMessage(
          'Location Permission Denied. Please allow location access by clicking "i" button from left of browser address bar'
        );
        console.log('window location', window.location);
        window.location.href = 'http://app-settings:location';
        return { result: result, error: null, currentPosition: null };
      }
      // result.addEventListener('change', () => {
      //     report(result.state);
      //     return { result: result, error: null };
      // });
    } else {
      pushMessage('Geolocation is not supported in your browser.');
    }
  } catch (error) {
    console.log('Error Requesting Permission', error);
    return { result: null, error: error, currentPosition: null };
  }
}

function report(state) {
  console.log(`Permission ${state}`);
}

export const handleLogoutReset = async () => {
  //clear reducer data
  resetUserData();
  resetB2BStepData();
  resetComapanyData();
  resetProfileData();
  resetAddCatalogData();
  resetMyCatalogData();
  resetMyServiceData();
  resetOfferedServiceData();
  resetUpdateProfileeData();
  resetFLAddBioData();
  resetFLProfileData();
  resetHourlyRate();
  resetFLResumeData();
  resetFLUploadServiceData();
  resetFLUProfRoleData();
  resetFLAddServiceData();
  resetLoginData();
  resetCompleteProfileData();
  resetFLStepData();
  // history.replace('/login');
};

export function filterAllCategory(list, selectedCate) {
  let roots = list?.filter((item) => {
    return item.parent_id == selectedCate?.id;
  });
  console.log('Roots list', roots);
  return roots;
}

export function searchInArray(array, searchTerm) {
  let results = [];

  function searchRecursive(items) {
    for (let item of items) {
      // Check if the current item matches the search term
      if (item.title.toLowerCase().includes(searchTerm.toLowerCase())) {
        results.push(item); // Collect the matching item
      }

      // Recursively search through children if present
      if (item?.children && item?.children.length > 0) {
        searchRecursive(item.children);
      }
    }
  }

  // Start the search
  searchRecursive(array);

  return results;
}

export const scrollToInput = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    element.focus();
  }
};

export function handleErrorsAndScroll(errors) {
  if (!errors) return;

  Object.keys(errors).forEach((key) => {
    if (errors[key]) {
      scrollToInput(key);
    }
  });
}

export const handleDownloadDocFormate = () => {
  const link = document.createElement('a');

  link.href = './buisness-formate.xlsx';
  link.download = 'sample.xlsx';

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
};
export async function shareCardContent(title, files = [], fallbackUrl, url) {
  const fireFoxshareData = {
    url: fallbackUrl,
    title: url,
  };
  const chromeshareData = {
    title,
    files: [files],
    url: url,
  };
  const userAgents = window.navigator.userAgent;

  if (
    window.navigator.canShare &&
    window.navigator.canShare({ files: [files] })
  ) {
    window.navigator
      .share({
        files: [files],
        title: '',
        url: url,
      })
      .then(() => {
        console.log('Share successfully');
      })
      .catch((error) => {
        console.error('Share Failed', error);
      });
  } else {
    console.log(`Your system doesn't support sharing files.`);
    try {
      await window.navigator.share(fireFoxshareData);
      console.log('Share Successful');
    } catch (error) {
      // alert('Sharing from this browser is not supported');
      // console.error('Sharing failed', error);
      window.location.reload(true);
    }
  }

  if (userAgents.includes('Chrome')) {
    try {
      console.log('chromeshareData', chromeshareData);
      await window.navigator.share(chromeshareData);
      console.log('Share Successful');
    } catch (error) {
      // alert('Sharing from this browser is not supported.');
      // console.error('Sharing failed', error);
      window.location.reload(true);
    }
  } else if (userAgents.includes('Firefox')) {
    try {
      await window.navigator.share(fireFoxshareData);
      console.log('Share Successful');
    } catch (error) {
      // alert('Sharing from this browser is not supported');
      // console.error('Sharing failed', error);
      window.location.reload(true);
    }
  }
}

export function isMoreThan24HoursAgo(createdAt) {
  if (!createdAt) {
    return false; // or handle this case as per your requirement
  }

  const createdDate = moment(createdAt);
  if (!createdDate.isValid()) {
    return false; // or handle this case as per your requirement
  }

  const differenceInHours = moment().diff(createdDate, 'hours');
  return differenceInHours > 24;
}

export function GAEvents(title, category, action, params) {
  ReactGA.event(title, {
    category: category,
    action: action,
    ...params,
  });
}

export function truncateText(text = '', tool, length = 100) {
  if (text.length <= length) {
    return text;
  }

  return text?.length < length
    ? text
    : tool?.viewMore
      ? text
      : text.substr(0, length) + '\u2026';
}

export function list_to_tree(list) {
  let map = {},
    node,
    roots = [],
    i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i; // initialize the map
    list[i].children = []; // initialize the children
    list[i].label = list[i].title;
    list[i].value = list[i].id;
    // list[i].data = list[i];
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.parent_id !== null) {
      // if you have dangling branches check that map[node.parent_id] exists
      list[map[node.parent_id]]?.children.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
}

export function GAPageView(page, category, title) {
  ReactGA.send({
    hitType: 'pageview',
    page: page,
    category: category,
    title: title,
  });
}

export function generateUID() {
  return Date.now().toString(36);
}

export function sendDefaultPageEvent(
  userData,
  page_title,
  page_location,
  page_path
) {
  let userID = userData?.user?.id;
  let regUUID = userData?.registration?.uuid;
  let userType, loginStatus;
  if (userID == null) {
    userID = sessionStorage.getItem('random_id');
  }
  if (regUUID == null) {
    regUUID = sessionStorage.getItem('random_id');
    userType = 'Guest';
    loginStatus = 'logged_out';
  } else {
    userType = 'Registered User';
    loginStatus = 'logged_in';
  }
  const data = {
    user_id: userID,
    reg_uuid: regUUID,
    user_type: userType,
    logged_in_status: loginStatus,
    page_title,
    page_location,
    page_path,
  };
  // ReactGA.gtag(['set', 'user_properties', data]);
  ReactGA.set(data);
}

export function sendPageData(page_title, page_location, page_path) {
  ReactGA.set({
    page_title,
    page_location,
    page_path,
  });
}
