import React, { useEffect } from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import {
  Container,
  Grid,
  Typography,
  Divider,
  Button,
  // Autocomplete,
  // TextField,
  ListItemIcon,
  Radio,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import TreeView from '../../components/TreeView/treeView';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CircleIcon from '@mui/icons-material/Circle';
import { ReactComponent as CallNowIcon } from '../../assets/call-now-icon.svg';
import { ReactComponent as WhatsappIcon } from '../../assets/whatsapp-icon.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/right-arrow-icon.svg';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import { any, array, bool, func, object } from 'prop-types';
import moment from 'moment';
import { COMPANY_LOGO_BASE_URL } from '../../utils/APIs/api';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
import {
  BUSINESS_ID,
  DIALOG_VENDOR_DETAIL_SCRN__MSG,
} from '../../utils/constants';
// import { loading } from '../../store/actions/rootActions';
import { GAEvents, GAPageView } from '../../utils/helper-funtions';
import WebFooter from '../../components/Footer/WebFooter';
// import { setSelectedVCategoryUUID } from '../FLFreelance/redux/actions/fl-business-action';
import defaultProfileImage from '../../assets/defaultProfileImage.png';

const theme = createTheme();
const WebB2BCategoryForm = ({
  categoriesTree,
  selCategory,
  handleCategoryToggle,
  ratingArr,
  sortArr,
  handleRatingToggle,
  selRating,
  vendors,
  handleCallNow,
  handleWhatsapp,
  toggleCallBackDrawer,
  selectedVCategory,
  BuisnessType,
  clearFilter,
  handleTerms,
  handlePrivacy,
  openInNewTab,
  // handleShippingDelivery,
  // handleCancellationRefund,
  handleContactUs,
  handleClickHome,
  handleClickCategory,
  selCity,
  selSort,
  setSelectedSort,
  getVendorList,
  handleCityToggle,
  filterVCity,
  allVCity,
  setFilterVCity,
  clearSortData,
  clearAllCity,
  clearRatingData,
  pushMessage,
  subCategoryAttributes,
  setSubCategoryTreeAtrributes,
  setFilterParam,
  getSubCategoryVendorList,
  userData,
  isLoading,
  loading,
  setSelectedVCategoryUUID,
}) => {
  const history = useHistory();

  const handleSortChange = (value) => {
    setSelectedSort(value);
  };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      className="fs-14 text-gray font-gotham"
      href="/"
      onClick={handleClickHome}
    >
      Elynker
    </Link>,
    <Link
      underline="hover"
      key="2"
      className="fs-14 text-gray font-gotham"
      href="/"
      onClick={handleClickCategory}
    >
      {BuisnessType ? 'Business' : 'Freelancer'}
    </Link>,
    <Link
      underline="none"
      key="3"
      className="fs-14 text-breadcrumbs-active font-gotham"
    >
      {selectedVCategory?.title || ''}
    </Link>,
  ];
  const successCallback = () => {
    loading(false);
  };
  const errorCallback = () => {
    loading(false);
  };
  useEffect(() => {
    GAPageView(
      '/b2b-category',
      'page_vendor_listing',
      'Web Business Vendor listing'
    );
  }, []);

  useEffect(() => {
    let rParams = setFilterParam();
    const fetchData = async () => {
      await getVendorList(
        BUSINESS_ID,
        selectedVCategory?.uuid,
        rParams,
        successCallback,
        errorCallback
      );
    };
    const fetchSubCategoryData = async () => {
      await getSubCategoryVendorList(
        BUSINESS_ID,
        rParams,
        successCallback,
        errorCallback
      );
    };
    loading(true);
    subCategoryAttributes?.checked.length !== 0
      ? fetchSubCategoryData()
      : fetchData();
    // loading(false);
  }, [
    selSort,
    selRating,
    selCategory,
    selCity,
    subCategoryAttributes?.checked,
  ]);

  useEffect(() => {
    setFilterVCity(allVCity);
  }, [allVCity]);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xl">
        {isLoading && (
          <Backdrop
            sx={{
              color: '#fff',
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: '67vh' }}
        >
          <Grid item xs={12}>
            <div className="my-3 ms-2 d-flex align-items-center">
              <ArrowBackIosIcon
                className="cursor-pointer"
                onClick={() => {
                  setSelectedVCategoryUUID(null);
                  history.goBack();
                }}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  alignItems: 'center',
                }}
              >
                <ElynkerLogoComponent>
                  <ElynkerLogo
                    className="ms-3 cursor-pointer"
                    width={'100px'}
                    height={'30px'}
                  />
                </ElynkerLogoComponent>
                <div></div>
              </div>
            </div>
            <Divider
              component="div"
              className="main-divider mt-2 mb-3"
              role="presentation"
            />

            <form style={{ marginTop: '20px' }}>
              <Breadcrumbs
                className="mb-3"
                separator={
                  <NavigateNextIcon fontSize="small" className="text-gray" />
                }
                aria-label="breadcrumb"
              >
                {breadcrumbs}
              </Breadcrumbs>

              <Card className="category-web-card mb-3">
                <CardContent className="category-web-content">
                  <div className="d-flex align-items-center flex-content">
                    <Typography
                      variant="h5"
                      className="b2b-category-web-text text-offwhite fw-bold mb-3 fw-bold font-skmodernist"
                      component="div"
                      style={{ width: '50%' }}
                    >
                      Expand your reach by connecting with potential partners
                      and suppliers.
                    </Typography>
                    <div className="">
                      <Button
                        variant="contained"
                        className="post-requirement-btn py-2 px-4"
                        onClick={() => {
                          const userID =
                            userData?.user?.id ||
                            sessionStorage.getItem('random_id');
                          const regId =
                            userData?.registration?.uuid ||
                            sessionStorage.getItem('random_id');
                          const cateName = selectedVCategory?.title;
                          const pageTitle = 'Elynker - Business - ' + cateName;
                          GAEvents(
                            'Request Call Back Banner',
                            'Request Call Back Button',
                            'clicked',
                            {
                              page_title: pageTitle,
                              page_location: window.location.href,
                              page_path: window.location.pathname,
                              category_name: cateName,
                              user_id: userID,
                              reg_uuid: regId,
                              title: 'Request A Call Back Button',
                            }
                          );
                          toggleCallBackDrawer();
                        }}
                      >
                        Request a Call Back
                      </Button>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Typography
                variant="h4"
                align="left"
                alignItems="center"
                className="fs-20 text-black mb-3 fw-bold font-skmodernist"
              >
                {BuisnessType ? 'Wholesale and Suppliers' : 'Category Name'}
              </Typography>

              <Grid container spacing={2} className="mb-4">
                <Grid item md={4} lg={4}>
                  <Box
                    sx={{
                      border: '1px solid #e3e3e3 !important',
                      borderRadius: '8px !important',
                      padding: '14px',
                    }}
                  >
                    <Box className="d-flex justify-content-between align-items-center">
                      <Typography className="fs-22 fw-bold font-skmodernsit ">
                        Filters
                      </Typography>
                      <Button onClick={clearFilter}>
                        <Typography
                          className="fs-15 fw-bold font-skmodernsit text-secondary"
                          onClick={() => {
                            clearFilter();
                            clearRatingData();
                            clearSortData();
                            clearAllCity();
                          }}
                        >
                          Clear All
                        </Typography>
                      </Button>
                    </Box>
                    <Divider
                      component="div"
                      className="my-3"
                      role="presentation"
                    />
                    <Box>
                      <Typography className="fs-18 fw-medium font-skmodernsit mb-2">
                        Category
                      </Typography>
                      <List
                        class="filter-web-list"
                        sx={{
                          width: '100%',
                          bgcolor: 'background.paper',
                          padding: '0px',
                        }}
                      >
                        {/* {categoriesTree.map((category, index) => {
                          const labelId = `checkbox-list-secondary-label-${index}`;
                          return (
                            <ListItem key={index} disablePadding>
                              <ListItemButton
                                sx={{ paddingLeft: '0px' }}
                                onClick={handleCategoryToggle(category)}
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    edge="start"
                                    onChange={handleCategoryToggle(category)}
                                    checked={
                                      selCategory.findIndex(
                                        (x) => x.id === category.id
                                      ) !== -1
                                    }
                                    inputProps={{
                                      'aria-labelledby': labelId,
                                    }}
                                    sx={{
                                      color: '#D4D4D4',
                                      '&.Mui-checked': {
                                        color: '#416AD4',
                                      },
                                    }}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  id={labelId}
                                  className="fs-15 fw-normal text-black font-skmodernist"
                                  primary={category?.title}
                                />
                              </ListItemButton>
                            </ListItem>
                          );
                        })} */}
                        <TreeView
                          nodes={categoriesTree}
                          checked={subCategoryAttributes?.checked}
                          expanded={subCategoryAttributes?.expanded}
                          onCheck={(checked, value) => {
                            console.log('TreeView', checked, value);
                            setSubCategoryTreeAtrributes({ checked });
                            // setChecked(checked);
                            subCategoryAttributes?.checked?.forEach((id) => {
                              selCategory.findIndex((x) => x.id === id) !== -1;
                            });
                            loading(true);
                            handleCategoryToggle(value);
                          }}
                          onExpand={(expanded) => {
                            setSubCategoryTreeAtrributes({ expanded });
                            handleCategoryToggle(expanded);
                          }}
                        />
                      </List>
                      <Divider
                        component="div"
                        className="my-3"
                        role="presentation"
                      />
                      <Typography className="fs-18 fw-medium font-skmodernsit mb-2">
                        Rating
                      </Typography>
                      <List
                        class="filter-web-list"
                        sx={{
                          width: '100%',
                          bgcolor: 'background.paper',
                          padding: '0px',
                        }}
                      >
                        {ratingArr.map((rating, index) => {
                          const labelId = `checkbox-list-secondary-label-${index}`;
                          return (
                            <ListItem key={index} disablePadding>
                              <ListItemButton
                                sx={{ paddingLeft: '0px' }}
                                onClick={handleRatingToggle(rating)}
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    edge="start"
                                    onChange={handleRatingToggle(rating)}
                                    checked={
                                      selRating.findIndex(
                                        (x) => x.label === rating.label
                                      ) !== -1
                                    }
                                    inputProps={{
                                      'aria-labelledby': labelId,
                                    }}
                                    sx={{
                                      color: '#D4D4D4',
                                      '&.Mui-checked': {
                                        color: '#416AD4',
                                      },
                                    }}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  id={labelId}
                                  className="fs-15 fw-normal text-black font-skmodernist"
                                  primary={rating?.label}
                                />
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                      </List>
                      <Divider
                        component="div"
                        className="my-3"
                        role="presentation"
                      />
                      <Typography className="fs-18 fw-medium font-skmodernsit mb-2">
                        City
                      </Typography>
                      <List
                        class="filter-web-list"
                        sx={{
                          width: '100%',
                          bgcolor: 'background.paper',
                          padding: '0px',
                        }}
                      >
                        {filterVCity &&
                          filterVCity?.map((city, index) => {
                            const labelId = `checkbox-list-secondary-label-${index}`;
                            return (
                              <ListItem key={index} disablePadding>
                                <ListItemButton
                                  sx={{ paddingLeft: '0px' }}
                                  onClick={handleCityToggle(city)}
                                >
                                  <ListItemIcon>
                                    <Checkbox
                                      edge="start"
                                      onChange={handleCityToggle(city)}
                                      checked={selCity.indexOf(city) !== -1}
                                      inputProps={{
                                        'aria-labelledby': labelId,
                                      }}
                                      sx={{
                                        color: '#D4D4D4',
                                        '&.Mui-checked': {
                                          color: '#416AD4',
                                        },
                                      }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    id={labelId}
                                    className="fs-15 fw-normal text-black font-skmodernist"
                                    primary={city}
                                  />
                                </ListItemButton>
                              </ListItem>
                            );
                          })}
                      </List>
                      <Divider
                        component="div"
                        className="my-3"
                        role="presentation"
                      />
                      <Typography className="fs-18 fw-medium font-skmodernsit mb-2">
                        Sort By
                      </Typography>

                      <List
                        class="filter-web-sort-list"
                        dense
                        sx={{ width: '100%', bgcolor: 'background.paper' }}
                      >
                        {sortArr &&
                          sortArr?.map((option, index) => {
                            const labelId = `radio-list-secondary-label-${index}`;
                            return (
                              <ListItem key={index} disablePadding>
                                <ListItemButton
                                  sx={{ paddingLeft: '0px' }}
                                  onClick={() => handleSortChange(option)}
                                >
                                  <ListItemIcon>
                                    <Radio
                                      edge="end"
                                      onChange={() => handleSortChange(option)}
                                      checked={selSort?.label === option?.label} //selRating.findIndex(x => x.label === rating.label) !== -1
                                      inputProps={{
                                        'aria-labelledby': labelId,
                                      }}
                                      sx={{
                                        color: '#D4D4D4',
                                        '&.Mui-checked': {
                                          color: '#416AD4',
                                        },
                                      }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    id={labelId}
                                    className="fs-15 fw-normal text-grey font-gotham"
                                    primary={option.label}
                                  />
                                </ListItemButton>
                              </ListItem>
                            );
                          })}
                      </List>
                    </Box>
                  </Box>
                </Grid>

                <Grid item md={8} lg={8}>
                  <div
                    className="mb-4 b2b-business"
                    style={{ display: 'none' }}
                  >
                    <Stack
                      direction="row"
                      spacing={1.5}
                      useFlexGap
                      flexWrap="wrap"
                    >
                      {/* <Chip
                        ref={nearRef}
                        label="Near Me"
                        clickable
                        sx={{ display: 'none' }}
                      /> */}
                    </Stack>
                  </div>
                  <List className="pt-0">
                    {vendors &&
                      vendors?.map((item, index) => {
                        let last_update = moment(item?.last_login)?.fromNow();
                        return (
                          <ListItem
                            className="b2b-list-item"
                            key={index}
                            style={
                              item?.status == 'approved'
                                ? { cursor: 'pointer' }
                                : {}
                            }
                          >
                            <Card
                              className="card"
                              onClick={() => {
                                if (item?.uuid) {
                                  GAEvents(
                                    'page_event_listing',
                                    'page_event_listing',
                                    'Clicked on vendor card item',
                                    {
                                      uuid: item?.uuid || '',
                                      company_name: item?.company_name || '',
                                      vendor_id: item?.id,
                                    }
                                  );
                                  if (
                                    item?.uuid &&
                                    item?.status == 'approved'
                                  ) {
                                    setSelectedVCategoryUUID(item.uuid);
                                    history.push(
                                      '/b2b-detail' + `/${item.uuid}`
                                    );
                                  }
                                } else {
                                  pushMessage(DIALOG_VENDOR_DETAIL_SCRN__MSG);
                                }
                              }}
                            >
                              <div className="d-flex justify-content-between">
                                <CardMedia
                                  className="category-media"
                                  image={
                                    item?.image_path
                                      ? `${COMPANY_LOGO_BASE_URL}${item.image_path}`
                                      : // eslint-disable-next-line no-undef
                                        defaultProfileImage
                                  }
                                  sx={{
                                    padding: '1em 1em 0 1em',
                                    objectFit: 'contain',
                                  }}
                                />
                                <CardContent className="content p-0">
                                  <div className="d-flex justify-content-between align-items-start w-100">
                                    <Typography
                                      variant="h5"
                                      className="fs-16 text-black mb-1 fw-bold font-skmodernist"
                                      component="div"
                                    >
                                      {item?.company_name || ''}
                                    </Typography>
                                    <div>
                                      {item?.status == 'approved' && (
                                        <RightArrowIcon />
                                      )}
                                    </div>
                                  </div>

                                  <Typography
                                    variant="body2"
                                    className="fs-13 text-grey mb-1 font-gotham"
                                  >
                                    {item?.business_description || ''}
                                  </Typography>

                                  <Typography
                                    variant="body2"
                                    className="fs-13 text-secondary mb-1 font-gotham"
                                  >
                                    City : {item?.city}
                                  </Typography>
                                  <Box>
                                    <Rating
                                      name="read-only"
                                      value={item?.rating}
                                      size="small"
                                      precision={0.1}
                                      readOnly
                                    />
                                  </Box>
                                </CardContent>
                              </div>
                              <CardContent className="p-0 mt-1">
                                <Box className="d-flex justify-content-between">
                                  <div className="d-flex flex-column">
                                    <div className="d-flex align-items-center mb-1">
                                      <CircleIcon
                                        style={{
                                          color: '#1843A0',
                                          width: '7px',
                                          height: '7px',
                                          marginRight: '10px',
                                        }}
                                      />
                                      <Typography
                                        variant="body2"
                                        className="fs-13 text-darkgrey mb-1 font-skmodernist"
                                      >
                                        Last seen{' '}
                                        {last_update !== 'Invalid date'
                                          ? last_update
                                          : ''}
                                      </Typography>
                                    </div>
                                  </div>
                                  <div className="d-flex flex-column">
                                    <Typography
                                      variant="body2"
                                      className="fs-13 text-darkgrey mb-1 font-skmodernist"
                                    >
                                      Members
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      className="fs-13 text-secondary mb-1 font-skmodernist"
                                    >
                                      {item?.member_count || 0}
                                    </Typography>
                                  </div>
                                  <div className="d-flex flex-column">
                                    <Typography
                                      variant="body2"
                                      className="fs-13 text-darkgrey mb-1 font-skmodernist"
                                    >
                                      Products
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      className="fs-13 text-secondary mb-1 font-skmodernist"
                                    >
                                      {item?.product_count || 0}
                                    </Typography>
                                  </div>
                                </Box>
                                <Box>
                                  <Typography
                                    variant="body2"
                                    className="fs-16 fw-bold text-secondary mb-1 font-skmodernist"
                                  >
                                    {item?.status !== 'approved'
                                      ? 'Un-Verified'
                                      : ''}
                                  </Typography>
                                </Box>
                              </CardContent>

                              {item?.status == 'approved' && (
                                <>
                                  <Divider
                                    component="div"
                                    className="mt-2 mb-3"
                                    role="presentation"
                                  />
                                  <CardContent className="p-0 d-flex justify-content-between">
                                    <Button
                                      variant="contained"
                                      className="call-now-btn "
                                      onClick={(e) => {
                                        const mobileNo = item?.mobile_number
                                          ? '<mobile_no>'
                                          : '';
                                        GAEvents(
                                          'call_button',
                                          'call_button',
                                          'Clicked on vendor card item Call button',
                                          {
                                            uuid: item?.uuid || '',
                                            company_name:
                                              item?.company_name || '',
                                            vendor_id: item?.id,
                                            phone: mobileNo,
                                          }
                                        );
                                        handleCallNow(
                                          e,
                                          item?.mobile_number,
                                          item?.country_code || '',
                                          item?.id
                                        );
                                      }}
                                      disabled={item?.status !== 'approved'}
                                    >
                                      <CallNowIcon className="me-2" />
                                      Call Now
                                    </Button>
                                    <Button
                                      variant="contained"
                                      className="whatsapp-btn "
                                      onClick={(e) => {
                                        const whatsappNo = item?.whatsapp_number
                                          ? '<whatsapp_no>'
                                          : '';
                                        GAEvents(
                                          'whatsapp_chat_button',
                                          'whatsapp_chat_button',
                                          'Clicked on vendor card item Whatsapp button',
                                          {
                                            uuid: item?.uuid || '',
                                            company_name:
                                              item?.company_name || '',
                                            vendor_id: item?.id,
                                            whatsapp: whatsappNo,
                                          }
                                        );
                                        handleWhatsapp(
                                          e,
                                          item?.whatsapp_number,
                                          item?.country_code || '',
                                          item?.id
                                        );
                                      }}
                                      disabled={item?.status !== 'approved'}
                                    >
                                      <WhatsappIcon className="me-2" />
                                      WhatsApp
                                    </Button>
                                  </CardContent>
                                </>
                              )}
                            </Card>
                          </ListItem>
                        );
                      })}
                  </List>
                </Grid>
              </Grid>
            </form>
            <div className="homepage-footer" style={{ marginTop: '100px' }}>
              <WebFooter
                handleTerms={handleTerms}
                handlePrivacy={handlePrivacy}
                openInNewTab={openInNewTab}
                handleContactUs={handleContactUs}
                userData={userData}
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};
WebB2BCategoryForm.propTypes = {
  nearRef: any,
  isLoading: any,
  SearchComponent: any,
  setIsCallBackDrawerOpen: any,
  categoriesTree: any,
  selCategory: any,
  handleCategoryToggle: any,
  clearRatingData: any,
  handleRatingAndCategoryFilter: any,
  ratingArr: any,
  sortArr: any,
  handleRatingToggle: any,
  selRating: any,
  vendors: any,
  handleCallNow: any,
  handleWhatsapp: any,
  getVendorDetails: any,
  clearFilter: any,
  handleTerms: any,
  handlePrivacy: any,
  openInNewTab: func,
  toggleCallBackDrawer: any,
  selectedVCategory: any,
  BuisnessType: any,
  handleShippingDelivery: func,
  handleCancellationRefund: func,
  handleContactUs: func,
  handleClickHome: func,
  handleClickCategory: func,
  selCity: any,
  setSelCity: func,
  markNearByClick: bool,
  nearByCity: any,
  setSelRating: any,
  setSelCategory: any,
  selSort: any,
  setSelectedSort: any,
  getVendorList: any,
  handleCityToggle: func,
  filterVCity: array,
  setFilterVCity: func,
  allVCity: array,
  clearSortData: func,
  clearAllCity: func,
  pushMessage: func,
  subCategoryAttributes: any,
  setSubCategoryTreeAtrributes: func,
  setFilterParam: func,
  getSubCategoryVendorList: func,
  userData: object,
  loading: func,
  setSelectedVCategoryUUID: func,
};
export default WebB2BCategoryForm;
