import axiosInstance, {
  BUSINESS_POST,
  CATEGORIES,
  CITY_MASTER,
  COMPANY_LOGO_UPLOAD,
  COVER_PHOTO_UPLOAD,
  DELETE_SINGLE_IMAGE,
  GET_CATALOG_PRODUCTS,
  SIGNIN,
  SIGNUP,
  SINGLE_PRODUCT_UPDATE,
  SINGLE_PRODUCT_UPLOAD,
  SOCIAL_LINKS,
  UPDATE_USER,
  FRL_RESUME,
  FRL_BANNER,
  FRL_DELETE_BANNER,
  SEARCH_UUID,
  FRL_CERTIFICATE,
  ALLSTATE,
  ALLCITY,
  GET_USER,
  PARENT_CATEGORY,
  SOCIAL_LINK_BY_ID,
  SOCIAL_MEDIA_MASTER,
  ALL_CATEGORIES,
  // ALL_VENDORS,
  ENQUIRY,
  ENQUIRY_VENDOR,
  MASTER_EDUCATION,
  GET_SUBDOMAIN_API,
  MASTER_LANGUAGE,
  VENDOR_DETAILS,
  MEMBERS_CONTACTED,
  axiosSolrInstance,
  VENDORS_SEARCH,
  GET_UNIT,
  HOMEPAGE_WIDGET,
  B2B_SUBMIT_REQUIREMENT,
  GET_POST_REVIEW,
  FREELANCER_VENDOR_DETAILS,
  TEMPLATE_PAGE,
  SERVICE_TOOLS,
  ADD_ROLE,
  SET_ROLE,
  JOBS,
  SAVE_BUISNESS_CARD_IMAGE,
  POSTBUISNESSQRENQUIRY,
  JOB_LIST_SEARCH,
  JOBLIST,
  ENQUIRY_CHECK,
  JOB_APPLY,
  GET_SUBSCRIPTION,
  PAYMENT_RESPONSE,
  SUBSCRIPTION_ORDER,
  SUBSCRIPTION_DETAILS,
  JOB_COUNT,
  JOB_APPLICANT,
  HOMEPAGE_JOB,
  SEND_OTP,
  VERIFY_OTP,
  axiosSMSInstance,
  GENERATE_PAY_HASH,
  RATING_DATA,
  ENQUIRY_MSG,
  PAYMENT_ORDER,
  PAYMENT_VERIFY,
  BULK_UPLOAD,
  GET_TRENDING_PRODUCT_CATEGORY,
  GET_TRENDING_JOB_CATEGORY,
  GET_SUBCATEGORY_VENDOR_LIST,
  CHECK_SUB_DOMAIN_AVL,
  POST_TESTIMONIAL_API,
  GET_TESTIMONIAL_API,
  GET_PRODUCT_BY_REGISTRATION_ID,
  POST_MANAGE_DATA,
  UPDATE_OUTREACH_USER,
  MARK_OUTREACH_USER_VARIFIED,
  ALL_VENDORS_BY_UUID
} from './api';

// API endpoints
export const getRegistrationData = (userId) => {
  return axiosInstance.get(UPDATE_USER + userId);
};
export const saveBuisnessCardImage = (data) => {
  return axiosInstance.post(SAVE_BUISNESS_CARD_IMAGE, data);
};
export const postSignInUser = ({ mobile_number, country_code, otp }) => {
  return axiosInstance.post(SIGNIN, {
    mobile_number,
    country_code,
    otp,
  });
};
export const postBuisnessQrCard = ({ mobile_number, vendor_id }) => {
  return axiosInstance.post(POSTBUISNESSQRENQUIRY, {
    mobile_number,
    vendor_id,
  });
};

export const postSignUpUser = ({
  mobile_number,
  country_code,
  name,
  email,
  city,
  registration_type,
}) => {
  return axiosInstance.post(SIGNUP, {
    mobile_number,
    country_code,
    name,
    email,
    city,
    registration_type,
  });
};
export const getStateMasterApi = async () => {
  let res = await axiosInstance.get(ALLSTATE);
  return res?.status === 200 ? res.data.data : [];
};
export const getCityMasterApi = async () => {
  let res = await axiosInstance.get(ALLCITY);
  return res?.status === 200 ? res.data.data : [];
};

export const postBusinessDocuments = (regId, businessDocuments) => {
  return axiosInstance.post(BUSINESS_POST + regId, businessDocuments);
};

export const getBusinessDocuments = (regId) => {
  return axiosInstance.get(BUSINESS_POST + regId);
};

export const putUpdateUser = (regId, newUserData) => {
  return axiosInstance.put(UPDATE_USER + regId, newUserData);
};
export const putUpdateOutReachUser = (data, regId, userId) => {
  return axiosInstance.put(UPDATE_OUTREACH_USER + regId + '/' + userId, data);
};
export const markOutReachVarifiedApi = (data, regId) => {
  return axiosInstance.put(MARK_OUTREACH_USER_VARIFIED + regId, data);
};
export const getUpdatedUser = (regId) => {
  return axiosInstance.get(UPDATE_USER + regId, {});
};

export const getProdCategories = (type) => {
  return axiosInstance.get(CATEGORIES + type);
};

export const getAllCatalogProducts = (regId) => {
  return axiosInstance.get(GET_CATALOG_PRODUCTS + regId);
};

export const postSingleProduct = (formdata, onUploadProgress, customHeader) => {
  return axiosInstance.post(SINGLE_PRODUCT_UPLOAD, formdata, {
    headers: {
      'Content-Type': 'multipart/form-data',
      ...customHeader,
    },
    onUploadProgress,
  });
};

export const putSingleProductUpdate = (
  sku,
  formdata,
  onUploadProgress,
  customHeader
) => {
  return axiosInstance.put(SINGLE_PRODUCT_UPDATE + sku, formdata, {
    headers: {
      'Content-Type': 'multipart/form-data',
      ...customHeader,
    },
    onUploadProgress,
  });
};

export const deleteSingleProductUpdate = (sku) => {
  return axiosInstance.delete(SINGLE_PRODUCT_UPDATE + sku);
};

export const putCompanyLogo = (regId, formdata, onUploadProgress) => {
  return axiosInstance.put(COMPANY_LOGO_UPLOAD + regId, formdata, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
};

export const putCoverPhoto = (regId, formdata, onUploadProgress) => {
  return axiosInstance.put(COVER_PHOTO_UPLOAD + regId, formdata, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
};

export const getSocialLinksByUser = (regId) => {
  return axiosInstance.get(SOCIAL_LINKS + regId);
};

export const postSocialLinksByUser = (regId, socialLinks) => {
  return axiosInstance.post(SOCIAL_LINKS + regId, socialLinks);
};

export const putSocialLinksBySocialId = (socialId, socialLinks) => {
  return axiosInstance.put(SOCIAL_LINK_BY_ID + socialId, socialLinks);
};

export const deleteSocialLinksBySocialId = (socialId) => {
  return axiosInstance.delete(SOCIAL_LINK_BY_ID + socialId);
};
export const getAllCities = () => {
  return axiosInstance.get(CITY_MASTER);
};

export const getMiscellaneousType = (type) => {
  return axiosInstance.get(GET_UNIT + type);
};

export const deleteSingleProductImage = (productId, payload) => {
  return axiosInstance.delete(DELETE_SINGLE_IMAGE + productId, payload);
};

export const uploadResume = (regId, formData, onUploadProgress) => {
  return axiosInstance.post(FRL_RESUME + regId, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
};

export const getResume = (regId) => {
  return axiosInstance.get(FRL_RESUME + regId);
};

export const deleteSingleResume = (resumeId) => {
  return axiosInstance.delete(FRL_RESUME + resumeId);
};

export const getBannersData = (regId) => {
  return axiosInstance.get(FRL_BANNER + regId);
};

export const uploadBanner = (regId, formData, onUploadProgress) => {
  return axiosInstance.post(FRL_BANNER + regId, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
};

export const deleteSingleBanner = (bannerId) => {
  return axiosInstance.delete(FRL_DELETE_BANNER + bannerId);
};

export const postCertificate = (certData, headers) => {
  return axiosInstance.post(FRL_CERTIFICATE, certData, headers);
};
export const postReviews = (review) => {
  console.log('====postReviews review', review);
  return axiosInstance.post(GET_POST_REVIEW, review);
};
export const getReviews = () => {
  // console.log("====postReviews review", review);
  return axiosInstance.get(GET_POST_REVIEW);
};

export const getCertificate = (regId) => {
  return axiosInstance.get(FRL_CERTIFICATE + regId);
};

export const putCertificate = (certId, certData, config) => {
  return axiosInstance.put(FRL_CERTIFICATE + certId, certData, config);
};

export const deleteCertificate = (certId) => {
  return axiosInstance.delete(FRL_CERTIFICATE + certId);
};

export const getUser = async (userId) => {
  return axiosInstance.get(GET_USER + userId);
};

export const putUserData = async (userId, userData) => {
  return axiosInstance.put(GET_USER + userId, userData);
};

export const getParentCategory = async (regId) => {
  return axiosInstance.get(PARENT_CATEGORY + regId);
};

export const getSocialMediaMaster = () => {
  return axiosInstance.get(SOCIAL_MEDIA_MASTER);
};

export const getAllProdCategories = () => {
  return axiosInstance.get(ALL_CATEGORIES);
};

export const getVenderListApi = async (type, parentUUID, params) => {
  // http://localhost:3000/api/vendors/Business/Pune?categoryId=1&ratingMin=1&ratingMax=2
  var vURL = ALL_VENDORS_BY_UUID + type + '?';
  if (parentUUID != null) {
    vURL += 'categoryId=' + parentUUID;
  }
  if (params !== undefined && params !== '') {
    if (parentUUID != null && parentUUID !== '') {
      vURL += '&';
    }
    vURL += params;
  }
  let res = await axiosInstance.get(vURL);
  return res;
};

export const getSubCategoryVenderListApi = async (type, params) => {
  // http://localhost:3000/api/vendors/Business/Pune?categoryId=1&ratingMin=1&ratingMax=2
  var vURL = GET_SUBCATEGORY_VENDOR_LIST + type + '?';

  if (params !== undefined && params !== '') {
    vURL += params;
  }
  let res = await axiosInstance.get(vURL);
  return res;
};

export const getEnquiryApi = async (id, type) => {
  let res = await axiosInstance.get(`${ENQUIRY_VENDOR}/${id}`, {
    params: {
      type,
    },
  });
  // console.log("===getEnquiryApi ", res);
  return res?.status === 200 ? res.data.data : [];
};

export const postEnquiryApi = async (id, payload) => {
  let res = await axiosInstance.post(ENQUIRY, payload);
  // console.log("===postEnquiryApi ", res.data);
  return res?.status === 201 ? res.data : [];
};

export const getEducationDetails = async () => {
  let res = await axiosInstance.get(MASTER_EDUCATION);
  console.log('masterEducation :', res.data);
  return res;
};

export const getLanguageDetails = async () => {
  let res = await axiosInstance.get(MASTER_LANGUAGE);
  return res;
};

export const postMembersContactedApi = async (registrationId, member_phone) => {
  let res = await axiosInstance.post(MEMBERS_CONTACTED, {
    registrationId,
    member_phone,
  });
  // console.log("===postEnquiryApi ", res.data);
  return res?.status === 201 || res?.status === 200 ? res.data : [];
};
//MEMBERS_CONTACTED
export const getVendorDetailsApi = async (reg_id) => {
  let res = await axiosInstance.get(`${VENDOR_DETAILS}/${reg_id}`);
  console.log('===getVendorDetailsApi ', res);
  return res?.status === 200
    ? {
        reviewStarsCount: res.data.reviewStarsCount,
        reviewStarsPercentages: res.data.reviewStarsPercentages,
        ...res.data.data,
      }
    : [];
};
export const getFreelancerVendorDetailsApi = async (reg_id) => {
  let res = await axiosInstance.get(`${FREELANCER_VENDOR_DETAILS}/${reg_id}`);
  // console.log("===getVendorDetailsApi ", res);
  return res?.status === 200
    ? {
        reviewStarsCount: res.data.reviewStarsCount,
        reviewStarsPercentages: res.data.reviewStarsPercentages,
        ...res.data.data,
      }
    : [];
};
//VENDOR_DETAILS

export const getVendorSearchAPI = async (text) => {
  return axiosSolrInstance.get(VENDORS_SEARCH + text);
};

export const getApiTrendingProductCategory = async () => {
  return axiosInstance.get(GET_TRENDING_PRODUCT_CATEGORY);
};
export const searchUUIDApi = async (UUID) => {
  return axiosInstance.get(`${SEARCH_UUID}/${UUID}`);
};
export const postApiTrendingProductCategory = async (registrationId) => {
  return axiosInstance.post(GET_TRENDING_PRODUCT_CATEGORY, { registrationId });
};

export const getApiTrendingJobCategory = async () => {
  return axiosInstance.get(GET_TRENDING_JOB_CATEGORY);
};
export const postApiTrendingJobCategory = async (jobId) => {
  return axiosInstance.post(GET_TRENDING_JOB_CATEGORY, {
    jobId: Number(jobId),
  });
};

export const getHomepagewidget = async () => {
  let res = await axiosInstance.get(HOMEPAGE_WIDGET);
  return res;
};

export const getTemplatePage = async () => {
  let res = await axiosInstance.get(TEMPLATE_PAGE);
  return res;
};

export const postBusinessRequirementApi = async (data) => {
  let res = await axiosInstance.post(B2B_SUBMIT_REQUIREMENT, data);
  // console.log("===postEnquiryApi ", res.data);
  return res;
};

export const addRoleToUser = (user_id, data) => {
  return axiosInstance.put(ADD_ROLE + user_id, data);
};

export const setRoleToUser = (user_id, data) => {
  return axiosInstance.put(SET_ROLE + user_id, data);
};

export const getBusinessRequirementApi = async (params) => {
  let res = await axiosInstance.get(B2B_SUBMIT_REQUIREMENT + params);
  return res;
};

export const deleteBusinessRequirementApi = async (id) => {
  let res = await axiosInstance.delete(B2B_SUBMIT_REQUIREMENT + id);
  return res;
};

export const putBusinessRequirementApi = (regId, data) => {
  return axiosInstance.put(B2B_SUBMIT_REQUIREMENT + regId, data);
};

export const postJOBApi = async (payload) => {
  let res = await axiosInstance.post(JOBS, payload);
  return res;
};

export const putJOBApi = (regId, data) => {
  return axiosInstance.put(JOBS + regId, data);
};

export const deleteJOBApi = async (id) => {
  let res = await axiosInstance.delete(JOBS + id);
  return res;
};

export const getJOBAPI = async () => {
  return axiosInstance.get(JOBS);
};

export const getElynkerTools = async () => {
  let res = await axiosInstance.get(SERVICE_TOOLS);
  return res;
};

export const getJobListByTitleApi = async (title) => {
  let res = await axiosInstance.get(JOB_LIST_SEARCH + title);
  return res;
};

export const getJobListByFilterApi = async (title, params) => {
  var vURL = JOBLIST + title + '?';
  if (params !== undefined && params !== '') {
    vURL += params;
  }
  let res = await axiosInstance.get(vURL);
  return res;
};

export const getEnquiryCheck = async (reg_id) => {
  let res = await axiosInstance.get(`${ENQUIRY_CHECK}/${reg_id}`);
  return res;
};

export const postApplyJob = (formData, onUploadProgress) => {
  return axiosInstance.post(JOB_APPLY, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
};

export const getSubscriptionData = () => {
  return axiosInstance.get(GET_SUBSCRIPTION);
};
export const getSubscriptionById = (id) => {
  return axiosInstance.get(GET_SUBSCRIPTION + '/' + id);
};

/**
 * @author                                  Jay Vishwakarma
 * @description                             this endpoint is used to post PayU Payment Response of the transaction with
 * 											a order id (txnid) generated by us
 * @param {{
 *  order_id: number
 *  mihpayid: string
 *  mode: string
 *  status: string
 *  unmappedstatus: string
 *  key: string
 *  txnid: string
 *  amount: number
 *  cardCategory: string
 *  discount: number
 *  net_amount_debit: number
 *  addedon: date
 *  productinfo: string
 *  firstname: string
 *  lastname: string
 *  address1: string
 *  address2: string
 *  city: string
 *  state: string
 *  country: string
 *  zipcode: string
 *  email: string
 *  phone: string
 *  udf1: string
 *  udf2: string
 *  udf3: string
 *  udf4: string
 *  udf5: string
 *  udf6: string
 *  udf7: string
 *  udf8: string
 *  udf9: string
 *  udf10: string
 *  hash: string
 *  field1: string
 *  field2: string
 *  field3: string
 *  field4: string
 *  field5: string
 *  field6: string
 *  field7: string
 *  field8: string
 *  field9: string
 *  payment_source: string
 *  PG_TYPE: string
 *  bank_ref_num: string
 *  bankcode: string
 *  error: string
 *  error_Message: string
 *  name_on_card: string
 *  cardnum: string
 *  cardhash: string
 *  created_by: number
 *  updated_by: number
 * }}  	body        request body
 */
export const postPaymentResponse = (body) => {
  return axiosInstance.post(PAYMENT_RESPONSE, body);
};

/**
 * @typedef {"success" | "pending" | "hold" | "pifraude"} OrderStatus
 */

/**
 * @author                                  Jay Vishwakarma
 * @description                             this endpoint is used to post subscription order before the payment
 * 											transaction on payu hosted checkout
 * @param {{
 *  subscription_id: number
 *  registration_id: number
 *  subscriber_name: string,
 *  subscription_type: string,
 *  subscription_name: string
 *  requests: string
 *  response: string
 *  orders_status: OrderStatus,
 *  created_by: number
 *  updated_by: number
 * }}
 * body        request body
 */
export const postSubscriptionOrder = (body) => {
  return axiosInstance.post(SUBSCRIPTION_ORDER, body);
};

/**
 * @author                                  Jay Vishwakarma
 * @description                             this endpoint is used to put subscription order before the payment
 * 											transaction on payu hosted checkout
 * @param {{
 *  subscription_id: number
 *  registration_id: number
 *  subscriber_name: string,
 *  subscription_type: string,
 *  subscription_name: string
 *  requests: string
 *  response: string
 *  orders_status: OrderStatus,
 *  created_by: number
 *  updated_by: number
 * }}
 * body        request body
 */
export const putSubscriptionOrder = (id, body) => {
  return axiosInstance.put(SUBSCRIPTION_ORDER + `/${id}`, body);
};

/**
 * @author                                  Jay Vishwakarma
 * @description                             this endpoint is used to post subscription deatils after payment is successfull
 * @param {{
 *  registration_id: number;
 *  subscription_details_id: number;
 *  subscription_id: number;
 *  order_id: number;
 *  subscribed_services: string;
 *  status: boolean;
 *  valid_from: date;
 *  valid_to: date;
 *  created_by: number;
 *  updated_by: number;
 * }}
 * body        request body
 */
export const postSubscriptionDetails = (body) => {
  return axiosInstance.post(SUBSCRIPTION_DETAILS, body);
};

export const getSubscriptionDetailsBySearch = (key, value) => {
  return axiosInstance.get(SUBSCRIPTION_DETAILS + `/search/${key}/${value}`);
};

export const getOrderBySearch = (value = '', key = '') => {
  return axiosInstance.get(SUBSCRIPTION_ORDER + `/search/${key}/${value}`);
};

export const getOrderById = (id) => {
  return axiosInstance.get(SUBSCRIPTION_ORDER + `/${id}`);
};

export const getApplicantCount = (id) => {
  return axiosInstance.get(JOB_COUNT + `/${id}`);
};

export const getApplicantData = (jobid) => {
  return axiosInstance.get(JOB_APPLICANT + `/${jobid}`);
};

export const getHomepageJob = () => {
  return axiosInstance.get(HOMEPAGE_JOB + '/active');
};

export const postSendOTP = (body) => {
  return axiosSMSInstance.post(SEND_OTP, body);
};

export const postVerifyOTP = (body) => {
  return axiosSMSInstance.post(VERIFY_OTP, body);
};
export const postPaymentDataForHash = (body) => {
  return axiosInstance.post(GENERATE_PAY_HASH, body);
};

export const getJobRatingData = (reg_id) => {
  return axiosInstance.get(RATING_DATA + `/${reg_id}`);
};
export const postSendEnquiryMsg = (body) => {
  return axiosSMSInstance.post(ENQUIRY_MSG, body);
};

export const getPageCMSbySearch = (key, value) => {
  return axiosInstance.get(TEMPLATE_PAGE + `/search/${key}/${value}`);
};

export const isSubDomainAvlApi = async (value) => {
  let res = await axiosInstance.get(CHECK_SUB_DOMAIN_AVL + value);
  return res;
};

export const getSubDomainApi = async () => {
  let res = await axiosInstance.get(GET_SUBDOMAIN_API);
  return res;
};
export const postTetimonial = async (data) => {
  let res = await axiosInstance.post(POST_TESTIMONIAL_API, data);
  return res;
};
export const updateTetimonialApi = async (id, data) => {
  console.log('updateTetimonialApi', data, id);
  console.log(`updateTetimonialApi ${POST_TESTIMONIAL_API}/${id}`);

  let res = await axiosInstance.put(`${POST_TESTIMONIAL_API}/${id}`, data);
  return res;
};
export const deleteTetimonialApi = async (id) => {
  let res = await axiosInstance.delete(POST_TESTIMONIAL_API + '/' + id);
  return res;
};
export const getTetimonialById = async (id) => {
  let res = await axiosInstance.get(
    GET_TESTIMONIAL_API + 'registrationId/' + id
  );
  return res;
};
export const postManagedData = async (data) => {
  let res = await axiosInstance.post(POST_MANAGE_DATA, data);
  return res;
};

export const updateManagedDataApi = async (data, id) => {
  let res = await axiosInstance.put(POST_MANAGE_DATA + '/' + id, data);
  return res;
};

export const getProductsByRegistration = async (id) => {
  let res = await axiosInstance.get(
    GET_PRODUCT_BY_REGISTRATION_ID + 'registrationId/' + id
  );
  return res;
};
export const getSellerWebsiteDataByRegistration = async (id) => {
  let res = await axiosInstance.get(
    POST_MANAGE_DATA + '/search/registrationId/' + id
  );
  return res;
};
export const getSellerWebsiteDataById = async (id) => {
  let res = await axiosInstance.get(POST_MANAGE_DATA + '/' + id);
  return res;
};
export const getTetimonialByTestimonialId = async (id) => {
  let res = await axiosInstance.get(POST_TESTIMONIAL_API + '/' + id);
  return res;
};
/**
 * @author                                  Jay Vishwakarma
 * @description                             this endpoint is used to create a razorpay order, then after this payment
 * 											gateway is supposed to open.
 * @param {{
 *  amount: string;
 *  currency: string;
 * }} 							body        request body
 */
export const postPaymentOrder = (body) => {
  return axiosInstance.post(PAYMENT_ORDER, body);
};

/**
 * @author                                  Jay Vishwakarma
 * @description                             this endpoint is used to verify a razorpay order/transaction to check if
 * 											payment is not interfered and he payment is successfull or failed, then
 * 											after this payment gateway is supposed to open.
 * @param {{
 *  razorpay_order_id: string;
 *  razorpay_payment_id: string;
 *  razorpay_signature: string;
 * }} 							body        request body
 */
export const postPaymentVerify = (body) => {
  return axiosInstance.post(PAYMENT_VERIFY, body);
};

export const bulkUploadProduct = (formdata, onUploadProgress, customHeader) => {
  return axiosInstance.post(BULK_UPLOAD, formdata, {
    headers: {
      'Content-Type': 'multipart/form-data',
      ...customHeader,
    },
    onUploadProgress,
  });
};
