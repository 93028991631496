/* eslint-disable no-undef */
import React, { useState, useRef, useEffect, useCallback } from 'react';
import '../../../common/fontsize.css';
import '../../../index.css';
import {
  Container,
  Grid,
  Typography,
  Button,
  InputAdornment,
  IconButton,
  Drawer,
  Backdrop,
  CircularProgress,
  Box,
  FormHelperText,
} from '@mui/material';
import { GAEvents } from '../../../utils/helper-funtions';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactComponent as ElynkerLogo } from '../../../assets/elynker-logo.svg';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import AddBtnIcon from '../../../assets/add-btn-icon.svg';
import OutlinedInput from '@mui/material/OutlinedInput';
import ProductCloseIcon from '../../../assets/product-close-icon.svg';
import ImageDialog from '../../../components/ImageOverlay';

import {
  arrayOf,
  shape,
  func,
  array,
  string,
  number,
  object,
  oneOfType,
  bool,
} from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import saveServiceImage, {
  getServicesList,
  setServiceDesc,
  setServiceDetail,
  setServiceBudget,
  setServiceYOfExp,
  setServiceSubCategory,
  uploadServiceData,
  editProductData,
  removeServiceImage,
  removeAddServiceImage,
  resetFLAddServiceData,
  setServicePortfolioLink,
  setDeleteProdImages,
} from './redux/actions/add-service-action';
import CloseIcon from '@mui/icons-material/Close';
import CustomizedTreeView from '../../../components/TreeDropDown/TreeDropDown';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  getProductCategories,
  setBusinessType,
} from '../../OfferedServices/redux/actions/offered-services-form-action';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { PRODUCT_IMAGE_BASE_URL } from '../../../utils/APIs/api';
import { loading } from '../../../store/actions/rootActions';
import Resizer from 'react-image-file-resizer';
import { pushMessage } from '../../../components/MessageBox/redux/actions/message-box-action';
import {
  CLICKED,
  FL_ADDSERVICE_TITLE,
  FREELANCER,
  LOAD,
} from '../../../utils/constants';
//
import { resetMyCatalogData } from '../../MyCatalog/redux/actions/mycatalog-action';
import useValidation from '../../../hooks/useValidation';
import { hasAnyErrorPresent } from '../../../utils/validation';
import ElynkerLogoComponent from '../../../components/ElynkerLogo/ElynkerLogo';
import {
  handleErrorsAndScroll,
  scrollToInput,
  searchInArray,
} from '../../../utils/helper-funtions';
import { stickyButtonStyle } from '../../../utils/sx-styles';
import { useMediaQuery } from 'react-responsive';
import { ConfirmationDialog } from '../../../components/ActionPopup';

// const CircularJSON = require('circular-json');

const theme = createTheme();

export function filterData(data, filterValues) {
  return data.filter((value) => {
    return filterValues
      .trim()
      .split(', ')
      .every((filterValue) => checkValue(value, filterValue));
  });
}

function checkValue(value, filterValue) {
  if (typeof value === 'string') {
    return value.toLowerCase().includes(filterValue.toLowerCase());
  } else if (
    typeof value === 'object' &&
    value !== null &&
    Object.keys(value).length > 0
  ) {
    if (Array.isArray(value)) {
      return value.some((v) => checkValue(v, filterValue));
    } else {
      return Object.values(value).some((v) => checkValue(v, filterValue));
    }
  } else {
    return false;
  }
}

export function list_to_tree(list) {
  let map = {},
    node,
    roots = [],
    i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i; // initialize the map
    list[i].children = []; // initialize the children
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.parent_id !== null) {
      // if you have dangling branches check that map[node.parent_id] exists
      list[map[node.parent_id]]?.children.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
}

// Function to create a File object from a URL
// async function urlToFile(url) {
//   try {
//     const response = await fetch(url);
//     const blob = await response.blob();
//     const filename = url.substring(url.lastIndexOf('/') + 1);
//     return new File([blob], filename, { type: blob.type });
//   } catch (err) {
//     return;
//   }
// }

// Function to create a custom FileList-like object from a list of File objects
// function createFileList(files) {
//   const dataTransfer = new DataTransfer();
//   files.forEach((file) => {
//     dataTransfer.items.add(file);
//   });
//   return dataTransfer.files;
// }

const FLAddServiceForm = ({
  images,
  saveServiceImage,
  getProductCategories,
  userData,
  selectedLvl1Categories,
  b2bprodCategories,
  setServiceDesc,
  setServiceDetail,
  setServiceBudget,
  setServiceYOfExp,
  setServiceSubCategory,
  uploadServiceData,
  businessType,
  title,
  description,
  budget,
  yearOfExp,
  subCategoryId,
  singleProduct,
  pageActionType,
  editProductData,
  removeServiceImage,
  // resetFLAddServiceData,
  portfolioLink,
  setBusinessType,
  setServicePortfolioLink,
  loading,
  isLoading,
  deleteProdImgs,
  setDeleteProdImages,
  removeAddServiceImage,
  pushMessage,
  resetMyCatalogData,
}) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const categoryText =
    pageActionType == 'Edit' ? pageActionType + ' Service' : 'Add Service';
  const inputRef = useRef();
  const [categoriesTreeFilter, setCategoriesTreeFilter] = useState([]);
  const [categorySearch, setCategorySearch] = useState('');
  const [open, setOpen] = useState(false);
  const [filteredCategory, setFilteredCategory] = useState([]);
  const [categoriesTree, setCategoriesTree] = useState([]);

  const [openImageModal, setOpenImageModal] = useState(false);
  const handleImageOpen = () => setOpenImageModal(true);
  const handleImageClose = () => setOpenImageModal(false);
  const [imgIndex, setImgIndex] = useState();

  const { validateInput, errors } = useValidation();
  // const [yearExp, setYearExp] = useState("");
  const [count, setCount] = useState(description?.length || 0);
  const history = useHistory();

  const changeHandler = async (e) => {
    const files = e.target.files;
    console.log('files', files);
    const selectedFileArr = Array.from(files);
    console.log('selectedFileObj data :', selectedFileArr);
    for (const selectedFileObj of selectedFileArr) {
      if (
        !selectedFileObj?.type.includes('jpeg') &&
        !selectedFileObj?.type.includes('jpg') &&
        !selectedFileObj?.type.includes('png')
      ) {
        e.preventDefault();
        pushMessage('Please add Valid file type');
        e.target.value = null;
        return;
      }
    }
    let newImages = images ? [...images, ...files] : [...files];

    if (newImages.length > 5) {
      pushMessage('You can upload up to 5 photos.');
      e.preventDefault();
      e.target.value = null;
      return;
    }
    validateInput('photos', newImages);
    let compressedImages = await compressImages(files);
    saveServiceImage(compressedImages);
    e.target.value = null;
  };

  const compressImages = (files) => {
    return Promise.all(
      Array.from(files).map((file) => {
        return new Promise((resolve) => {
          Resizer.imageFileResizer(
            file,
            500, // New width
            500, // New height
            'JPEG', // Format
            75, // Quality
            0, // Rotation
            (uri) => {
              resolve(uri);
            },
            'file'
          );
        });
      })
    );
  };

  const onPasteDescription = (event) => {
    // clipboardData = event.clipboardData || event?.window?.clipboardData || event.originalEvent.clipboardData;
    const input = (event.clipboardData || event.window.clipboardData).getData(
      'text'
    );
    const newInput = description ? description + input : input;
    const regex = /^[\s\S]*$/;
    if (regex.test(newInput)) {
      if (input?.length > 5000) {
        const text = input.substr(0, 5000);
        setServiceDesc(text);
        setCount(text?.length);
      } else {
        setServiceDesc(newInput);
        setCount(newInput?.length);
      }
    }
  };

  const onChangeDescription = (e) => {
    const input = e.target.value;
    // const regex = /^[a-zA-Z0-9\s!@#$%^&*()_+=[\]{};':"\\|,.<>/?`~-]{0,500}$/;
    const regex = /^[\s\S]*$/;
    if (regex.test(input)) {
      if (input?.length > 5000) {
        const text = input.substr(0, 5000);
        setServiceDesc(text);
        setCount(text?.length);
      } else {
        setServiceDesc(e.target.value);
        setCount(e.target.value?.length);
      }
    }
  };
  function getTitleById(data, id) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].id == id) {
        return data[i].title;
      }
    }
    return null; // Return null if ID is not found
  }

  const onChangeProdDetail = (e) => {
    const input = e.target.value;
    const regex = /^[a-zA-Z0-9\s!@#$%^&*()_+=[\]{};':"\\|,.<>/?`~-]{0,100}$/;
    if (regex.test(input)) {
      setServiceDetail(input);
      validateInput('flSerName', input);
    }
  };

  // const onChangeProdMoq = (e) => {
  //   try {
  //     setServiceYOfExp(Number(e.target.value));
  //   } catch (err) {
  //     setServiceYOfExp(0);
  //   }
  // };

  const onChangePortfolioLink = (e) => {
    const input = e.target.value;
    setServicePortfolioLink(input); // Handle invalid input (e.g., show error message)
    validateInput('flProtLink', input);
  };

  const onChangeYearOfExp = (e) => {
    try {
      const input = e.target.value.replace(/^0+(?!$|\.|0)/, '');
      // Allow numbers with 1 or 2 digits before the decimal point and up to 2 digits after the decimal point
      const regex = /^(\s*|\d{1,2}(\.\d{1,2})?)$/;
      if (regex.test(input)) {
        setServiceYOfExp(input);
      }
    } catch (err) {
      pushMessage('Please enter a valid year of experience');
    }
  };

  const onChangeBudget = (e) => {
    try {
      const input = e.target.value.replace(/^0+/, '');
      if (input == '') {
        setServiceBudget('');
        validateInput('flPriceHr', '');
        return;
      }
      const regex = /^\s*(\d{1,7}(\.\d{1,2})?)\s*$/;
      if (regex.test(input)) {
        setServiceBudget(input);
        validateInput('flPriceHr', input);
      }
    } catch (err) {
      // setServiceBudget("");
      // alert("Please enter service price");
      validateInput('flPriceHr', '');
    }
  };

  const handleSubCategoryChange = (e) => {
    if (!open) {
      setOpen(true);
    }
    setCategorySearch(e.target.value.trim());
    const filteredCategories = filterCategoriesInDeep(e.target.value);
    // console.log('filteredCategories0', filteredCategories);
    setFilteredCategory(filteredCategories);

    const searchResults = searchInArray(categoriesTree, e.target.value);
    console.log('searchResults 123', searchResults);
    setFilteredCategory(searchResults);
  };

  const filterCategoriesInDeep = (searchText) => {
    const filterCategorySearch = filterData(categoriesTreeFilter, searchText);
    if (filterCategorySearch.length > 0) {
      return checkChildrenAndLoop(filterCategorySearch, searchText);
    } else {
      return filterCategorySearch;
    }
  };

  const checkChildrenAndLoop = (childCategories, searchText) => {
    let newFilteredCategories = [];
    for (const category of childCategories) {
      if (category.children.length > 0) {
        const filterLvl2 = filterData(category.children, searchText);
        const filterLvl2Child = filterLvl2.findIndex(
          (child) => child.children.length > 0
        );
        if (filterLvl2Child !== -1) {
          const childArr = checkChildrenAndLoop(filterLvl2, searchText);
          newFilteredCategories.push({
            ...category,
            children: childArr,
          });
        } else {
          newFilteredCategories.push({ ...category, children: filterLvl2 });
        }
      } else {
        newFilteredCategories.push(category);
      }
    }
    return newFilteredCategories;
  };

  const successCallback = () => {
    history.goBack();
    pushMessage('Save Changes SucessFully');
  };

  function errorCallback(err) {
    loading(false);
    pushMessage(err);
  }

  async function handleSubmit() {
    if (
      !images?.length ||
      images?.length > 5 ||
      title === undefined ||
      title === '' ||
      !subCategoryId ||
      budget === undefined ||
      budget <= 0
    ) {
      if (!images?.length) {
        validateInput('photos', []);
        scrollToInput('photos');
        return;
      }
      if (images?.length > 5) {
        pushMessage('You can upload upto 5 Photo.');
        scrollToInput('photos');
        return;
      }
      if (title === undefined || title === '') {
        // alert('Please enter title.');
        validateInput('flSerName', '');
        scrollToInput('flSerName');
        return;
      }
      if (!subCategoryId) {
        // alert('Please enter the sub category');
        validateInput('flSerCategory', '');
        scrollToInput('flSerCategory');
        return;
      }

      if (budget === undefined || budget <= 0) {
        // alert('Please enter budget amout.');
        validateInput('flPriceHr', '');
        scrollToInput('flPriceHr');
        return;
      }
    }
    // if (yearOfExp <= 0 || !yearOfExp) {
    //   alert('Please enter year of experience.');
    //   return;
    // }
    else {
      if (!hasAnyErrorPresent(errors)) {
        const customHeader = {
          title,
          registrationid: userData?.registration?.id,
        };
        if (pageActionType !== 'Edit') {
          //Add new product
          loading(true);
          await uploadServiceData(
            images,
            Number(businessType) || 2,
            userData?.registration?.id,
            title,
            description === undefined ? '' : description,
            budget !== '' ? Number(budget) : 0,
            portfolioLink,
            yearOfExp !== '' ? Number(yearOfExp) : 0,
            subCategoryId,
            userData?.accessToken,
            successCallback,
            errorCallback,
            customHeader
          );
          loading(false);
        } else {
          //Edit existing product
          loading(true);
          if (deleteProdImgs.length > 0) {
            for (const itm of deleteProdImgs) {
              console.log('ITM of delete Prod Imgs', itm);
              await removeServiceImage(
                itm.imgIdx,
                itm.prod,
                itm.img,
                () => {
                  //   removeAddServiceImage(index);
                  console.log('Image deleted successfully');
                },
                () => {
                  pushMessage('Please try again, Error during image removing');
                }
              );
            }
          }
          await editProductData(
            singleProduct.sku,
            images,
            Number(businessType) || 2,
            userData?.registration?.id,
            title,
            description === undefined ? '' : description,
            budget !== '' ? Number(budget) : 0,
            portfolioLink,
            yearOfExp !== '' ? Number(yearOfExp) : 0,
            subCategoryId,
            userData?.accessToken,
            successCallback,
            errorCallback,
            customHeader
          );
          loading(false);
        }
      } else {
        handleErrorsAndScroll(errors);
      }
    }
  }

  const handleClick = () => {
    setOpen(!open);
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  // async function getFileFromUrl(url, name, defaultType = 'image/jpeg'){
  //   const response = await fetch(url);
  //   const data = await response.blob();
  //   return new File([data], name, {
  //     type: data.type || defaultType,
  //   });
  // }

  // async function createFileList(images){
  //   var tempImgList = [];
  //   let arrImg = images.split(',');
  //   let newImgList = arrImg.map(async (img, index)=>{
  //     const file = await getFileFromUrl(`${PRODUCT_IMAGE_BASE_URL}${img}`, img);
  //     return file;
  //   });
  //   return newImgList;
  // }

  const handleFileListCreation = useCallback(() => {
    if (singleProduct?.product_images?.length > 0) {
      const prodImgs = singleProduct?.product_images?.split(',');
      const newProdImg = prodImgs?.map((img) => {
        return PRODUCT_IMAGE_BASE_URL + img;
      });

      saveServiceImage(newProdImg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleProduct]);
  useEffect(() => {
    // Function to handle creating FileList and processing it
    setBusinessType('2');

    const handleFile = setTimeout(() => {
      if (pageActionType == 'Edit') {
        handleFileListCreation();
      }
    }, 500);

    return () => clearTimeout(handleFile);
  }, [pageActionType]);

  useEffect(() => {
    // const jsonString = CircularJSON.stringify(b2bprodCategories);
    // console.log('===b2bprodCategories678', jsonString);
    if (singleProduct && b2bprodCategories && b2bprodCategories.length > 0) {
      console.log('===singleProduct78', singleProduct);
      let singleProductCategory;
      //singleProduct.category_id
      if (singleProduct?.category_id && b2bprodCategories.length > 0) {
        singleProductCategory = getTitleById(
          b2bprodCategories,
          singleProduct.category_id
        );
        console.log('singleProductId after filter', singleProductCategory);
      }
      setCategorySearch(singleProductCategory);
      if (singleProduct?.category_id !== '') {
        setServiceSubCategory(Number(singleProduct?.category_id));
      } else {
        setServiceSubCategory(singleProduct?.category_id);
      }
    }
  }, [singleProduct, b2bprodCategories]);

  useEffect(() => {
    GAEvents(FL_ADDSERVICE_TITLE, 'Freelancer Add Service', LOAD, {
      page_title: 'Add Service',
      page_location: window.location.href,
      page_path: window.location.pathname,
      user_id: userData?.id || sessionStorage.getItem('random_id'),
      reg_uuid:
        userData?.registration?.uuid || sessionStorage.getItem('random_id'),
    });
    getProductCategories(FREELANCER);
    // console.log('===singleProduct788', b2bprodCategories);
    if (singleProduct) {
      setServiceDesc(singleProduct.description);
      setServiceDetail(singleProduct.title);
      setServiceYOfExp(Number(singleProduct.year_of_exp));
      setServiceBudget(singleProduct?.budget?.toString());
      setCategorySearch(singleProduct.category_id);
      setServicePortfolioLink(singleProduct.portfolio_link || '');
      setCount(singleProduct?.description?.length || 0);
    }

    // return ()=> resetFLAddServiceData();
  }, []);

  useEffect(() => {
    // console.log('selectedLvl1Categories', selectedLvl1Categories);
    let filteredLvl1 = [];
    selectedLvl1Categories.forEach((category) => {
      let selectedIdx = categoriesTree.findIndex(
        (itm) => itm.id === category.id
      );
      if (selectedIdx !== -1) {
        filteredLvl1.push(categoriesTree[selectedIdx]);
      }
    });
    setCategoriesTreeFilter(filteredLvl1);
  }, [categoriesTree, selectedLvl1Categories]);

  useEffect(() => {
    if (b2bprodCategories.length > 0) {
      const categoryTree = list_to_tree(b2bprodCategories);
      // console.log('CATEGORY TREE', categoryTree);
      setCategoriesTree(categoryTree);
    }
  }, [b2bprodCategories]);

  const handleImageClick = () => {
    // 👇️ open file input box on click of another element
    inputRef.current.click();
  };

  const deleteProdImage = ({ index, prod = null, img = '' }) => {
    if (pageActionType === 'Add') {
      removeAddServiceImage(index);
    } else {
      if (typeof img !== 'string') {
        removeAddServiceImage(index);
      } else {
        let newDelProdImgs = [...deleteProdImgs];
        const newObj = {
          imgIdx: index,
          prod,
          img,
        };
        newDelProdImgs.push(newObj);
        setDeleteProdImages(newDelProdImgs);
        removeAddServiceImage(index);
      }
    }
  };

  function goBackAndReset() {
    resetMyCatalogData();
    history.goBack();
  }
  const [viewImagedialogOpen, setViewImagedialogOpen] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState('');

  // Function to handle opening the dialog and setting the selected image
  const handleViewImageClick = (image) => {
    console.log('===handleViewImageClick', image);
    setSelectedImage(image);
    setViewImagedialogOpen(true);
  };

  // Function to handle closing the dialog
  const handleViewImageCloseDialog = () => {
    setViewImagedialogOpen(false);
    setSelectedImage('');
  };
  const handleConfirmDelete = () => {
    handleImageClose();
    deleteProdImage(imgIndex);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xl">
        {isLoading && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <ConfirmationDialog
          open={openImageModal}
          handleClose={handleImageClose}
          title="Elynker"
          content="Are you sure you want to delete a photo?"
          onConfirm={handleConfirmDelete}
          confirmText="Yes"
          cancelText="No"
        />
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: '67vh' }}
        >
          <Grid item xs={12}>
            <div className="my-3 ms-2 d-flex align-items-center">
              <ArrowBackIosIcon onClick={() => goBackAndReset()} />
              <ElynkerLogoComponent>
                <ElynkerLogo className="ms-3" width={'100px'} height={'30px'} />
              </ElynkerLogoComponent>
            </div>
            <Divider
              component="div"
              className="main-divider mb-3"
              role="presentation"
            />
            <Typography
              variant="h4"
              align="left"
              alignItems="center"
              className="fs-22 mt-4 mb-2 fw-bold font-skmodernist"
              gutterBottom
              style={{ whiteSpace: 'pre-line' }}
            >
              {categoryText}
            </Typography>

            <form style={{ marginTop: '30px', marginBottom: '100px' }}>
              <Grid container rowSpacing={0} columnSpacing={10} className="">
                <Grid item md={6} lg={6} xs={12}>
                  {images === undefined ||
                  images === null ||
                  images?.length === 0 ? (
                    <div className="mb-3">
                      <InputLabel
                        htmlFor="inputCompanyName"
                        className="text-start fs-14 text-black font-skmodernist"
                      >
                        Add Service Images (Max 5){' '}
                        <span className="text-mandatory">*</span>
                        <br />
                        <span className="text-muted fs-12">
                          (JPEG or PNG files only)
                        </span>
                      </InputLabel>
                      <input
                        style={{ display: 'none' }}
                        id="photos"
                        ref={inputRef}
                        multiple
                        type="file"
                        accept="image/*"
                        onChange={changeHandler}
                      />

                      <Button
                        id="add_photo"
                        type="button"
                        fullWidth
                        variant="outlined"
                        name="add_photo"
                        value="add_photo"
                        className="add-button mt-2 fs-15 font-skmodernist fw-bold"
                        onClick={() => {
                          GAEvents(
                            FL_ADDSERVICE_TITLE,
                            'Freelancer Onboarding',
                            CLICKED,
                            {
                              userId:
                                userData?.id ||
                                sessionStorage.getItem('random_id'),
                              reg_uuid:
                                userData?.registration?.uuid ||
                                sessionStorage.getItem('random_id'),
                              title: 'Image Open',
                            }
                          );
                          handleImageClick();
                        }}
                        sx={{
                          borderRadius: '6px',
                          textTransform: 'capitalize',
                        }}
                      >
                        <img
                          src={AddBtnIcon}
                          alt="add-btn-icon"
                          className="user-logo"
                        />
                      </Button>
                      <FormHelperText
                        sx={{ color: 'red' }}
                        id="email-helper-text"
                      >
                        {errors.photos}
                      </FormHelperText>
                    </div>
                  ) : (
                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <InputLabel
                          htmlFor="inputproduct"
                          className="text-start fs-14 text-black font-skmodernist"
                        >
                          Add Service Images (Max 5){' '}
                          <span className="text-mandatory">*</span>
                        </InputLabel>
                        <Typography
                          variant="body1"
                          align="left"
                          className="fs-14 text-primary fw-normal font-gotham"
                          onClick={() => {
                            GAEvents(
                              FL_ADDSERVICE_TITLE,
                              'Freelancer Onboarding',
                              CLICKED,
                              {
                                userId:
                                  userData?.id ||
                                  sessionStorage.getItem('random_id'),
                                reg_uuid:
                                  userData?.registration?.uuid ||
                                  sessionStorage.getItem('random_id'),
                                title: 'View All',
                              }
                            );
                            toggleDrawer();
                          }}
                          gutterBottom
                        >
                          View All
                          {images ? ` (${Array.from(images)?.length})` : ''}
                        </Typography>
                      </div>

                      <div className="upload-image-container my-1">
                        {images &&
                          Array.from(images)
                            .slice(0, 3)
                            .map((img, imgIdx) => {
                              let imgSrc = '';

                              if (img instanceof File || img instanceof Blob) {
                                try {
                                  imgSrc = URL.createObjectURL(img);
                                } catch (error) {
                                  console.error(
                                    'Error creating object URL for image:',
                                    error
                                  );
                                }
                              } else if (typeof img === 'string') {
                                imgSrc = img;
                              } else {
                                console.error('Invalid image type:', img);
                              }

                              return (
                                <div className="image-inner-field" key={imgIdx}>
                                  {imgSrc && (
                                    <img
                                      onClick={() => {
                                        GAEvents(
                                          FL_ADDSERVICE_TITLE,
                                          'Freelancer Onboarding',
                                          CLICKED,
                                          {
                                            userId:
                                              userData?.id ||
                                              sessionStorage.getItem(
                                                'random_id'
                                              ),
                                            reg_uuid:
                                              userData?.registration?.uuid ||
                                              sessionStorage.getItem(
                                                'random_id'
                                              ),
                                            title: 'Image Open',
                                          }
                                        );
                                        handleViewImageClick(imgSrc);
                                      }}
                                      src={imgSrc}
                                      alt="product-image"
                                      className="product-image"
                                    />
                                  )}
                                  <img
                                    src={ProductCloseIcon}
                                    alt="product-close-icon"
                                    className="product-close-icon"
                                    onClick={() => {
                                      GAEvents(
                                        FL_ADDSERVICE_TITLE,
                                        'Freelancer Onboarding',
                                        CLICKED,
                                        {
                                          userId:
                                            userData?.id ||
                                            sessionStorage.getItem('random_id'),
                                          reg_uuid:
                                            userData?.registration?.uuid ||
                                            sessionStorage.getItem('random_id'),
                                          title: 'Image Close',
                                        }
                                      );
                                      const id = singleProduct.id;
                                      validateInput('photos', images);
                                      setImgIndex({
                                        index: imgIdx,
                                        prod: id,
                                        img: img,
                                      });
                                      handleImageOpen();
                                    }}
                                  />
                                </div>
                              );
                            })}

                        <div className="image-inner-field">
                          <input
                            id="photos"
                            type="file"
                            accept="image/*"
                            multiple
                            style={{ display: 'none' }}
                            ref={inputRef}
                            onChange={changeHandler}
                          />
                          <Button
                            type="button"
                            fullWidth
                            variant="outlined"
                            name="add_more_photo"
                            value="add_more_photo"
                            className="add-button image-inner-field fs-15 font-skmodernist fw-bold"
                            sx={{
                              borderRadius: '6px',
                              textTransform: 'capitalize',
                            }}
                            onClick={() => {
                              GAEvents(
                                FL_ADDSERVICE_TITLE,
                                'Freelancer Onboarding',
                                CLICKED,
                                {
                                  userId:
                                    userData?.id ||
                                    sessionStorage.getItem('random_id'),
                                  reg_uuid:
                                    userData?.registration?.uuid ||
                                    sessionStorage.getItem('random_id'),
                                  title: 'Add Image',
                                }
                              );
                              handleImageClick();
                            }}
                          >
                            <img
                              src={AddBtnIcon}
                              alt="add-btn-icon"
                              className="user-logo"
                            />
                          </Button>
                        </div>
                        <FormHelperText
                          sx={{ color: 'red' }}
                          id="email-helper-text"
                        >
                          {errors.photos}
                        </FormHelperText>
                      </div>

                      <ImageDialog
                        open={viewImagedialogOpen}
                        onClose={handleViewImageCloseDialog}
                        image={selectedImage}
                      />
                      <Drawer
                        xs={12}
                        anchor="bottom"
                        open={isDrawerOpen}
                        onClose={toggleDrawer}
                        sx={{
                          '& .MuiDrawer-paper': {
                            height: '90%',
                            borderTopLeftRadius: '20px',
                            borderTopRightRadius: '20px',
                          },
                        }}
                      >
                        <div className="p-3">
                          <div className="d-flex justify-content-between mb-3">
                            <Typography
                              variant="h5"
                              align="left"
                              alignItems="center"
                              className="fs-18 mb-2 fw-bold font-skmodernist "
                              gutterBottom
                            >
                              Services Photos
                            </Typography>
                            <CloseIcon
                              onClick={() => {
                                GAEvents(
                                  FL_ADDSERVICE_TITLE,
                                  'Freelancer Onboarding',
                                  CLICKED,
                                  {
                                    userId:
                                      userData?.id ||
                                      sessionStorage.getItem('random_id'),
                                    reg_uuid:
                                      userData?.registration?.uuid ||
                                      sessionStorage.getItem('random_id'),
                                    title: 'Close',
                                  }
                                );
                                toggleDrawer();
                              }}
                            />
                          </div>
                          <div className="product-grid mt-3">
                            {images &&
                              Array.from(images)?.map((img, imgIdx) => {
                                let imgSrc = '';

                                if (
                                  img instanceof File ||
                                  img instanceof Blob
                                ) {
                                  try {
                                    imgSrc = URL.createObjectURL(img);
                                  } catch (error) {
                                    console.error(
                                      'Error creating object URL for image:',
                                      error
                                    );
                                  }
                                } else if (typeof img === 'string') {
                                  imgSrc = img;
                                } else {
                                  console.error('Invalid image type:', img);
                                }

                                return (
                                  <div
                                    className="image-inner-field"
                                    key={imgIdx}
                                  >
                                    {imgSrc && (
                                      <img
                                        alt="product-image"
                                        className="product-image"
                                        src={imgSrc}
                                        onClick={() => {
                                          GAEvents(
                                            FL_ADDSERVICE_TITLE,
                                            'Freelancer Onboarding',
                                            CLICKED,
                                            {
                                              userId:
                                                userData?.id ||
                                                sessionStorage.getItem(
                                                  'random_id'
                                                ),
                                              reg_uuid:
                                                userData?.registration?.uuid ||
                                                sessionStorage.getItem(
                                                  'random_id'
                                                ),
                                              title: 'Image Open',
                                            }
                                          );
                                          handleViewImageClick(imgSrc);
                                        }}
                                      />
                                    )}
                                    <img
                                      src={ProductCloseIcon}
                                      alt="product-close-icon"
                                      className="product-close-icon"
                                      onClick={() => {
                                        GAEvents(
                                          FL_ADDSERVICE_TITLE,
                                          'Freelancer Onboarding',
                                          CLICKED,
                                          {
                                            userId:
                                              userData?.id ||
                                              sessionStorage.getItem(
                                                'random_id'
                                              ),
                                            reg_uuid:
                                              userData?.registration?.uuid ||
                                              sessionStorage.getItem(
                                                'random_id'
                                              ),
                                            title: 'Image Close',
                                          }
                                        );
                                        const id = singleProduct.id;
                                        setImgIndex({
                                          index: imgIdx,
                                          prod: id,
                                          img: img,
                                        });
                                        handleImageOpen();
                                      }}
                                    />
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </Drawer>
                    </div>
                  )}
                </Grid>
              </Grid>
              <Grid container rowSpacing={0} columnSpacing={10} className="">
                <Grid item md={6} lg={6} xs={12}>
                  <InputLabel
                    htmlFor="inputCIN"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    Service Name <span className="text-mandatory">*</span>
                  </InputLabel>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <OutlinedInput
                      id="flSerName"
                      // label="Mobile Number"
                      type="text"
                      placeholder="Enter service name"
                      autoFocus
                      fullWidth
                      margin="none"
                      onChange={onChangeProdDetail}
                      defaultValue={singleProduct?.title}
                      inputProps={{
                        maxLength: 100,
                      }}
                      value={title}
                      autoComplete="off"
                    />
                  </FormControl>
                  <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                    {errors.flSerName}
                  </FormHelperText>
                </Grid>

                <Grid item md={6} lg={6} xs={12}>
                  <InputLabel
                    htmlFor="subCategoryInput"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    Service Category <span className="text-mandatory">*</span>
                  </InputLabel>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <OutlinedInput
                      id="flSerCategory"
                      type="text"
                      placeholder="Select Category"
                      // required
                      // disabled={categoriesTreeFilter.length === 0}
                      fullWidth
                      margin="none"
                      value={categorySearch}
                      onChange={handleSubCategoryChange}
                      onClick={() => {
                        handleClick();
                      }}
                      autoComplete="off"
                      // onFocus={handleClick}
                      endAdornment={
                        <InputAdornment
                          position="end"
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            border: 'black',
                          }}
                        >
                          {categorySearch && (
                            <IconButton
                              aria-label="toggle password visibility"
                              edge="end"
                              onClick={() => {
                                setCategorySearch('');
                                const filteredCategories =
                                  filterCategoriesInDeep('');
                                setFilteredCategory(filteredCategories);

                                setServiceSubCategory('');
                                validateInput('flSerCategory', '');
                              }}
                            >
                              <CloseIcon
                                style={{
                                  color: '#727272',
                                  width: 16,
                                  height: 16,
                                  cursor: 'pointer',
                                  marginLeft: 4,
                                }}
                              />
                            </IconButton>
                          )}
                          <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                          >
                            <ArrowDropDownIcon className="arrow-icon" />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {open && (
                      <div
                        style={{
                          position: 'absolute',
                          boxShadow: '0px 2px 14px -3px rgba(0,0,0,0.5)',
                          top: '50px',
                          background: 'white',
                          zIndex: 1,
                          borderRadius: 4,
                          width: '100%',
                        }}
                      >
                        <CustomizedTreeView
                          categoriesTree={
                            filteredCategory.length > 0
                              ? filteredCategory
                              : categoriesTreeFilter.length > 0
                                ? categoriesTreeFilter
                                : categorySearch && categorySearch.length !== 0
                                  ? filteredCategory
                                  : categoriesTree
                          }
                          selectedCategory={(value) => {
                            // console.log(value);
                            GAEvents(
                              FL_ADDSERVICE_TITLE,
                              'Freelancer Onboarding',
                              CLICKED,
                              {
                                userId:
                                  userData?.id ||
                                  sessionStorage.getItem('random_id'),
                                reg_uuid:
                                  userData?.registration?.uuid ||
                                  sessionStorage.getItem('random_id'),
                                title: value.title + 'Selected',
                              }
                            );
                            setOpen(!open);
                            setCategorySearch(value.title);
                            setServiceSubCategory(value.id);
                            validateInput('flSerCategory', value.id);
                          }}
                        />
                      </div>
                    )}
                  </FormControl>
                  <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                    {errors.flSerCategory}
                  </FormHelperText>
                </Grid>

                <Grid item md={12} lg={12} xs={12}>
                  <div className="d-flex justify-content-between">
                    <InputLabel
                      htmlFor="inputCompanyName"
                      className="text-start fs-14 text-black font-skmodernist"
                    >
                      Service Description
                    </InputLabel>
                    <Typography
                      variant="body1"
                      align="left"
                      className="fs-12 text-lightgrey fw-normal mb-0 font-gotham"
                      gutterBottom
                    >
                      {5000 - count} Characters Left
                    </Typography>
                  </div>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <TextareaAutosize
                      id="inputAdditionalInfo"
                      placeholder="Enter description"
                      minRows={3} // You can adjust the number of rows as needed
                      maxRows={2} // You can adjust the number of rows as needed
                      onChange={onChangeDescription}
                      inputprops={{
                        maxLength: 5000,
                      }}
                      value={description}
                      onPaste={onPasteDescription}
                    />
                  </FormControl>
                </Grid>

                <Grid item md={6} lg={6} xs={12}>
                  <InputLabel
                    htmlFor="inputCIN"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    Price (Per Hour) <span className="text-mandatory">*</span>
                  </InputLabel>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <OutlinedInput
                      id="flPriceHr"
                      // label="Mobile Number"
                      type="text"
                      placeholder="Enter price"
                      fullWidth
                      margin="none"
                      onKeyDown={(evt) =>
                        ['e', 'E', '+', '-'].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      onChange={onChangeBudget}
                      // defaultValue={singleProduct?.budget}
                      value={isNaN(budget) ? '' : budget}
                      startAdornment={
                        <InputAdornment position="start">₹</InputAdornment>
                      }
                      inputMode="decimal"
                    />
                  </FormControl>
                  <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                    {errors.flPriceHr}
                  </FormHelperText>
                </Grid>

                <Grid item md={6} lg={6} xs={12}>
                  <InputLabel
                    htmlFor="inputCIN"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    Portfolio Link
                  </InputLabel>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <OutlinedInput
                      id="flProtLink"
                      // label="Mobile Number"
                      type="text"
                      placeholder="Enter portfolio link"
                      fullWidth
                      margin="none"
                      onChange={onChangePortfolioLink}
                      // defaultValue={singleProduct?.portfolioLink}
                      value={portfolioLink}
                      inputProps={{
                        maxLength: 200,
                      }}
                    />
                  </FormControl>
                  <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                    {errors.flProtLink}
                  </FormHelperText>
                </Grid>
                <Grid
                  item
                  md={6}
                  lg={6}
                  xs={12}
                  sx={{ marginBottom: isTabletOrMobile ? 15 : 0 }}
                >
                  <InputLabel
                    htmlFor="inputCIN"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    Years Of Experience
                  </InputLabel>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <OutlinedInput
                      id="flYoE"
                      // label="Mobile Number"
                      type="text"
                      placeholder="Enter years of experience"
                      fullWidth
                      margin="none"
                      onKeyDown={(evt) =>
                        ['e', 'E', '+', '-'].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      onChange={onChangeYearOfExp}
                      // defaultValue={singleProduct?.yearOfExp}
                      value={isNaN(yearOfExp) ? '' : yearOfExp}
                      inputProps={{
                        maxLength: 2,
                      }}
                      inputMode="tel"
                    />
                  </FormControl>
                  <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                    {errors.flYoE}
                  </FormHelperText>
                </Grid>
              </Grid>

              <Box sx={{ width: '100%' }}>
                <Box sx={[stickyButtonStyle]}>
                  <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    name="add_product"
                    value="add_product"
                    className="py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
                    onClick={() => {
                      GAEvents(
                        FL_ADDSERVICE_TITLE,
                        'Freelancer Onboarding',
                        CLICKED,
                        {
                          userId:
                            userData?.id || sessionStorage.getItem('random_id'),
                          reg_uuid:
                            userData?.registration?.uuid ||
                            sessionStorage.getItem('random_id'),
                          title: 'Save Service Details',
                        }
                      );
                      handleSubmit();
                    }}
                    sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
                  >
                    Save Service Details
                  </Button>
                </Box>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

FLAddServiceForm.propTypes = {
  images: oneOfType([object, array]),
  products: arrayOf(
    shape({
      images: array,
      type: string,
      registrationId: number,
      title: '',
      description: '',
      product_category: arrayOf(
        shape({
          id: number,
          name: string,
        })
      ),
      product_description: string,
      budget: string,
      min_order_qty: string,
    })
  ),
  prod_image: array,
  getServicesList: func,
  access_token: string,
  saveServiceImage: func,
  getProductCategories: func,
  userData: oneOfType([object, number]),
  b2bprodCategories: array,
  b2bLvl2CategoriesTree: array,
  b2bLvl1Categories: array,
  selectedLvl1Categories: array,
  setServiceDesc: func,
  setServiceDetail: func,
  setServiceBudget: func,
  setServiceYOfExp: func,
  setServiceSubCategory: func,
  uploadServiceData: func,
  type: number,
  title: string,
  description: string,
  budget: number,
  yearOfExp: string,
  subCategoryId: number,
  businessType: string,
  singleProduct: object,
  pageActionType: string,
  editProductData: func,
  removeServiceImage: func,
  resetFLAddServiceData: func,
  portfolioLink: string,
  setBusinessType: func,
  setServicePortfolioLink: func,
  isLoading: bool,
  loading: func,
  deleteProdImgs: array,
  setDeleteProdImages: func,
  removeAddServiceImage: func,
  pushMessage: func,
  resetMyCatalogData: func,
};

const mapStateToProps = (state) => {
  const { userData } = state.signin;
  const {
    images,
    title,
    description,
    budget,
    yearOfExp,
    category_id,
    subCategoryId,
    singleProduct,
    pageActionType,
    portfolioLink,
    deleteProdImgs,
  } = state.flService;
  const {
    b2bprodCategories,
    b2bLvl2CategoriesTree,
    b2bLvl1Categories,
    selectedLvl1Categories,
    businessType,
  } = state.offeredServices;
  const { isLoading } = state.root;

  return {
    userData,
    images,
    b2bprodCategories,
    b2bLvl2CategoriesTree,
    b2bLvl1Categories,
    selectedLvl1Categories,
    businessType,
    title,
    description,
    budget,
    yearOfExp,
    category_id,
    subCategoryId,
    singleProduct,
    pageActionType,
    portfolioLink,
    deleteProdImgs,
    isLoading,
  };
};

export default connect(mapStateToProps, {
  getServicesList,
  saveServiceImage,
  getProductCategories,
  setServiceDesc,
  setServiceDetail,
  setServiceBudget,
  setServiceYOfExp,
  setServiceSubCategory,
  uploadServiceData,
  editProductData,
  removeServiceImage,
  resetFLAddServiceData,
  setBusinessType,
  setServicePortfolioLink,
  setDeleteProdImages,
  loading,
  removeAddServiceImage,
  pushMessage,
  resetMyCatalogData,
})(FLAddServiceForm);
