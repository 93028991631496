export const SET_ALL_VENDORS = 'SET_ALL_VENDORS';
export const SET_VENDORS_DETAILS = 'SET_VENDORS_DETAILS';
export const SET_ENQUIRY = 'SET_ENQUIRY';
export const SEARCH_VENDORS = 'SEARCH_VENDORS';
export const SET_VENDOR_CITY = 'SET_VENDOR_CITY';
export const SET_FILTER_VCITY = 'SET_FILTER_VCITY';
export const SAVE_FL_DETAILS = 'SAVE_FL_DETAILS';
export const SET_FILTER_RATING = 'SET_FILTER_RATING';
export const SET_FILTER_CATEGORY = 'SET_FILTER_CATEGORY';
export const SET_FILTER_CITY = 'SET_FILTER_CITY';
export const SET_FILTER_NEARME = 'SET_FILTER_NEARME';
export const SET_FILTER_SORT = 'SET_FILTER_SORT';
export const RESET_CITY_FILTER = 'RESET_CITY_FILTER';
export const RESET_ALL_VENDOR_DATA = 'RESET_ALL_VENDOR_DATA';
export const MARK_NEAR_BY_CLICK = 'MARK_NEAR_BY_CLICK';
export const SET_WA_ENQUIRY = 'SET_WA_ENQUIRY';
export const SET_WA_CATE_ENQUIRY = 'SET_WA_ENQUIRY';
export const SET_SUBCATEGIRY_TREE_ATTRIBUTES =
  'SET_SUBCATEGIRY_TREE_ATTRIBUTES';
