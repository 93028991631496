import React, { useState, useRef, useEffect, useCallback } from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import {
  Container,
  Grid,
  Typography,
  Button,
  InputAdornment,
  IconButton,
  Backdrop,
  CircularProgress,
  FormHelperText,
  Box,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ADDCATALOG_TITLE, BUSINESS, CLICKED, FREELANCER } from '../../utils/constants';
import Resizer from 'react-image-file-resizer';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import AddBtnIcon from '../../assets/add-btn-icon.svg';
import OutlinedInput from '@mui/material/OutlinedInput';
import ProductCloseIcon from '../../assets/product-close-icon.svg';
import {
  arrayOf,
  shape,
  func,
  array,
  string,
  number,
  object,
  oneOfType,
  bool,
} from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import saveProductImage, {
  getProductsList,
  setProductDesc,
  setProductDetail,
  setProductBudget,
  setProductMOQ,
  setProductSubCategory,
  uploadProductData,
  editProductData,
  removeProdImage,
  resetAddCatalogData,
  removeAddProdImage,
  setProductUnit,
  setDeleteProdImages,
  getMiscellaneousData,
} from './redux/actions/add-catalog-action';
import CloseIcon from '@mui/icons-material/Close';
import ImageDialog from '../../components/ImageOverlay';
import CustomizedTreeView from '../../components/TreeDropDown/TreeDropDown';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  getProductCategories,
  setBusinessType,
} from '../OfferedServices/redux/actions/offered-services-form-action';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { PRODUCT_IMAGE_BASE_URL } from '../../utils/APIs/api';
import Drawer from '@mui/material/Drawer';
import { loading } from '../../store/actions/rootActions';
import { pushMessage } from '../../components/MessageBox/redux/actions/message-box-action';
import useValidation from '../../hooks/useValidation';
import { hasAnyErrorPresent } from '../../utils/validation';
import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
import SimpleDropdown from '../../components/SimpleDropDown';
import {
  GAEvents,
  handleErrorsAndScroll,
  scrollToInput,
  searchInArray,
} from '../../utils/helper-funtions';
import { stickyButtonStyle } from '../../utils/sx-styles';
import { ConfirmationDialog } from '../../components/ActionPopup';
// import { useMediaQuery } from 'react-responsive';
// import { styled } from '@mui/material/styles';
// import Box from '@mui/material/Box';
// import Paper from '@mui/material/Paper';

// const CircularJSON = require('circular-json');

const theme = createTheme();

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
// }));

export function filterData(data, filterValues) {
  return data.filter((value) => {
    return filterValues
      .trim()
      .split(', ')
      .every((filterValue) => checkValue(value, filterValue));
  });
}

function checkValue(value, filterValue) {
  if (typeof value === 'string') {
    return value.toLowerCase().includes(filterValue.toLowerCase());
  } else if (
    typeof value === 'object' &&
    value !== null &&
    Object.keys(value).length > 0
  ) {
    if (Array.isArray(value)) {
      return value.some((v) => checkValue(v, filterValue));
    } else {
      return Object.values(value).some((v) => checkValue(v, filterValue));
    }
  } else {
    return false;
  }
}

export function list_to_tree(list) {
  let map = {},
    node,
    roots = [],
    i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i; // initialize the map
    list[i].children = []; // initialize the children
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.parent_id !== null) {
      // if you have dangling branches check that map[node.parent_id] exists
      list[map[node.parent_id]]?.children.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
}
/* eslint-disable no-unused-vars */
// Function to create a File object from a URL
async function urlToFile(url) {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    const filename = url.substring(url.lastIndexOf('/') + 1);
    return new File([blob], filename, { type: blob.type });
  } catch (err) {
    return;
  }
}

// Function to create a custom FileList-like object from a list of File objects
function createFileList(files) {
  const dataTransfer = new DataTransfer();
  files.forEach((file) => {
    dataTransfer.items.add(file);
  });
  return dataTransfer.files;
}

/* eslint-enable no-unused-vars */
const AddCatalogForm = ({
  images,
  saveProductImage,
  getProductCategories,
  userData,
  selectedLvl1Categories,
  b2bprodCategories,
  setProductDesc,
  setProductDetail,
  setProductBudget,
  setProductMOQ,
  setProductSubCategory,
  uploadProductData,
  businessType,
  title,
  description,
  budget,
  moq,
  subCategoryId,
  singleProduct,
  pageActionType,
  editProductData,
  removeProdImage,
  removeAddProdImage,
  setProductUnit,
  unit,
  resetAddCatalogData,
  loading,
  isLoading,
  deleteProdImgs,
  setDeleteProdImages,
  pushMessage,
  getMiscellaneousData,
  adminUnits,
  setBusinessType,
}) => {
  // console.log('=====businessType567', businessType !== 2);
  const titleName = businessType === '2' ? 'Service' : 'Product';
  // const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const categoryText =
    pageActionType === undefined
      ? `Add Catalog ${titleName}`
      : pageActionType + ' ' + `Catalog ${titleName}`;
  const inputRef = useRef();

  const [categoriesTreeFilter, setCategoriesTreeFilter] = useState([]);
  const [categorySearch, setCategorySearch] = useState('');
  const [filteredUnits, setFilteredUnits] = useState([]);
  const [open, setOpen] = useState(false);
  const [unitModelopen, setUnitModelOpen] = useState(false);
  const [filteredCategory, setFilteredCategory] = useState([]);
  const [categoriesTree, setCategoriesTree] = useState([]);
  const [count, setCount] = useState(0);
  const [firstTime, setIsfirstTime] = useState(false);
  React.useEffect(() => {
    if (!firstTime && description) {
      setCount(Math.min(5000, Number(description.length)));
      setIsfirstTime(true);
    }
  }, [description, firstTime]);
  const history = useHistory();
  const { errors, validateInput } = useValidation();
  // const { errors } = useValidation()

  const changeHandler = async (e) => {
    const files = e.target.files;
    const selectedFileArr = Array.from(files);
    console.log('selectedFileObj data :', selectedFileArr);
    for (const selectedFileObj of selectedFileArr) {
      if (
        !selectedFileObj?.type.includes('jpeg') &&
        !selectedFileObj?.type.includes('jpg') &&
        !selectedFileObj?.type.includes('png')
      ) {
        e.preventDefault();
        pushMessage('Please add Valid file type');
        e.target.value = null;
        return;
      }
    }

    if (files && Array.from(files)?.length > 5) {
      pushMessage('You can upload upto 5 Photo.');
      e.preventDefault();
      e.target.value = null;
      return;
    } else {
      if (
        images &&
        Array.from(images)?.length + Array.from(files)?.length > 5
      ) {
        pushMessage('You can upload upto 5 Photo.');
        e.preventDefault();
        return;
      }

      let compressedImages = await compressImages(files);
      // console.log("===compressed image", compressedImages);
      validateInput('photos', [compressedImages]);
      saveProductImage(compressedImages);
      e.target.value = null;
    }
  };
  const compressImages = (files) => {
    return Promise.all(
      Array.from(files).map((file) => {
        return new Promise((resolve) => {
          Resizer.imageFileResizer(
            file,
            500, // New width
            500, // New height
            'JPEG', // Format
            75, // Quality
            0, // Rotation
            (uri) => {
              resolve(uri);
            },
            'file'
          );
        });
      })
    );
  };

  const onPasteDescription = (event) => {
    // clipboardData = event.clipboardData || event?.window?.clipboardData || event.originalEvent.clipboardData;
    const input = (event.clipboardData || event.window.clipboardData).getData(
      'text'
    );
    const newInput = description ? description + input : input;
    const regex = /^[\s\S]*$/;
    if (regex.test(newInput)) {
      if (newInput?.length > 5000) {
        const text = newInput.substr(0, 5000);
        setProductDesc(text);
        setCount(text?.length);
        validateInput('product_description', text);
      } else {
        setProductDesc(newInput);
        setCount(newInput?.length);
        validateInput('product_description', newInput);
      }
    }
  };
  const onChangeDescription = (e) => {
    const input = e.target.value;
    // const regex = /^[a-zA-Z0-9\s]{0,500}$/; {0,500}$/
    // const regex = /^[a-zA-Z0-9\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]{0,5000}$/;
    const regex = /^[\s\S]*$/;
    if (regex.test(input)) {
      if (input?.length > 5000) {
        const text = input.substr(0, 5000);
        setProductDesc(text);
        setCount(text?.length);
        validateInput('product_description', text);
      } else {
        setProductDesc(input);
        setCount(input?.length);
        validateInput('product_description', input);
      }
    }
  };
  function getTitleById(data, id) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].id == id) {
        return data[i].title;
      }
    }
    return null; // Return null if ID is not found
  }

  const onChangeProdDetail = (e) => {
    const input = e.target.value;
    const regex = /^[a-zA-Z0-9\s!@#$%^&*()_+=[\]{};':"\\|,.<>/?`~-]{0,100}$/;

    console.log('===onChangeProdDetail', input, errors);
    if (regex.test(input)) {
      setProductDetail(String(input));
      validateInput('product_name', input.trim());
    }
  };

  const onChangeProdMoq = (e) => {
    const input = e.target.value;
    const regex = /^[0-9]{0,3}$/;
    if (regex.test(input)) {
      console.log('==== Number(input)', Number(input));
      setProductMOQ(Number(input));
      validateInput('product_orderQuantity', Number(input));
    } else {
      console.log('==== Number(input) else', input);
    }
  };

  const onChangeBudget = (e) => {
    const input = e.target.value;
    const regex = /^\s*(\d{0,7}(\.\d{1,2})?)?\s*$/;

    if (regex.test(input)) {
      setProductBudget(Number(input));
      validateInput('product_price', input);
    } else if (input === '') {
      setProductBudget('');
      validateInput('product_price', '');
    }
  };

  const onChangeUnit = (unit) => {
    console.log('onChangeUnit', unit);
    setProductUnit(unit);
    validateInput('product_unit', unit);
    setUnitModelOpen(false);
  };

  const handleSubCategoryChange = (e) => {
    console.log('==handleSubCategoryChange 67', e.target.value);
    if (!open) {
      setCategorySearch('');
      setOpen(true);
    }

    setCategorySearch(e.target.value.trim());
    const filteredCategories = filterCategoriesInDeep(e.target.value);

    setFilteredCategory(filteredCategories);

    const searchResults = searchInArray(categoriesTree, e.target.value);
    console.log('searchResults 123', searchResults);
    setFilteredCategory(searchResults);
  };

  const handleUnitChange = (e) => {
    // console.log('==handleSubCategoryChange 67', e.target.value);
    if (!unitModelopen) {
      setUnitModelOpen(true);
    }
    //
    setProductUnit(e.target.value);
    const filteredUnitsFound = filterUnits(e.target.value);
    if (filteredUnitsFound.length === 0) {
      setUnitModelOpen(false);
    } else {
      setUnitModelOpen(true);
    }
    console.log('filteredCategories0', filteredUnitsFound);
    setFilteredUnits(filteredUnitsFound);
  };

  const filterCategoriesInDeep = (searchText) => {
    const filterCategorySearch = filterData(categoriesTreeFilter, searchText);
    if (filterCategorySearch.length > 0) {
      return checkChildrenAndLoop(filterCategorySearch, searchText);
    } else {
      return filterCategorySearch;
    }
  };

  const filterUnits = (searchText) => {
    const filterUnitSearch = filterData(adminUnits, searchText);
    return filterUnitSearch;
  };

  const checkChildrenAndLoop = (childCategories, searchText) => {
    let newFilteredCategories = [];
    for (const category of childCategories) {
      if (category.children.length > 0) {
        const filterLvl2 = filterData(category.children, searchText);
        const filterLvl2Child = filterLvl2.findIndex(
          (child) => child.children.length > 0
        );
        if (filterLvl2Child !== -1) {
          const childArr = checkChildrenAndLoop(filterLvl2, searchText);
          newFilteredCategories.push({
            ...category,
            children: childArr,
          });
        } else {
          newFilteredCategories.push({ ...category, children: filterLvl2 });
        }
      } else {
        newFilteredCategories.push(category);
      }
    }

    return newFilteredCategories;
  };

  function successCallback() {
    //clear data
    loading(false);
    resetAddCatalogData();
    history.goBack();
    pushMessage('Catalog Product Save Successfully');
  }

  function errorCallback(err) {
    loading(false);
    // const type = 'error';
    pushMessage(err);
  }

  async function handleSubmit() {
    console.log('====unit567', unit);
    if (
      !images?.length ||
      title === '' ||
      !title ||
      !subCategoryId ||
      description === '' ||
      !description ||
      budget <= 0 ||
      !budget ||
      (businessType !== '2' && (moq <= 0 || !moq || !unit))
    ) {
      if (!images?.length) {
        validateInput('photos', []);
        scrollToInput('photos');
        return;
      }
      if (title === '' || !title) {
        validateInput('product_name', '');
        scrollToInput('product_name');
        return;
      }
      if (!subCategoryId) {
        validateInput('product_subCategory', '');
        scrollToInput('product_subCategory');
        return;
      }
      if (description === '' || !description) {
        validateInput('product_description', '');
        scrollToInput('product_description');
        return;
      }
      if (budget <= 0 || !budget) {
        validateInput('product_price', '');
        scrollToInput('product_price');
        return;
      }
      if (businessType !== '2') {
        console.log('===if errors567', errors);
        if (!unit) {
          validateInput('product_unit', null);
          scrollToInput('product_unit');
          setProductUnit('');
          return;
        }
        if (unit) {
          const validUnit = adminUnits.find((u) => u?.value === unit);
          console.log('validUnit', validUnit);

          if (!validUnit) {
            validateInput('product_unit', '');
            scrollToInput('product_unit');
            return;
          }
        }
        if (moq <= 0 || !moq || isNaN(moq)) {
          setProductMOQ(0);
          validateInput('product_orderQuantity', 0);
          scrollToInput('product_orderQuantity');
        }

        return;
      }
      console.log('===errors 456', errors);
    } else {
      if (unit) {
        const validUnit = adminUnits.find((u) => u?.value === unit);
        console.log('validUnit', validUnit);

        if (!validUnit) {
          validateInput('product_unit', '');
          scrollToInput('product_unit');
          pushMessage('Invalid Unit');
          return;
        }
      }
      let moqVal = null;
      let unitVal = null;
      if (moq) {
        moqVal = moq;
      }
      if (unit) {
        unitVal = unit;
      }
      if (!hasAnyErrorPresent(errors)) {
        const customHeader = {
          title,
          registrationid: userData?.registration?.id,
        };
        if (pageActionType !== 'Edit') {
          //Add new product
          loading(true);
          await uploadProductData(
            images,
            Number(businessType) || 1,
            userData?.registration?.id,
            title,
            description,
            budget,
            moqVal,
            subCategoryId,
            unitVal,
            userData?.accessToken,
            successCallback,
            errorCallback,
            customHeader
          );
          loading(false);
        } else {
          //Edit existing product
          loading(true);
          if (deleteProdImgs.length > 0) {
            for (const itm of deleteProdImgs) {
              console.log('ITM of delete Prod Imgs', itm);
              await removeProdImage(
                itm.imgIdx,
                itm.prod,
                itm.img,
                () => {
                  //   removeAddProdImage(index);
                  console.log('Image deleted successfully');
                },
                () => {
                  pushMessage('Please try again, Error during image removing');
                }
              );
            }
          }
          await editProductData(
            singleProduct.sku,
            images,
            Number(businessType) || 1,
            userData?.registration?.id,
            title,
            description,
            budget,
            moqVal,
            subCategoryId,
            unitVal,
            userData?.accessToken,
            successCallback,
            errorCallback,
            customHeader
          );
          loading(false);
        }
      } else {
        handleErrorsAndScroll(errors);
      }
    }
  }

  const handleClick = () => {
    setOpen(!open);
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  // async function getFileFromUrl(url, name, defaultType = 'image/jpeg'){
  //   const response = await fetch(url);
  //   const data = await response.blob();
  //   return new File([data], name, {
  //     type: data.type || defaultType,
  //   });
  // }

  // async function createFileList(images){
  //   var tempImgList = [];
  //   let arrImg = images.split(',');
  //   let newImgList = arrImg.map(async (img, index)=>{
  //     const file = await getFileFromUrl(`${PRODUCT_IMAGE_BASE_URL}${img}`, img);
  //     return file;
  //   });
  //   return newImgList;
  // }

  const handleFileListCreation = useCallback(() => {
    // console.log(
    // 	'==singleProduct?.product_images',
    // 	singleProduct?.product_images
    // );
    if (singleProduct?.product_images?.length > 0) {
      const prodImgs = singleProduct?.product_images?.split(',');
      const newProdImg = prodImgs?.map((img) => {
        return PRODUCT_IMAGE_BASE_URL + img;
      });
      saveProductImage(newProdImg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleProduct]);

  useEffect(()=>{
    GAEvents('Add_Catalog_Load', 'Add_Catalog', 'load', {
      page_title: 'Add Catalog',
      page_location: window.location.href,
      page_path: window.location.pathname,
      userId: userData?.id || sessionStorage.getItem('random_id')
    });
  },[])
  useEffect(() => {
    // Function to handle creating FileList and processing it

    // Call the function when the component mounts
    const handleFile = setTimeout(() => {
      if (pageActionType == 'Edit') {
        handleFileListCreation();
      }
    }, 500);

    return () => clearTimeout(handleFile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageActionType]);

  useEffect(() => {
    // const jsonString = CircularJSON.stringify(b2bprodCategories);
    // console.log("===b2bprodCategories678", jsonString);
    console.log('====singleProduct', singleProduct);
    console.log('====b2bprodCategories', b2bprodCategories);
    if (singleProduct && b2bprodCategories && b2bprodCategories.length > 0) {
      let singleProductCategory;
      //singleProduct.category_id
      if (singleProduct?.category_id && b2bprodCategories.length > 0) {
        singleProductCategory = getTitleById(
          b2bprodCategories,
          singleProduct?.category_id
        );
      }

      setCategorySearch(singleProductCategory);
      if (singleProduct?.category_id !== '') {
        setProductSubCategory(Number(singleProduct?.category_id));
      } else {
        setProductSubCategory(singleProduct?.category_id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleProduct, b2bprodCategories]);

  useEffect(() => {
    if (businessType !== '2') {
      getProductCategories(BUSINESS);
      getMiscellaneousData('Unit');
      console.log('======add catlog store adminUnits', adminUnits);
      if (singleProduct) {
        setProductDesc(singleProduct.description);
        setProductDetail(singleProduct.title);
        setProductMOQ(Number(singleProduct.moq));
        setProductBudget(singleProduct.budget);
        setCategorySearch(singleProduct?.category_id);
        setProductUnit(singleProduct?.unit);
        setBusinessType(singleProduct?.type?.toString() || '1');
      }
    } else {
      getProductCategories(FREELANCER);
      if (singleProduct) {
        setProductDesc(singleProduct.description);
        setProductDetail(singleProduct.title);
        setProductBudget(singleProduct.budget);
        setCategorySearch(singleProduct?.category_id);
        setBusinessType(singleProduct?.type?.toString() || '1');
      }
    }
  }, [singleProduct]);

  useEffect(() => {
    if (businessType !== '2') {
      getProductCategories(BUSINESS);
      console.log('======add catlog store adminUnits', adminUnits);
    } else {
      getProductCategories(FREELANCER);
    }
  }, [businessType]);

  useEffect(() => {
    // console.log('selectedLvl1Categories', selectedLvl1Categories);
    let filteredLvl1 = [];
    selectedLvl1Categories.forEach((category) => {
      let selectedIdx = categoriesTree.findIndex(
        (itm) => itm.id === category.id
      );
      if (selectedIdx !== -1) {
        filteredLvl1.push(categoriesTree[selectedIdx]);
      }
    });
    setCategoriesTreeFilter(filteredLvl1);
  }, [categoriesTree, selectedLvl1Categories]);

  const handleBTypeChange = (event) => {
    setBusinessType(event.target.value);
  };

  useEffect(() => {
    if (b2bprodCategories.length > 0) {
      const categoryTree = list_to_tree(b2bprodCategories);
      // console.log('CATEGORY TREE', categoryTree);
      setCategoriesTree(categoryTree);
    }
  }, [b2bprodCategories]);

  const handleImageClick = () => {
    // 👇️ open file input box on click of another element
    inputRef.current.click();
  };

  const deleteProdImage = ({ index, prod = null, img = '' }) => {
    console.log('===deleteProdImage', index, prod, img, typeof img);
    if (pageActionType === 'Add') {
      removeAddProdImage(index);
    } else {
      if (typeof img !== 'string') {
        removeAddProdImage(index);
      } else {
        let newDelProdImgs = [...deleteProdImgs];
        console.log('===newDelProdImgs', newDelProdImgs);
        const newObj = {
          imgIdx: index,
          prod,
          img,
        };
        newDelProdImgs.push(newObj);
        setDeleteProdImages(newDelProdImgs);
        removeAddProdImage(index);
      }
    }
  };
  const [viewImagedialogOpen, setViewImagedialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const handleImageOpen = () => setOpenImageModal(true);
  const [imgIndex, setImgIndex] = useState();
  const handleViewImageClick = (image) => {
    console.log('===handleViewImageClick', image);
    setSelectedImage(image);
    setViewImagedialogOpen(true);
  };

  const handleViewImageCloseDialog = () => {
    setViewImagedialogOpen(false);
    setSelectedImage('');
  };
  const [openImageModal, setOpenImageModal] = useState(false);
  const handleImageClose = () => setOpenImageModal(false);
  const handleConfirmDelete = () => {
    deleteProdImage(imgIndex);
  };
  return (
    <ThemeProvider theme={theme}>
      <ConfirmationDialog
        open={openImageModal}
        handleClose={handleImageClose}
        title="Elynker"
        content="Are you sure you want to delete a photo?"
        onConfirm={handleConfirmDelete}
        confirmText="Yes"
        cancelText="No"
      />
      <Container component="main" maxWidth="lg">
        {isLoading && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: 'auto' }}
        >
          <Grid item xs={12}>
            <div className="my-3 ms-2 d-flex align-items-center">
              <ArrowBackIosIcon
                onClick={async () => {
                  // clear image array or product data
                  await resetAddCatalogData();
                  history.goBack();
                }}
              />
              <ElynkerLogoComponent>
                <ElynkerLogo className="ms-3" width={'100px'} height={'30px'} />
              </ElynkerLogoComponent>
            </div>
            <Divider
              component="div"
              className="main-divider mb-3"
              role="presentation"
            />
            <Typography
              variant="h4"
              align="left"
              alignItems="center"
              className="fs-22 mt-4 mb-2 fw-bold font-skmodernist"
              gutterBottom
              style={{ whiteSpace: 'pre-line' }}
            >
              {categoryText}
            </Typography>

            <form style={{ marginTop: '30px' }}>
              <FormControl fullWidth>
                <FormLabel
                  id="business-type-radio"
                  className="fs-15 fw-normal font-skmodernist text-black"
                >
                  What is your Catalog Type{' '}
                  <span className="text-mandatory">*</span>
                </FormLabel>
                <Divider
                  component="div"
                  className="divider mt-2 mb-1"
                  role="presentation"
                ></Divider>
                <RadioGroup
                  row
                  aria-labelledby="business-type-radio"
                  name="business-type-radio"
                  // defaultValue={"1"}
                  // defaultValue={singleProduct?.type?.toString() || "1"}
                  value={businessType}
                  onChange={handleBTypeChange}
                >
                  <FormControlLabel
                    // disabled={singleRequirement?.status === 'fulfilled'}
                    value="1"
                    control={<Radio />}
                    label="Product"
                  />
                  <FormControlLabel
                    // disabled={singleRequirement?.status === 'fulfilled'}
                    value="2"
                    control={<Radio />}
                    label="Service"
                  />
                </RadioGroup>
                <Divider
                  component="div"
                  className="divider mb-3 mt-1"
                  role="presentation"
                ></Divider>
              </FormControl>
              {images === undefined ||
              images === null ||
              images?.length === 0 ? (
                <Grid md={6} lg={6} xs={12} className=" mb-3">
                  <InputLabel
                    htmlFor="inputCompanyName"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    Add your {titleName + ' '} Photo (Max 5){' '}
                    <span className="text-mandatory">*</span>
                  </InputLabel>

                  <input
                    style={{ display: 'none' }}
                    id="image"
                    ref={inputRef}
                    multiple
                    type="file"
                    accept=".png,.jpeg,.jpg"
                    onChange={changeHandler}
                  />
                  <Button
                    id="add_photo"
                    type="button"
                    fullWidth
                    variant="outlined"
                    name="add_photo"
                    value="add_photo"
                    className="add-button mt-2 fs-15 font-skmodernist fw-bold"
                    onClick={()=>{
                      GAEvents(ADDCATALOG_TITLE, 'Add Catalog', CLICKED, {
                        title: 'Add Photo',
                        userId: userData?.id || sessionStorage.getItem('random_id')
                      });
                      handleImageClick();
                    }}
                    sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
                  >
                    <img
                      src={AddBtnIcon}
                      alt="add-btn-icon"
                      className="user-logo"
                    />
                  </Button>
                </Grid>
              ) : (
                <div className="mb-3">
                  <div className="d-flex justify-content-between">
                    <InputLabel
                      htmlFor="inputproduct"
                      className="text-start fs-14 text-black font-skmodernist"
                    >
                      Add your {titleName + ' '}
                      Photo (Max 5) <span className="text-mandatory">*</span>
                      <br />
                      <span className="text-muted fs-12">
                        (JPEG or PNG files only)
                      </span>
                    </InputLabel>
                    <Typography
                      variant="body1"
                      align="left"
                      className="fs-14 text-primary fw-normal font-gotham"
                      onClick={()=>{
                        GAEvents(ADDCATALOG_TITLE, 'Add Catalog', CLICKED, {
                          title: 'View All',
                          userId: userData?.id || sessionStorage.getItem('random_id')
                        });
                        toggleDrawer();}}
                      gutterBottom
                    >
                      {`View All (${images.length})`}
                    </Typography>
                  </div>

                  <div className="upload-image-container my-1">
                    {images &&
                      Array.from(images)
                        ?.filter(
                          (img) =>
                            img instanceof File || Object.keys(img).length !== 0
                        )
                        ?.slice(0, 3)
                        ?.map((img, imgIdx) => {
                          return (
                            <div className="image-inner-field" key={imgIdx}>
                              <img
                                onClick={() =>
                                  handleViewImageClick(
                                    typeof img === 'string'
                                      ? img
                                      : URL.createObjectURL(img)
                                  )
                                }
                                src={
                                  typeof img === 'string'
                                    ? img
                                    : URL.createObjectURL(img)
                                }
                                alt="product-image"
                                className="product-image"
                              />
                              <img
                                src={ProductCloseIcon}
                                alt="product-close-icon"
                                className="product-close-icon"
                                onClick={() => {
                                  GAEvents(ADDCATALOG_TITLE, 'Image Remove', CLICKED, {
                                    title: 'Image Remove button',
                                    userId: userData?.id || sessionStorage.getItem('random_id')
                                  });
                                  const id = singleProduct.id;
                                  validateInput('photos', images);
                                  setImgIndex({
                                    index: imgIdx,
                                    prod: id,
                                    img: img,
                                  });
                                  handleImageOpen();
                                }}
                              />
                            </div>
                          );
                        })}
                    <div className="image-inner-field">
                      <input
                        style={{ display: 'none' }}
                        id="image"
                        ref={inputRef}
                        multiple
                        type="file"
                        accept=".png,.jpeg,.jpg"
                        onChange={changeHandler}
                      />
                      <Button
                        type="button"
                        fullWidth
                        variant="outlined"
                        name="add_more_photo"
                        value="add_more_photo"
                        className="add-button image-inner-field fs-15 font-skmodernist fw-bold"
                        sx={{
                          borderRadius: '6px',
                          textTransform: 'capitalize',
                        }}
                        onClick={()=>{
                          GAEvents(ADDCATALOG_TITLE, 'Image Add More', CLICKED, {
                            title: 'Add More',
                            userId: userData?.id || sessionStorage.getItem('random_id')
                          });
                          handleImageClick();
                        }}
                      >
                        <img
                          src={AddBtnIcon}
                          alt="add-btn-icon"
                          className="user-logo"
                        />
                      </Button>
                    </div>
                  </div>
                  <ImageDialog
                    open={viewImagedialogOpen}
                    onClose={handleViewImageCloseDialog}
                    image={selectedImage}
                  />
                  <Drawer
                    xs={12}
                    anchor="bottom"
                    open={isDrawerOpen}
                    onClose={toggleDrawer}
                    sx={{
                      '& .MuiDrawer-paper': {
                        height: '90%',
                        borderTopLeftRadius: '20px',
                        borderTopRightRadius: '20px',
                      },
                    }}
                  >
                    <div className="p-3">
                      <div className="d-flex justify-content-between mb-3">
                        <Typography
                          variant="h5"
                          align="left"
                          alignItems="center"
                          className="fs-18 mb-2 fw-bold font-skmodernist "
                          gutterBottom
                        >
                          {titleName} Photos
                        </Typography>
                        <CloseIcon onClick={()=>{
                            GAEvents(ADDCATALOG_TITLE, 'Popup Close', CLICKED, {
                              title: 'Photos',
                              userId: userData?.id || sessionStorage.getItem('random_id')
                            });
                            toggleDrawer();
                          }} />
                      </div>
                      <div className="product-grid mt-3">
                        {images &&
                          Array.from(images)
                            ?.filter(
                              (img) =>
                                img instanceof File ||
                                Object.keys(img).length !== 0
                            )

                            ?.map((img, imgIdx) => {
                              return (
                                <div className="image-inner-field" key={imgIdx}>
                                  <img
                                    alt="product-image"
                                    onClick={() => {
                                      handleViewImageClick(
                                        typeof img === 'string'
                                          ? img
                                          : URL.createObjectURL(img)
                                      )
                                    }}
                                    className="product-image"
                                    src={
                                      typeof img === 'string'
                                        ? img
                                        : URL.createObjectURL(img)
                                    }
                                  />
                                  <img
                                    src={ProductCloseIcon}
                                    alt="product-close-icon"
                                    className="product-close-icon"
                                    onClick={() => {
                                      GAEvents(ADDCATALOG_TITLE, 'Image Remove', CLICKED, {
                                        title: 'Image Remove button',
                                        userId: userData?.id || sessionStorage.getItem('random_id')
                                      });
                                      const id = singleProduct.id;
                                      validateInput('photos', images);
                                      setImgIndex({
                                        index: imgIdx,
                                        prod: id,
                                        img: img,
                                      });
                                      handleImageOpen();
                                    }}
                                  />
                                </div>
                              );
                            })}
                      </div>
                    </div>
                  </Drawer>
                </div>
              )}
              <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                {errors.photos}
              </FormHelperText>
              <Grid container spacing={2} alignItems="start">
                <Grid item md={6} lg={6} xs={12} className=" mb-3">
                  <InputLabel
                    htmlFor="inputCIN"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    {titleName} Name<span className="text-mandatory">*</span>
                  </InputLabel>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <OutlinedInput
                      id="inputCIN"
                      // label="Mobile Number"
                      type="text"
                      placeholder={`Enter ${titleName} name`}
                      fullWidth
                      margin="none"
                      onChange={onChangeProdDetail}
                      inputProps={{
                        maxLength: 100,
                      }}
                      value={title}
                      autoComplete="off"
                    />
                  </FormControl>
                  <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                    {errors.product_name}
                  </FormHelperText>
                </Grid>

                <Grid item md={6} lg={6} xs={12} className=" mb-3">
                  <InputLabel
                    htmlFor="subCategoryInput"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    Sub Category <span className="text-mandatory">*</span>
                  </InputLabel>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <OutlinedInput
                      id="subCategoryInput"
                      type="text"
                      placeholder="Select Sub-Category"
                      // required
                      // disabled={categoriesTreeFilter.length === 0}
                      fullWidth
                      margin="none"
                      value={categorySearch}
                      onChange={handleSubCategoryChange}
                      onClick={() => {
                        handleClick();
                      }}
                      autoComplete="off"
                      // onFocus={handleClick}
                      endAdornment={
                        <InputAdornment
                          position="end"
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            border: 'black',
                          }}
                        >
                          {categorySearch && (
                            <IconButton
                              aria-label="toggle password visibility"
                              edge="end"
                              onClick={() => {
                                validateInput('product_subCategory', '');
                                console.log('=====on close');
                                setCategorySearch('');
                                const filteredCategories =
                                  filterCategoriesInDeep('');
                                setFilteredCategory(filteredCategories);
                              }}
                            >
                              <CloseIcon
                                style={{
                                  color: '#727272',
                                  width: 16,
                                  height: 16,
                                  cursor: 'pointer',
                                  marginLeft: 4,
                                }}
                              />
                            </IconButton>
                          )}
                          <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                          >
                            <ArrowDropDownIcon className="arrow-icon" />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {open && (
                      <div
                        style={{
                          position: 'absolute',
                          boxShadow: '0px 2px 14px -3px rgba(0,0,0,0.5)',
                          top: '50px',
                          background: 'white',
                          zIndex: 1,
                          borderRadius: 4,
                          width: '100%',
                        }}
                      >
                        <CustomizedTreeView
                          categoriesTree={
                            filteredCategory.length > 0
                              ? filteredCategory
                              : categoriesTreeFilter.length > 0
                                ? categoriesTreeFilter
                                : categorySearch && categorySearch.length !== 0
                                  ? filteredCategory
                                  : categoriesTree
                          }
                          selectedCategory={(value) => {
                            // console.log('selectedCategory', value);
                            GAEvents(ADDCATALOG_TITLE, 'Sub Catagory', CLICKED, {
                              title: value?.title || '',
                              category_id: value?.id || '',
                              userId: userData?.id || sessionStorage.getItem('random_id')
                            });
                            validateInput('product_subCategory', value?.title);
                            setOpen(!open);
                            setCategorySearch(value.title);
                            setProductSubCategory(value.id);
                            setFilteredCategory([]);
                          }}
                        />
                      </div>
                    )}
                  </FormControl>
                  <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                    {errors.product_subCategory}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid md={12} lg={12} xs={12} className=" mb-3">
                <div className="d-flex justify-content-between">
                  <InputLabel
                    htmlFor="inputCompanyName"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    {titleName} Description{' '}
                    <span className="text-mandatory">*</span>
                  </InputLabel>
                  <Typography
                    variant="body1"
                    align="left"
                    className="fs-12 text-lightgrey fw-normal mb-0 font-gotham"
                    gutterBottom
                  >
                    {5000 - count} Characters Left
                  </Typography>
                </div>
                <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                  <TextareaAutosize
                    id="inputAdditionalInfo"
                    placeholder="Enter description"
                    minRows={3} // You can adjust the number of rows as needed
                    maxRows={3} // You can adjust the number of rows as needed
                    onChange={onChangeDescription}
                    inputprops={{
                      maxLength: 5000,
                    }}
                    onPaste={onPasteDescription}
                    value={description}
                    autoComplete="off"
                    // defaultValue={singleProduct?.description || ''}
                  />
                </FormControl>
                <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                  {errors.product_description}
                </FormHelperText>
              </Grid>
              <div>
                <Grid
                  container
                  spacing={2}
                  alignItems="flex-start"
                  sx={{ marginBottom: 15 }}
                >
                  <Grid item md={6} lg={6} xs={12}>
                    <InputLabel
                      htmlFor="inputCIN"
                      className="text-start fs-14 text-black font-skmodernist"
                    >
                      Price (in Rupees){' '}
                      <span className="text-mandatory">*</span>
                    </InputLabel>
                    <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                      <OutlinedInput
                        id="inputCIN"
                        type="number"
                        placeholder="Enter Price"
                        fullWidth
                        margin="none"
                        onWheel={(event) =>
                          event.currentTarget.querySelector('input')?.blur()
                        }
                        onKeyDown={(evt) =>
                          ['e', 'E', '+', '-'].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        onChange={onChangeBudget}
                        value={isNaN(budget) || budget === 0 ? '' : budget}
                        inputMode="decimal"
                        startAdornment={
                          <InputAdornment position="start">₹</InputAdornment>
                        }
                        autoComplete="off"
                      />
                    </FormControl>
                    <FormHelperText
                      sx={{ color: 'red' }}
                      id="email-helper-text"
                    >
                      {errors.product_price}
                    </FormHelperText>
                  </Grid>
                  {businessType !== '2' && (
                    <Grid item md={6} lg={6} xs={12} className=" mb-3">
                      <InputLabel
                        htmlFor="inputCIN"
                        className="text-start fs-14 text-black font-skmodernist"
                      >
                        Unit<span className="text-mandatory">*</span>
                      </InputLabel>
                      <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                        <OutlinedInput
                          id="subCategoryInput"
                          type="text"
                          placeholder="Select Unit"
                          // required
                          // disabled={categoriesTreeFilter.length === 0}
                          fullWidth
                          margin="none"
                          value={unit}
                          onChange={handleUnitChange}
                          onClick={() => {
                            GAEvents(ADDCATALOG_TITLE, 'Unit', CLICKED, {
                              title: unit,
                              userId: userData?.id || sessionStorage.getItem('random_id')
                            });
                            setUnitModelOpen((prev) => !prev);
                          }}
                          autoComplete="off"
                          // onFocus={handleClick}
                          endAdornment={
                            <InputAdornment
                              position="end"
                              style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                border: 'black',
                              }}
                            >
                              {adminUnits && (
                                <IconButton
                                  aria-label="toggle password visibility"
                                  edge="end"
                                  onClick={() => {
                                    validateInput('product_unit', '');
                                    console.log('=====on close');
                                    setProductUnit('');
                                    setFilteredUnits(adminUnits);
                                  }}
                                >
                                  <CloseIcon
                                    style={{
                                      color: '#727272',
                                      width: 16,
                                      height: 16,
                                      cursor: 'pointer',
                                      marginLeft: 4,
                                    }}
                                  />
                                </IconButton>
                              )}
                              <IconButton
                                aria-label="toggle password visibility"
                                edge="end"
                              >
                                <ArrowDropDownIcon className="arrow-icon" />
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        {unitModelopen && (
                          <div
                            style={{
                              position: 'absolute',
                              boxShadow: '0px 2px 14px -3px rgba(0,0,0,0.5)',
                              top: '50px',
                              background: 'white',
                              zIndex: 1,
                              borderRadius: 4,
                              width: '100%',
                            }}
                          >
                            <SimpleDropdown
                              options={
                                filteredUnits.length > 0
                                  ? filteredUnits
                                  : adminUnits
                              }
                              selectedValue={{ value: '' }}
                              onSelect={onChangeUnit}
                            />
                          </div>
                        )}
                      </FormControl>
                      <FormHelperText
                        sx={{ color: 'red' }}
                        id="email-helper-text"
                      >
                        {errors.product_unit}
                      </FormHelperText>
                    </Grid>
                  )}
                  {/* <Grid item md={4} lg={4} xs={6} >
                    <InputLabel
                      htmlFor="inputCIN"
                      className="text-start fs-14 text-black font-skmodernist"
                    >
                      Unit
                    </InputLabel>
                    <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                      <OutlinedInput
                        id="inputCIN"
                        // label="Mobile Number"
                        type="text"
                        placeholder="Enter Unit"
                        fullWidth
                        margin="none"
                        onChange={onChangeUnit}
                        // defaultValue={singleProduct?.unit}
                        value={unit}
                        inputProps={{
                          maxLength: 8,
                        }}
                      />
                    </FormControl>
                    <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                      {errors.product_unit}
                    </FormHelperText>
                  </Grid> */}
                  {businessType !== '2' && (
                    <Grid item md={4} lg={4} xs={12}>
                      <InputLabel
                        htmlFor="inputOrderQuantity"
                        className="text-start fs-14 text-black font-skmodernist"
                      >
                        Min. Order Quantity{' '}
                        <span className="text-mandatory">*</span>
                      </InputLabel>
                      <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                        <OutlinedInput
                          id="inputOrderQuantity"
                          type="text" // Change type to "text"
                          placeholder="Enter order quantity"
                          fullWidth
                          margin="none"
                          onKeyDown={(evt) =>
                            ['e', 'E', '+', '-'].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          inputProps={{
                            maxLength: 10,
                          }}
                          onChange={onChangeProdMoq}
                          value={!moq || moq === 0 ? '' : moq}
                          autoComplete="off"
                        />
                      </FormControl>
                      <FormHelperText
                        sx={{ color: 'red' }}
                        id="email-helper-text"
                      >
                        {errors.product_orderQuantity}
                      </FormHelperText>
                    </Grid>
                  )}
                </Grid>
              </div>

              <Box sx={{ width: '100%' }}>
                <Box sx={[stickyButtonStyle]}>
                  <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    name="add_product"
                    value="add_product"
                    className="py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
                    onClick={()=>{
                      const btnText = `Save ${businessType !== "2"
                      ? "Catalog Product"
                      : "Service Catalog"}`;
                      GAEvents(ADDCATALOG_TITLE, 'Save', CLICKED, {
                        title: btnText,
                        userId: userData?.id || sessionStorage.getItem('random_id')
                      });
                      handleSubmit();
                    }}
                    sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
                  >
                    Save{' '}
                    {businessType !== '2'
                      ? 'Catalog Product'
                      : 'Service Catalog'}
                  </Button>
                </Box>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

AddCatalogForm.propTypes = {
  images: oneOfType([object, array]),
  products: arrayOf(
    shape({
      images: array,
      type: string,
      registrationId: number,
      title: '',
      description: '',
      product_category: arrayOf(
        shape({
          id: number,
          name: string,
        })
      ),
      product_description: string,
      budget: string,
      min_order_qty: string,
    })
  ),
  prod_image: array,
  getProductsList: func,
  access_token: string,
  saveProductImage: func,
  getProductCategories: func,
  userData: oneOfType([object, number]),
  b2bprodCategories: array,
  b2bLvl2CategoriesTree: array,
  b2bLvl1Categories: array,
  selectedLvl1Categories: array,
  setProductDesc: func,
  setProductDetail: func,
  setProductBudget: func,
  setProductMOQ: func,
  setProductSubCategory: func,
  uploadProductData: func,
  type: number,
  title: string,
  description: string,
  budget: number,
  moq: number,
  subCategoryId: number,
  businessType: string,
  singleProduct: object,
  pageActionType: string,
  editProductData: func,
  removeProdImage: func,
  resetAddCatalogData: func,
  removeAddProdImage: func,
  setProductUnit: func,
  unit: string,
  isLoading: bool,
  loading: func,
  deleteProdImgs: array,
  setDeleteProdImages: func,
  pushMessage: func,
  getMiscellaneousData: func,
  adminUnits: array,
  setBusinessType: func,
};

const mapStateToProps = (state) => {
  const { userData } = state.signin;

  const {
    images,
    title,
    description,
    budget,
    adminUnits,
    moq,
    category_id,
    subCategoryId,
    singleProduct,
    pageActionType,
    unit,
    deleteProdImgs,
  } = state.product;
  const {
    b2bprodCategories,
    b2bLvl2CategoriesTree,
    b2bLvl1Categories,
    selectedLvl1Categories,
    businessType,
  } = state.offeredServices;
  const { isLoading } = state.root;
  return {
    userData,
    images,
    b2bprodCategories,
    b2bLvl2CategoriesTree,
    b2bLvl1Categories,
    selectedLvl1Categories,
    businessType,
    title,
    description,
    budget,
    moq,
    category_id,
    subCategoryId,
    singleProduct,
    pageActionType,
    unit,
    isLoading,
    deleteProdImgs,
    adminUnits,
  };
};

export default connect(mapStateToProps, {
  getProductsList,
  saveProductImage,
  getProductCategories,
  setProductDesc,
  setProductDetail,
  setProductBudget,
  setProductMOQ,
  setProductSubCategory,
  uploadProductData,
  editProductData,
  removeProdImage,
  resetAddCatalogData,
  removeAddProdImage,
  setProductUnit,
  setDeleteProdImages,
  loading,
  pushMessage,
  getMiscellaneousData,
  setBusinessType,
})(AddCatalogForm);
