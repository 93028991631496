/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import '../../common/fontsize.css';
// import { ReactComponent as CheckIcon } from '../../assets/check.svg';
// import { ReactComponent as UncheckIcon } from '../../assets/uncheck.svg';
// import { ReactComponent as HalfCheckIcon } from '../../assets/halfCheck.svg';
// import { ReactComponent as ExpandCloseIcon } from '../../assets/expandClose.svg';
// import { ReactComponent as ExpandOpenIcon } from '../../assets/expandOpen.svg';
// import { ReactComponent as ExpandAllIcon } from '../../assets/expandAll.svg';
// import { ReactComponent as CollapseAllIcon } from '../../assets/collapseAll.svg';
// import { ReactComponent as ParentCloseIcon } from '../../assets/parentClose.svg';
// import { ReactComponent as ParentOpenIcon } from '../../assets/parentOpen.svg';
// import { ReactComponent as LeafIcon } from '../../assets/leaf.svg';
import defaultProfileImage from '../../assets/defaultProfileImage.png';

import '../../index.css';
import {
  Container,
  Grid,
  Typography,
  Divider,
  Button,
  Autocomplete,
  TextField,
  ButtonGroup,
  Radio,
  Backdrop,
  CircularProgress,
  Modal,
  FormControl,
  OutlinedInput,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  SvgIcon,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
import { ReactComponent as SearchIcon } from '../../assets/search-purple-icon.svg';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as MenuIcon } from '../../assets/menu-black.svg';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
import Responsive from '../../utils/responsive';
import WebB2BCategoryForm from './Web-B2B-Category';
import CircleIcon from '@mui/icons-material/Circle';
import { ReactComponent as FiltersIcon } from '../../assets/filters-icon.svg';
import { ReactComponent as NearMeWhiteIcon } from '../../assets/NearMeWhiteIcon.svg';
import { ReactComponent as NearMeIcon } from '../../assets/nearme-icon.svg';
import { ReactComponent as CallNowIcon } from '../../assets/call-now-icon.svg';
import { ReactComponent as WhatsappIcon } from '../../assets/whatsapp-icon.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/right-arrow-icon.svg';
import DropdownIcon from '@mui/icons-material/KeyboardArrowDown';
import Drawer from '@mui/material/Drawer';

import { ReactComponent as CloseIcon } from '../../assets/close-icon.svg';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { connect, useDispatch } from 'react-redux';
import {
  getVendorList,
  postEnquiry,
  getEnquiryById,
  setFilterVCity,
  postMemberContacted,
  setFilterRating,
  setFilterCategory,
  setFilterSort,
  setVendorFilterCity,
  setFilterNearMe,
  setNearByClickState,
  getSubCategoryVendorList,
  setSubCategoryTreeAtrributes,
  setEnquiryData,
} from './redux/actions/b2b-category-action';
import { loading } from '../../store/actions/rootActions';
import { COMPANY_LOGO_BASE_URL, WHATSAPP_URL } from '../../utils/APIs/api';
import {
  getCityMaster,
  setFilterCity,
  setLocationCoords,
} from '../ProfileDetails/redux/actions/profile-details-form-action';
import DrawerForm from '../../components/DrawerForm';
import useValidation from '../../hooks/useValidation';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { SET_ENQUIRY } from './redux/types';
import moment from 'moment';
import { pushMessage } from '../../components/MessageBox/redux/actions/message-box-action';
import { getVendorDetails } from './redux/actions/b2b-category-action';
import {
  BUSINESS,
  BUSINESS_ID,
  BUSINESSCATEGORY_TITLE,
  CALLBACK_TYPE_GENERIC,
  CAN_REF_SLUG,
  CANCELLATION_REFUND,
  CLICKED,
  CON_US_SLUG,
  CONTACT_US,
  DIALOG_NOT_LOGIN_MSG,
  DIALOG_REQEST_SUCC_MSG,
  DIALOG_TITLE,
  DIALOG_VENDOR_DETAIL_SCRN__MSG,
  FREELANCER,
  LANG,
  MAPBOX_ACC,
  ModalStyle,
  PRIV_POL_SLUG,
  PRIVACY_POLICY,
  SHIP_DEL_SLUG,
  SHIPPING_DELIVERY,
  T_AND_C_SLUG,
  TERMS,
} from '../../utils/constants';
import axios from 'axios';
import {
  GAEvents,
  GAPageView,
  buildTree,
  data,
  findDescendants,
  handleLocationPermission,
  list_to_tree,
  sendDefaultPageEvent,
} from '../../utils/helper-funtions';
import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
// import useGeoLocation from '../../hooks/useGeolocation';
import {
  saveSearchText,
  searchVendors,
} from '../Search/redux/actions/search-action';
import Paper from '@mui/material/Paper';
import {
  resetTemplateData,
  setTemplateRender,
} from '../Templates/redux/action.js/template-action';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CustomizedTreeView from '../../components/TreeDropDown/TreeDropDown';
import { getProductCategories } from '../OfferedServices/redux/actions/offered-services-form-action';
import CheckboxTree from 'react-checkbox-tree';
import TreeView from '../../components/TreeView/treeView';
import { setSelectedVCategoryUUID } from '../B2BBusiness/redux/actions/b2b-business-action';

const theme = createTheme();
export function isMoreThan24HoursAgo(createdAt) {
  if (!createdAt) {
    return false; // or handle this case as per your requirement
  }

  const createdDate = moment(createdAt);
  if (!createdDate.isValid()) {
    return false; // or handle this case as per your requirement
  }

  const differenceInHours = moment().diff(createdDate, 'hours');
  return differenceInHours > 24;
}
const B2BCategoryForm = ({
  getVendorList,
  vendors,
  loading,
  userData,
  postEnquiry,
  isLoading,
  getCityMaster,
  allCity,
  b2bprodCategories,
  filterCity,
  setFilterCity,
  selectedVCategory,
  enquiry,
  pushMessage,
  getEnquiryById,
  getVendorDetails,
  allVCity,
  setFilterVCity,
  filterVCity,
  postMemberContacted,
  setFilterCategory,
  setFilterSort,
  setVendorFilterCity,
  setFilterRating,
  setFilterNearMe,
  saveFilterRating,
  saveFilterCategory,
  saveFilterCity,
  saveFilterNearMe,
  saveFilterSort,
  setNearByClickState,
  markNearByClick,
  searchVendor,
  saveSearchText,
  searchVendors,
  setTemplateRender,
  resetTemplateData,
  getProductCategories,
  selectedLvl1Categories,
  getSubCategoryVendorList,
  setSubCategoryTreeAtrributes,
  subCategoryAttributes,
  setSelectedVCategoryUUID,
  selectedVcategoryUUID,
  setEnquiryData,
}) => {
  console.log('enquiry in b2b', enquiry);

  const ratingArr = [
    { label: '1.0 and above', value: 1 },
    { label: '2.0 and above', value: 2 },
    { label: '3.0 and above', value: 3 },
    { label: '4.0 and above', value: 4 },
  ];
  const sortArr = [
    { label: 'Price - Low to High', value: 'price-low-to-high' },
    { label: 'Price - High to Low', value: 'price-high-to-low' },
    { label: 'Rating - High to Low', value: 'rating-High-to-low' },
  ];
  const [categoriesTree, setCategoriesTree] = useState([]);
  const { user } = userData || {};
  const { mobile_number = '', name = '', email = '' } = user || {};

  const [nearByCity, setNearByCity] = useState('');
  const [searchOptions, setSearchOptions] = useState([]);
  React.useEffect(() => {
    console.log('categoriesTree', categoriesTree);
  }, [categoriesTree]);
  // Vendor SearchBox States
  const [vendorSearchVal, setVendorSearchVal] = useState('');
  const [open, setOpen] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

  const [openCallModal, setOpenCallModal] = useState(false);
  const handleOpenCallModal = () => setOpenCallModal(true);
  const handleCloseCallModal = () => setOpenCallModal(false);

  const [selectedNearBy, setSelectedNearBy] = useState(false);
  const nearRef = useRef(null);
  const nearMeRef = useRef(true);

  const [openSuccCallModal, setOpenSuccCallModal] = useState(false);
  const handleOpenSuccCallModal = () => setOpenSuccCallModal(true);
  const handleCloseSuccCallModal = () => setOpenSuccCallModal(false);

  const [openErrCallModal, setOpenErrCallModal] = useState(false);
  const handleOpenErrCallModal = () => setOpenErrCallModal(true);
  const handleCloseErrCallModal = () => setOpenErrCallModal(false);
  const [categoriesTreeFilter, setCategoriesTreeFilter] = useState([]);

  const [checked, setChecked] = useState([]);

  function handleClickHome(event) {
    event.preventDefault();
    setSelectedVCategoryUUID(null);
    history.replace('/homepage');
    console.info('You clicked a breadcrumb.');
  }
  function handleClickCategory(e) {
    e.preventDefault();
    setSelectedVCategoryUUID(null);
    history.replace('/b2b-business');
  }
  function filterAllCategory(list) {
    let roots = list?.filter((item) => {
      return item.parent_id == selectedVCategory?.id;
    });
    return roots;
  }

  const history = useHistory();
  const [isCityDrawerOpen, setIsCityDrawerOpen] = useState(false);
  const { validateInput, errors } = useValidation();
  const toggleCityDrawer = () => {
    setIsCityDrawerOpen(!isCityDrawerOpen);
  };
  const dispatch = useDispatch();

  const [selRating, setSelRating] = useState([]);
  const [selCategory, setSelCategory] = useState([]);
  const [selCity, setSelCity] = useState([]);
  const [selSort, setSelectedSort] = useState();
  const [searchText, setSearchText] = useState('');
  const clearFilter = () => {
    setSelCategory([]);
    setSelRating([]);
    setSubCategoryTreeAtrributes({ checked: [], expanded: [] });
  };

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };

  const handleShippingDelivery = () => {
    setTemplateRender(SHIPPING_DELIVERY);
    history.push(`/${SHIP_DEL_SLUG}`);
    resetTemplateData();
  };

  const handleTerms = () => {
    setTemplateRender(TERMS);
    history.push(`/${T_AND_C_SLUG}`);
    resetTemplateData();
  };

  const handlePrivacy = () => {
    setTemplateRender(PRIVACY_POLICY);
    history.push(`/${PRIV_POL_SLUG}`);
    resetTemplateData();
  };

  const handleCancellationRefund = () => {
    setTemplateRender(CANCELLATION_REFUND);
    history.push(`/${CAN_REF_SLUG}`);
    resetTemplateData();
  };

  const handleContactUs = () => {
    setTemplateRender(CONTACT_US);
    history.push(`/${CON_US_SLUG}`);
    resetTemplateData();
  };

  const successCallback = () => {
    loading(false);
  };
  const errorCallback = () => {
    loading(false);
  };
  useEffect(() => {
    getProductCategories(BUSINESS);
    //Add logic for freelancer category
    // getProductCategories(FREELANCER);
    // GAPageView("/b2b-category", "page_vendor_listing", "Business Vendor listing");
    const cateName = selectedVCategory?.title;
    const pageTitle = 'Elynker - Business - ' + selectedVCategory?.title;
    // sendDefaultPageEvent(
    //   userData,
    //   'Business category Page',
    //   window.location.href,
    //   window.location.pathname
    // );
    GAEvents(BUSINESSCATEGORY_TITLE, 'Business_Category_Load', CLICKED, {
      page_title: pageTitle,
      page_location: window.location.href,
      page_path: window.location.pathname,
      category_name: cateName,
      userId: userData?.id || sessionStorage.getItem('random_id'),
      reg_uuid:
        userData?.registration?.uuid || sessionStorage.getItem('random_id'),
      vendor_uuid: selectedVCategory?.uuid || '',
    });
  }, []);

  useEffect(() => {
    let filteredLvl1 = [];
    selectedLvl1Categories.forEach((category) => {
      let selectedIdx = categoriesTree.findIndex(
        (itm) => itm.id === category.id
      );
      if (selectedIdx !== -1) {
        filteredLvl1.push(categoriesTree[selectedIdx]);
      }
    });
    setCategoriesTreeFilter(filteredLvl1);
  }, [categoriesTree, selectedLvl1Categories]);

  useEffect(() => {
    if (b2bprodCategories.length > 0) {
      const categoryTree = list_to_tree(b2bprodCategories);
      setCategoriesTree(categoryTree);
    }
  }, [b2bprodCategories]);

  useEffect(() => {
    const regId = userData?.registration.id;

    const fetchData = async () => {
      await getEnquiryById(regId, CALLBACK_TYPE_GENERIC, (enq, r) => {
        console.log('useEffect userData,enquiry', userData, enquiry);

        if (r == true) {
          //got obj from server
          if (enquiry?.isTrue) {
            dispatch({
              type: SET_ENQUIRY,
              payload: { ...enquiry[0], isTrue: true },
            });
            toggleCallBackDrawer();
          }
        } else {
          //no enq data found on server
          if (enquiry?.isTrue) {
            setIsCallBackDrawerOpen(true);
          } else {
            setIsCallBackDrawerOpen(false);
          }
        }
      });
    };
    fetchData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleRatingToggle = (value) => () => {
    const currentIndex = selRating.map((x) => x.label).indexOf(value.label);
    const newChecked = [...selRating];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelRating(newChecked);
    setFilterRating(newChecked);
  };

  const handleCategoryToggle = (value) => () => {
    console.log('handleCategoryToggle', value);

    const currentIndex = selCategory.indexOf(value);
    const newChecked = [...selCategory];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelCategory(newChecked);
    setFilterCategory(newChecked);
  };

  const handleCityToggle = (value) => () => {
    const currentIndex = selCity.indexOf(value);
    const newChecked = [...selCity];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelCity(newChecked);
  };

  const filterListData = (e) => {
    const searchTerm = e.replace(/\s\s+/g, ' ');
    setSearchText(searchTerm);
  };

  const findNearestLocation = () => {
    if (!markNearByClick) {
      setNearByCity(userData?.registration?.city || userData?.user?.city || '');
      setFilterNearMe(
        userData?.registration?.city || userData?.user?.city || ''
      );
    } else {
      setNearByCity('');
      setFilterNearMe('');
    }
    nearMeRef.current = !nearMeRef.current;
    setNearByClickState(!markNearByClick);
    setSelectedNearBy(!selectedNearBy);
    let rParams = setFilterParam();
    const fetchData = async () => {
      await getVendorList(
        BUSINESS_ID,
        selectedVCategory?.uuid,
        rParams,
        successCallback,
        errorCallback
      );
    };

    loading(true);
    fetchData();
    // loading(false);
  };

  const SearchComponent = React.useCallback(() => {
    return (
      <>
        {showSearch ? (
          <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
            <OutlinedInput
              id="subCategoryInput"
              type="text"
              placeholder="Search here..."
              fullWidth
              margin="none"
              value={vendorSearchVal}
              onChange={handleVendorSearchInput}
              onClick={() => {
                handleClick();
              }}
              startAdornment={
                <SearchIcon style={{ margin: '8px', width: '30px' }} />
              }
            />
            {open && (
              <div
                style={{
                  position: 'absolute',
                  boxShadow: '0px 2px 14px -3px rgba(0,0,0,0.5)',
                  top: '50px',
                  background: 'white',
                  zIndex: 1,
                  borderRadius: 4,
                  width: '100%',
                }}
              >
                {searchOptions.length > 0 &&
                  searchOptions?.map((option, vnIdx) => {
                    return (
                      <Box
                        style={{
                          marginRight: '10px',
                          marginLeft: '10px',
                        }}
                        key={vnIdx}
                        onClick={() => {
                          if (option?.registration_type[0] == BUSINESS_ID) {
                            if (option?.uuid) {
                              history.push('/b2b-detail' + `/${option.uuid}`);
                            } else {
                              pushMessage(DIALOG_VENDOR_DETAIL_SCRN__MSG);
                            }
                          } else {
                            if (option?.uuid) {
                              history.push(`/freelancer-detail/${option.uuid}`);
                            } else {
                              pushMessage(DIALOG_VENDOR_DETAIL_SCRN__MSG);
                            }
                          }
                        }}
                      >
                        <div>
                          {option?.registration_type[0] == BUSINESS_ID
                            ? option?.company_name
                            : option?.name}
                        </div>
                        <div>{option?.city ? option.city : ''}</div>
                        <br />
                      </Box>
                    );
                  })}
              </div>
            )}
          </FormControl>
        ) : null}
      </>
    );
  }, [
    showSearch,
    vendorSearchVal,
    open,
    searchOptions,
    getVendorDetails,
    history,
  ]);

  useEffect(() => {
    const vCardList = searchVendor?.map((item) => {
      item['card_type'] = 'VendorCard';
      return item;
    });
    if (vCardList !== undefined && vCardList?.length > 0) {
      setSearchOptions(vCardList);
    } else {
      setSearchOptions([]);
    }

    return () => {};
  }, [searchVendor]);

  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const toggleFilterDrawer = () => {
    setIsFilterDrawerOpen(!isFilterDrawerOpen);
  };
  const handleVendorSearchInput = (e) => {
    if (!open) {
      setOpen(true);
    }
    setVendorSearchVal(e.target.value);
    searchVendors(e.target.value);
  };
  const handleClick = () => {
    setOpen(!open);
  };
  const [isCallBackDrawerOpen, setIsCallBackDrawerOpen] = useState(false);
  const toggleCallBackDrawer = () => {
    console.log(
      'toggle callback',
      enquiry && Object.keys(enquiry)?.length == 0,
      userData
    );

    if (isMoreThan24HoursAgo(enquiry?.createdAt)) {
      if (!userData) {
        dispatch({
          type: SET_ENQUIRY,
          payload: {
            isTrue: true,
            redirect_to: '/b2b-category/' + selectedVCategory?.uuid,
          },
        });
        setSelectedVCategoryUUID(selectedVcategoryUUID);
        history.push('/login');
      } else {
        setIsCallBackDrawerOpen(!isCallBackDrawerOpen);
      }
    } else if (enquiry && Object.keys(enquiry)?.length == 0) {
      if (!userData) {
        dispatch({
          type: SET_ENQUIRY,
          payload: {
            isTrue: true,
            redirect_to: '/b2b-category/' + selectedVCategory?.uuid,
          },
        });
        handleOpenCallModal();
      } else if (enquiry && userData) {
        setIsCallBackDrawerOpen(!isCallBackDrawerOpen);
      } else {
        handleOpenCallModal();
      }
    } else if (enquiry && typeof enquiry?.createdAt !== 'undefined') {
      pushMessage(
        'We already received your enquiry. Admin will get back to you soon'
      );
    } else if (enquiry && Object.keys(enquiry)?.length == 2) {
      setIsCallBackDrawerOpen(!isCallBackDrawerOpen);
    } else {
      handleOpenCallModal();
      // pushMessage(
      //   'We already received your enquiry. Admin will get back to you soon'
      // );
    }
  };

  const [isSortByDrawerOpen, setIsSortByDrawerOpen] = useState(false);
  const toggleSortByDrawer = () => {
    setIsSortByDrawerOpen(!isSortByDrawerOpen);
  };

  const handleSortChange = (value) => {
    setSelectedSort(value);
    setFilterSort(value);
  };

  const requestCallBackSubmit = async (formData) => {
    const registrationId = userData?.registration?.id;
    if (registrationId) {
      const { mobileNumber, comment, ...rest } = formData;
      const details = {
        ...rest,
        comments: comment,
        phone_number: mobileNumber,
        registrationId,
      };
      GAEvents('Request_call_back', 'Submit button', 'clicked', details);
      const postResponse = await postEnquiry(registrationId, details);
      loading(false);
      if (postResponse?.message === 'Record Created') {
        dispatch({
          type: SET_ENQUIRY,
          payload: { ...postResponse?.data, isTrue: false, redirect_to: '/' },
        });
        handleOpenSuccCallModal();
      } else {
        dispatch({
          type: SET_ENQUIRY,
          payload: { isTrue: false, redirect_to: '/' },
        });
      }
    }
    setIsCallBackDrawerOpen(false);
  };

  // Filters Tabs
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      className="fs-14 text-gray font-gotham"
      href="/"
      onClick={handleClickHome}
    >
      Elynker
    </Link>,
    <Link
      underline="hover"
      key="2"
      className="fs-14 text-gray font-gotham"
      href="/"
      onClick={handleClickCategory}
    >
      Business
    </Link>,
    <Link
      underline="none"
      key="3"
      className="fs-14 text-breadcrumbs-active font-gotham"
    >
      {selectedVCategory?.title || ''}
    </Link>,
  ];

  function setFilterParam() {
    var params = '';
    if (selRating?.length > 0) {
      for (let i = 0; i < selRating.length; i++) {
        params += 'rating=' + selRating[i].value;
        if (i !== selRating.length - 1) {
          params += '&';
        }
      }
    }
    if (
      subCategoryAttributes?.checked &&
      subCategoryAttributes?.checked.length !== 0
    ) {
      if (subCategoryAttributes?.checked?.length > 0) {
        if (selRating?.length > 0) {
          params += '&';
        }
        for (let i = 0; i < subCategoryAttributes?.checked.length; i++) {
          params += 'categoryId=' + subCategoryAttributes?.checked[i];
          if (i !== subCategoryAttributes?.checked.length - 1) {
            params += '&';
          }
        }
      }
    } else {
      if (selCategory?.length > 0) {
        if (selRating?.length > 0) {
          params += '&';
        }
        for (let i = 0; i < selCategory.length; i++) {
          params += 'categoryId=' + selCategory[i].id;
          if (i !== selCategory.length - 1) {
            params += '&';
          }
        }
      }
    }

    if (selCity?.length > 0) {
      if (params !== '') {
        params += '&';
      }
      for (let i = 0; i < selCity.length; i++) {
        params += 'location=' + selCity[i];
        if (i !== selCity.length - 1) {
          params += '&';
        }
      }
    }

    if (selSort !== undefined && selSort !== null) {
      if (params !== '') {
        params += '&';
      }

      params += 'sortBy=' + selSort?.value;
    }

    // if (markNearByClick) {
    //   if (nearByCity && nearByCity !== '') {
    //     if (params !== '') {
    //       params += '&';
    //     }
    //     params += 'location=' + nearByCity;
    //     // if (nearRef.current) nearRef.current.style.color = '#ff0000';
    //   }
    // } else {
    //   // if (nearRef.current) nearRef.current.style.color = '#1843a0';
    // }
    console.log('returned params', params);

    return params;
  }

  const handleRatingAndCategoryFilter = () => {
    let rParams = setFilterParam();
    const fetchData = async () => {
      await getVendorList(
        BUSINESS_ID,
        selectedVCategory?.uuid,
        rParams,
        successCallback,
        errorCallback
      );
    };
    const fetchSubCategoryData = async () => {
      await getSubCategoryVendorList(
        BUSINESS_ID,
        rParams,
        successCallback,
        errorCallback
      );
    };
    //getSubCategoryVendorList

    if (isFilterDrawerOpen) toggleFilterDrawer();
    loading(true);

    subCategoryAttributes?.checked &&
    subCategoryAttributes?.checked.length !== 0
      ? fetchSubCategoryData()
      : fetchData();
    // loading(false);
    GAEvents('category_filter_data', 'category_filter', CLICKED, {
      rParams: rParams,
      ParentCategory: selectedVCategory?.id,
    });
  };

  const clearRatingData = () => {
    if (value == 0) {
      setSelRating([]);
      setFilterRating([]);
    }
    if (value == 1) {
      setSelCategory([]);
      setFilterCategory([]);
    }
  };

  const clearAllCity = () => {
    setSelCity([]);
    setFilterCity([]);
  };

  const handleSelectedCity = () => {
    let rParams = setFilterParam();
    const fetchData = async () => {
      await getVendorList(
        BUSINESS_ID,
        selectedVCategory?.uuid,
        rParams,
        successCallback,
        errorCallback
      );
    };
    loading(true);
    setVendorFilterCity(selCity);
    fetchData();
    // loading(false);
    toggleCityDrawer();
    GAEvents('category_filter_data', 'category_filter', CLICKED, {
      rParams: rParams,
      ParentCategory: selectedVCategory?.id,
    });
  };

  const clearSortData = () => {
    setSelectedSort(null);
    setFilterSort(null);
  };

  const handleSelectedSort = () => {
    let rParams = setFilterParam();
    const fetchData = async () => {
      await getVendorList(
        BUSINESS_ID,
        selectedVCategory?.uuid,
        rParams,
        successCallback,
        errorCallback
      );
    };
    loading(true);
    fetchData();
    // loading(false);
    toggleSortByDrawer();
    GAEvents('category_filter_data', 'category_filter', CLICKED, {
      rParams: rParams,
      ParentCategory: selectedVCategory?.id,
    });
  };

  useEffect(() => {
    if (saveFilterCategory?.length > 0) {
      setSelCategory(saveFilterCategory);
    }
    if (saveFilterRating?.length > 0) {
      setSelRating(saveFilterRating);
    }
    if (saveFilterCity?.length > 0) {
      setSelCity(saveFilterCity);
    }
    if (saveFilterSort && 'value' in saveFilterSort) {
      setSelectedSort(saveFilterSort);
    }

    if (saveFilterNearMe !== null && saveFilterNearMe !== '') {
      setNearByCity(saveFilterNearMe);
      setSelectedNearBy(markNearByClick);
      nearMeRef.current = markNearByClick;
    }
    var params = '';
    if (saveFilterRating?.length > 0) {
      for (let i = 0; i < saveFilterRating?.length; i++) {
        params += 'rating=' + saveFilterRating[i].value;
        if (i !== saveFilterRating?.length - 1) {
          params += '&';
        }
      }
    }

    if (saveFilterCategory?.length > 0) {
      if (saveFilterRating?.length > 0) {
        params += '&';
      }
      for (let i = 0; i < saveFilterCategory?.length; i++) {
        params += 'categoryId=' + saveFilterCategory[i]?.id;
        if (i !== saveFilterCategory?.length - 1) {
          params += '&';
        }
      }
    }

    // call API
    if (saveFilterCity?.length > 0) {
      if (params !== '') {
        params += '&';
      }
      for (let i = 0; i < saveFilterCity?.length; i++) {
        params += 'location=' + saveFilterCity[i];
        if (i !== saveFilterCity?.length - 1) {
          params += '&';
        }
      }
    }

    if (saveFilterSort && 'value' in saveFilterSort) {
      if (params !== '') {
        params += '&';
      }

      params += 'sortBy=' + saveFilterSort?.value;
    }

    // if (markNearByClick) {
    //   nearRef.current.style.color = '#1843a0';
    //   if (saveFilterNearMe && saveFilterNearMe !== '') {
    //     if (params !== '') {
    //       params += '&';
    //     }
    //     params += 'location=' + saveFilterNearMe;
    //   }
    // } else {
    //   nearRef.current.style.color = '#1843a0';
    // }

    const fetchData = async () => {
      await getVendorList(
        BUSINESS_ID,
        selectedVCategory?.uuid,
        params,
        successCallback,
        errorCallback
      );
    };
    loading(true);
    fetchData();
    // loading(false);
  }, []);
  const handleCallNow = (event, phoneNumber, countryCode, id) => {
    event.stopPropagation(); // Prevents the click of card
    if (userData?.registration?.id !== undefined) {
      if (
        phoneNumber !== undefined &&
        phoneNumber !== null &&
        phoneNumber !== ''
      ) {
        postMemberContacted(id, phoneNumber);
        window.location.href = `tel:${countryCode}${phoneNumber}`;
      } else {
        pushMessage('Phone number not found.');
      }
    } else {
      // show dialog msg and navigate to login screen
      handleOpenCallModal();
    }
  };

  const handleWhatsapp = (event, phoneNumber, countryCode, id) => {
    event.stopPropagation();
    if (userData?.registration?.id !== undefined) {
      if (
        phoneNumber !== undefined &&
        phoneNumber !== null &&
        phoneNumber !== ''
      ) {
        postMemberContacted(id, phoneNumber);
        window.open(`${WHATSAPP_URL}${countryCode}${phoneNumber}`, '_blank');
      } else {
        pushMessage('WhatsApp number not found.');
      }
    } else {
      // show dialog msg and navigate to login screen
      handleOpenCallModal();
    }
  };
  useEffect(() => {
    if (b2bprodCategories?.length > 0) {
      const categoryList = filterAllCategory(b2bprodCategories);
      setCategoriesTree(categoryList);
    }
  }, [b2bprodCategories]);

  useEffect(() => {
    if (searchText === '') {
      setFilterVCity(allVCity);
    } else {
      const filteredItems = allVCity?.filter((item) =>
        item?.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilterVCity(filteredItems);
    }
  }, [allVCity, searchText]);
  const handleSelectCategory = (node) => {
    console.log('Selected node:', node);
  };
  return (
    <>
      <DrawerForm
        title="Request a Call Back"
        isOpen={isCallBackDrawerOpen}
        onClose={() => {
          dispatch({
            type: SET_ENQUIRY,
            payload: {},
          });
          setIsCallBackDrawerOpen(false);
        }}
        BuisnessType={true}
        mobile_number={mobile_number}
        name={name}
        email={email}
        validateInput={validateInput}
        onSubmit={requestCallBackSubmit}
        errors={errors}
      />
      <Dialog
        open={openCallModal}
        onClose={handleCloseCallModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle className="custom-border-header pb-0">
          <Box className="position-relative d-flex justify-content-between">
            <Typography
              className="fs-22 fw-bold font-skmodernist"
              id="alert-dialog-title"
            >
              {DIALOG_TITLE}
            </Typography>
            <Box>
              <CloseIcon
                onClick={() => {
                  dispatch({
                    type: SET_ENQUIRY,
                    payload: {},
                  });
                  handleCloseCallModal();
                }}
                style={{ cursor: 'pointer' }}
              />
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {DIALOG_NOT_LOGIN_MSG}
          </DialogContentText>
        </DialogContent>

        <DialogActions className="modal-footer">
          <Button
            className="btn-cancel me-2"
            onClick={() => {
              dispatch({
                type: SET_ENQUIRY,
                payload: {},
              });
              handleCloseCallModal();
            }}
          >
            No
          </Button>
          <Button
            onClick={() => {
              // dispatch({ type: SET_WA_ENQUIRY, payload: { isTrue: true, redirect_to: '/b2b-category'} });
              handleCloseCallModal();
              setSelectedVCategoryUUID(selectedVcategoryUUID);
              history.push('/login');
            }}
            className="btn-save"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openSuccCallModal}
        onClose={handleCloseSuccCallModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle className="custom-border-header pb-0">
          <Box className="position-relative d-flex justify-content-between">
            <Typography
              className="fs-22 fw-bold font-skmodernist"
              id="alert-dialog-title"
            >
              {DIALOG_TITLE}
            </Typography>
            <Box>
              <CloseIcon
                onClick={() => {
                  handleCloseSuccCallModal();
                }}
                style={{ cursor: 'pointer' }}
              />
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {DIALOG_REQEST_SUCC_MSG}
          </DialogContentText>
        </DialogContent>

        <DialogActions className="modal-footer">
          <Button
            onClick={() => {
              handleCloseSuccCallModal();
            }}
            className="btn-save"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {Responsive(
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xl">
            {isLoading && (
              <Backdrop
                sx={{
                  color: '#fff',
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={isLoading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}

            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{ height: 'auto' }}
            >
              <Grid item xs={12}>
                <div className="my-3 ms-2 d-flex align-items-center">
                  <ArrowBackIosIcon
                    onClick={() => {
                      setSelectedVCategoryUUID(null);
                      history.goBack();
                    }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                      alignItems: 'center',
                    }}
                  >
                    <ElynkerLogoComponent>
                      <ElynkerLogo
                        className="ms-3"
                        width={'100px'}
                        height={'30px'}
                      />
                    </ElynkerLogoComponent>
                    <div></div>
                  </div>
                </div>
                <Divider
                  component="div"
                  className="main-divider mt-2 mb-3"
                  role="presentation"
                />

                <form style={{ marginTop: '0px' }}>
                  <Card className="category-card mb-3">
                    <CardContent className="category-content">
                      <Typography
                        variant="h5"
                        className=" text-white mb-3 fw-bold font-skmodernist b2b-category-text"
                        component="div"
                      >
                        Expand your reach by<br></br>
                        connecting with potential <br></br> partners and
                        suppliers.
                      </Typography>
                      <Button
                        variant="contained"
                        className="post-requirement-btn b2b-category-button"
                        onClick={() => {
                          const userID =
                            userData?.user?.id ||
                            sessionStorage.getItem('random_id');
                          const regId =
                            userData?.registration?.uuid ||
                            sessionStorage.getItem('random_id');
                          const cateName = selectedVCategory?.title;
                          const pageTitle = 'Elynker - Business - ' + cateName;
                          GAEvents(
                            'Request_Call_Back',
                            'Request Call Back',
                            'clicked',
                            {
                              page_title: pageTitle,
                              page_location: window.location.href,
                              page_path: window.location.pathname,
                              category_name: cateName,
                              user_id: userID,
                              reg_uuid: regId,
                              title: 'Request A Call Back Button',
                            }
                          );
                          toggleCallBackDrawer();
                        }}
                      >
                        Request a Call Back
                      </Button>
                    </CardContent>
                  </Card>
                  <Breadcrumbs
                    className="mb-3"
                    separator={
                      <NavigateNextIcon
                        fontSize="small"
                        className="text-gray"
                      />
                    }
                    aria-label="breadcrumb"
                  >
                    {breadcrumbs}
                  </Breadcrumbs>

                  <div
                    className={
                      selectedNearBy
                        ? 'my-4 b2b-filter-saved-section b2b-filter-section'
                        : 'my-4 b2b-filter-section'
                    }
                  >
                    <Stack
                      className="b2b-filter-slider"
                      direction="row"
                      spacing={1.5}
                      useFlexGap
                      flexWrap="nowrap"
                      overflow={'auto'}
                    >
                      <Chip
                        icon={<FiltersIcon />}
                        label="Filters"
                        clickable
                        onClick={toggleFilterDrawer}
                      />
                      {/* <Chip
                        ref={nearRef}
                        icon={
                          !selectedNearBy ? <NearMeIcon /> : <NearMeWhiteIcon />
                        }
                        label="Near Me"
                        clickable
                        className={selectedNearBy ? 'MuiChip-saved-root' : ''}
                        onClick={findNearestLocation}
                      /> */}
                      <Chip
                        label="City"
                        onDelete={(e) => {
                          toggleCityDrawer();
                        }}
                        onClick={toggleCityDrawer}
                        deleteIcon={
                          <DropdownIcon
                            fontSize="medium"
                            className="dropdown-icon"
                          />
                        }
                        clickable
                      />
                      <Chip
                        label="Sort By"
                        onDelete={(e) => {
                          toggleSortByDrawer();
                        }}
                        deleteIcon={
                          <DropdownIcon
                            fontSize="medium"
                            className="dropdown-icon"
                          />
                        }
                        onClick={toggleSortByDrawer}
                        clickable
                      />
                    </Stack>
                  </div>
                  {/* Filter drawer starts*/}
                  <Drawer
                    xs={12}
                    anchor="bottom"
                    open={isFilterDrawerOpen}
                    onClose={toggleFilterDrawer}
                    sx={{
                      '& .MuiDrawer-paper': {
                        height: 'auto',
                        borderTopLeftRadius: '20px',
                        borderTopRightRadius: '20px',
                      },
                    }}
                  >
                    <div className="p-3">
                      <div className="d-flex justify-content-between mb-3">
                        <Typography
                          variant="h5"
                          align="left"
                          alignItems="center"
                          className="fs-18 mb-2 fw-bold font-skmodernist "
                          gutterBottom
                        >
                          Filters
                        </Typography>
                        <CloseIcon onClick={toggleFilterDrawer} />
                      </div>

                      <Box
                        sx={{
                          flexGrow: 1,
                          bgcolor: 'background.paper',
                          display: 'flex',
                          height: 250,
                          margin: '0 -16px',
                          borderTop: '1px solid #D9D9D9',
                          borderBottom: '1px solid #D9D9D9',
                        }}
                      >
                        <Tabs
                          orientation="vertical"
                          variant="scrollable"
                          value={value}
                          onChange={handleChange}
                          aria-label="Vertical tabs example"
                          className="filter-tabs"
                          sx={{
                            borderRight: 1,
                            borderColor: 'divider',
                            background: '#F4F4F4',
                            minWidth: '30%',
                          }}
                        >
                          <Tab
                            label="Rating"
                            sx={{ borderBottom: '1px solid #E3E3E3' }}
                            className="fs-15 fw-normal text-black font-skmodernist text-capitalize px-0 text-start"
                            {...a11yProps(0)}
                          />
                          <Tab
                            label="Category"
                            sx={{ borderBottom: '1px solid #E3E3E3' }}
                            className="fs-15 fw-normal text-black font-skmodernist text-capitalize px-0"
                            {...a11yProps(1)}
                          />
                        </Tabs>
                        <TabPanel
                          value={value}
                          index={0}
                          className="filter-tabpanel"
                        >
                          <List
                            class="filter-tabpanel-list"
                            sx={{
                              width: '100%',
                              bgcolor: 'background.paper',
                              padding: '0px',
                            }}
                          >
                            {ratingArr &&
                              ratingArr?.map((rating, index) => {
                                const labelId = `checkbox-list-secondary-label-${index}`;
                                return (
                                  <ListItem
                                    key={index}
                                    secondaryAction={
                                      <Checkbox
                                        edge="end"
                                        onChange={handleRatingToggle(rating)}
                                        checked={
                                          selRating.findIndex(
                                            (x) => x.label === rating.label
                                          ) !== -1
                                        }
                                        inputProps={{
                                          'aria-labelledby': labelId,
                                        }}
                                        sx={{
                                          color: '#D4D4D4',
                                          '&.Mui-checked': {
                                            color: '#416AD4',
                                          },
                                        }}
                                      />
                                    }
                                    disablePadding
                                  >
                                    <ListItemButton sx={{ paddingLeft: '0px' }}>
                                      <ListItemText
                                        id={labelId}
                                        className="fs-15 fw-normal text-black font-skmodernist"
                                        primary={rating?.label}
                                      />
                                    </ListItemButton>
                                  </ListItem>
                                );
                              })}
                          </List>
                        </TabPanel>
                        <TabPanel
                          value={value}
                          index={1}
                          className="filter-tabpanel"
                        >
                          <List
                            class="filter-tabpanel-list"
                            sx={{
                              width: '100%',
                              bgcolor: 'background.paper',
                              padding: '0px',
                            }}
                          >
                            <TreeView
                              nodes={categoriesTree}
                              checked={subCategoryAttributes?.checked}
                              expanded={subCategoryAttributes?.expanded}
                              onCheck={(checked, value) => {
                                console.log('TreeView', checked, value);
                                setSubCategoryTreeAtrributes({ checked });
                                // setChecked(checked);
                                handleCategoryToggle(value);
                                subCategoryAttributes?.checked?.forEach(
                                  (id) => {
                                    selCategory.findIndex(
                                      (x) => x.id === id
                                    ) !== -1;
                                  }
                                );
                              }}
                              onExpand={(expanded) => {
                                setSubCategoryTreeAtrributes({ expanded });
                                handleCategoryToggle({ expanded });
                              }}
                            />
                            {/* {categoriesTree?.map((category, index) => {
                              const labelId = `checkbox-list-secondary-label-${index}`;
                              return (
                                <ListItem
                                  key={index}
                                  secondaryAction={
                                    <Checkbox
                                      edge="end"
                                      onChange={handleCategoryToggle(category)}
                                      checked={
                                        selCategory.findIndex(
                                          (x) => x.id === category.id
                                        ) !== -1
                                      } //selCategory.indexOf(category) !== -1
                                      inputProps={{
                                        'aria-labelledby': labelId,
                                      }}
                                      sx={{
                                        color: '#D4D4D4',
                                        '&.Mui-checked': {
                                          color: '#416AD4',
                                        },
                                      }}
                                    />
                                  }
                                  disablePadding
                                >
                                  <ListItemButton sx={{ paddingLeft: '0px' }}>
                                    <ListItemText
                                      id={labelId}
                                      className="fs-15 fw-normal text-black font-skmodernist"
                                      primary={category?.title}
                                    />
                                  </ListItemButton>
                                </ListItem>
                              );
                            })} */}
                          </List>
                        </TabPanel>
                      </Box>

                      <ButtonGroup className="w-100 justify-content-between mt-3 mb-3">
                        <Button
                          variant="contained"
                          className="call-now-btn"
                          onClick={clearRatingData}
                        >
                          Clear All
                        </Button>
                        <Button
                          variant="contained"
                          className="whatsapp-btn"
                          onClick={handleRatingAndCategoryFilter}
                        >
                          Apply
                        </Button>
                      </ButtonGroup>
                    </div>
                  </Drawer>
                  {/* Filter drawer ends*/}

                  <Drawer
                    xs={12}
                    anchor="bottom"
                    open={isCityDrawerOpen}
                    onClose={toggleCityDrawer}
                    sx={{
                      '& .MuiDrawer-paper': {
                        height: 'auto',
                        borderTopLeftRadius: '20px',
                        borderTopRightRadius: '20px',
                      },
                    }}
                  >
                    <div className="p-3">
                      <div className="d-flex justify-content-between mb-3">
                        <Typography
                          variant="h5"
                          align="left"
                          alignItems="center"
                          className="fs-18 mb-2 fw-bold font-skmodernist "
                          gutterBottom
                        >
                          Select City
                        </Typography>
                        <CloseIcon onClick={toggleCityDrawer} />
                      </div>
                      <div className="mb-3">
                        <Autocomplete
                          freeSolo
                          fullWidth
                          options={[]}
                          onInputChange={(e, value) => {
                            filterListData(value);
                          }}
                          onClose={() => {
                            setFilterCity(allVCity);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="Search your city"
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <SearchIcon
                                    style={{ margin: '8px', width: '30px' }}
                                  />
                                ),
                                type: 'search',
                              }}
                            />
                          )}
                        />
                      </div>
                      <Typography
                        variant="h4"
                        align="left"
                        alignItems="center"
                        className="fs-14 text-grey fw-normal font-gotham"
                      >
                        Popular
                      </Typography>
                      <List
                        dense
                        sx={{
                          width: '100%',
                          bgcolor: 'background.paper',
                          height: '220px',
                          overflowY: 'scroll',
                        }}
                      >
                        {filterVCity &&
                          filterVCity?.map((city, index) => {
                            const labelId = `checkbox-list-secondary-label-${index}`;
                            return (
                              <ListItem
                                key={index}
                                secondaryAction={
                                  <Checkbox
                                    edge="end"
                                    onChange={handleCityToggle(city)}
                                    checked={selCity.indexOf(city) !== -1}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                    sx={{
                                      color: '#D4D4D4',
                                      '&.Mui-checked': {
                                        color: '#416AD4',
                                      },
                                    }}
                                  />
                                }
                                disablePadding
                              >
                                <ListItemButton sx={{ paddingLeft: '0px' }}>
                                  <ListItemText
                                    id={labelId}
                                    className="fs-15 fw-normal text-black font-skmodernist"
                                    primary={city}
                                  />
                                </ListItemButton>
                              </ListItem>
                            );
                          })}
                      </List>

                      <ButtonGroup className="w-100 justify-content-between mt-3 mb-3">
                        <Button
                          variant="contained"
                          className="call-now-btn"
                          onClick={clearAllCity}
                        >
                          Clear All
                        </Button>
                        <Button
                          variant="contained"
                          className="whatsapp-btn"
                          onClick={handleSelectedCity}
                        >
                          Apply
                        </Button>
                      </ButtonGroup>
                    </div>
                  </Drawer>

                  <Drawer
                    xs={12}
                    anchor="bottom"
                    open={isSortByDrawerOpen}
                    onClose={toggleSortByDrawer}
                    sx={{
                      '& .MuiDrawer-paper': {
                        height: 'auto',
                        borderTopLeftRadius: '20px',
                        borderTopRightRadius: '20px',
                      },
                    }}
                  >
                    <div className="p-3">
                      <div className="d-flex justify-content-between mb-3">
                        <Typography
                          variant="h5"
                          align="left"
                          alignItems="center"
                          className="fs-18 mb-2 fw-bold font-skmodernist "
                          gutterBottom
                        >
                          Sort By
                        </Typography>
                        <CloseIcon onClick={toggleSortByDrawer} />
                      </div>

                      <List
                        dense
                        sx={{ width: '100%', bgcolor: 'background.paper' }}
                      >
                        {sortArr &&
                          sortArr?.map((option, index) => {
                            const labelId = `radio-list-secondary-label-${index}`;
                            return (
                              <ListItem
                                key={index}
                                secondaryAction={
                                  <Radio
                                    edge="end"
                                    onChange={() => handleSortChange(option)}
                                    checked={selSort?.label === option?.label} //selRating.findIndex(x => x.label === rating.label) !== -1
                                    inputProps={{ 'aria-labelledby': labelId }}
                                    sx={{
                                      color: '#D4D4D4',
                                      '&.Mui-checked': {
                                        color: '#416AD4',
                                      },
                                    }}
                                  />
                                }
                                disablePadding
                              >
                                <ListItemButton sx={{ paddingLeft: '0px' }}>
                                  <ListItemText
                                    id={labelId}
                                    className="fs-15 fw-normal text-black font-skmodernist"
                                    primary={option.label}
                                  />
                                </ListItemButton>
                              </ListItem>
                            );
                          })}
                      </List>

                      <ButtonGroup className="w-100 justify-content-between mt-3 mb-3">
                        <Button
                          variant="contained"
                          className="call-now-btn"
                          onClick={clearSortData}
                        >
                          Clear All
                        </Button>
                        <Button
                          variant="contained"
                          className="whatsapp-btn"
                          onClick={handleSelectedSort}
                        >
                          Apply
                        </Button>
                      </ButtonGroup>
                    </div>
                  </Drawer>

                  <Typography
                    variant="h4"
                    align="left"
                    alignItems="center"
                    className="fs-20 text-black mb-3 fw-bold font-skmodernist"
                  >
                    Wholesale and Suppliers
                  </Typography>
                  <List>
                    {vendors &&
                      vendors?.map((item, index) => {
                        let last_update = moment(item?.last_login)?.fromNow();
                        return (
                          <ListItem
                            className="b2b-list-item"
                            key={index}
                            style={
                              item?.status == 'approved'
                                ? { cursor: 'pointer' }
                                : {}
                            }
                          >
                            <Card
                              className="card"
                              onClick={() => {
                                // getVendorDetails(item.id);
                                if (item?.uuid && item?.status == 'approved') {
                                  history.push('/b2b-detail' + `/${item.uuid}`);
                                } else {
                                  pushMessage(DIALOG_VENDOR_DETAIL_SCRN__MSG);
                                }
                              }}
                            >
                              <div className="d-flex justify-content-between">
                                <CardMedia
                                  className="category-media"
                                  image={
                                    item?.image_path
                                      ? `${COMPANY_LOGO_BASE_URL}${item.image_path}`
                                      : // eslint-disable-next-line no-undef
                                        defaultProfileImage
                                  }
                                  sx={{
                                    padding: '1em 1em 0 1em',
                                    objectFit: 'contain',
                                  }}
                                />
                                <CardContent className="content p-0">
                                  <div className="d-flex justify-content-between align-items-start w-100">
                                    <Typography
                                      variant="h5"
                                      className="fs-16 text-black mb-1 fw-bold font-skmodernist"
                                      component="div"
                                    >
                                      {item?.company_name || ''}
                                    </Typography>
                                    <div>
                                      {item?.status == 'approved' && (
                                        <RightArrowIcon />
                                      )}
                                    </div>
                                  </div>
                                  <Typography
                                    variant="body2"
                                    className="fs-13 text-grey mb-1 font-gotham"
                                  >
                                    {item?.business_description || ''}
                                  </Typography>

                                  <Typography
                                    variant="body2"
                                    className="fs-13 text-secondary mb-1 font-gotham"
                                  >
                                    City : {item?.city}
                                  </Typography>
                                  <Box>
                                    <Rating
                                      name="read-only"
                                      value={item?.rating}
                                      size="small"
                                      precision={0.1}
                                      readOnly
                                    />
                                  </Box>
                                </CardContent>
                              </div>
                              <CardContent className="p-0  mt-1">
                                <Box className="d-flex justify-content-between">
                                  <div className="d-flex flex-column">
                                    <div className="d-flex align-items-center mb-1">
                                      <CircleIcon
                                        style={{
                                          color: '#1843A0',
                                          width: '7px',
                                          height: '7px',
                                          marginRight: '10px',
                                        }}
                                      />
                                      <Typography
                                        variant="body2"
                                        className="fs-13 text-darkgrey mb-1 font-skmodernist"
                                      >
                                        Last seen{' '}
                                        {last_update !== 'Invalid date'
                                          ? last_update
                                          : ''}
                                      </Typography>
                                    </div>
                                  </div>
                                  <div className="d-flex flex-column">
                                    <Typography
                                      variant="body2"
                                      className="fs-13 text-darkgrey mb-1 font-skmodernist"
                                    >
                                      Members
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      className="fs-13 text-secondary mb-1 font-skmodernist"
                                    >
                                      {item?.member_count || 0}
                                    </Typography>
                                  </div>
                                  <div className="d-flex flex-column">
                                    <Typography
                                      variant="body2"
                                      className="fs-13 text-darkgrey mb-1 font-skmodernist"
                                    >
                                      Products
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      className="fs-13 text-secondary mb-1 font-skmodernist"
                                    >
                                      {item?.product_count || 0}
                                    </Typography>
                                  </div>
                                </Box>
                                <Box>
                                  <Typography
                                    variant="body2"
                                    className="fs-16 fw-bold text-secondary mb-1 font-skmodernist"
                                  >
                                    {item?.status !== 'approved'
                                      ? 'Un-Verified'
                                      : ''}
                                  </Typography>
                                </Box>
                              </CardContent>

                              {item?.status == 'approved' && (
                                <>
                                  <Divider
                                    component="div"
                                    className="mt-2 mb-3"
                                    role="presentation"
                                  />
                                  <CardContent className="p-0 d-flex justify-content-between">
                                    <Button
                                      variant="contained"
                                      className="call-now-btn"
                                      onClick={(e) => {
                                        const mobileNo = item?.mobile_number
                                          ? '<mobile_no>'
                                          : '';
                                        GAEvents(
                                          'Call_button',
                                          'Call button',
                                          'clicked',
                                          {
                                            uuid: item?.uuid || '',
                                            company_name:
                                              item?.company_name || '',
                                            vendor_id: item?.id,
                                            vendor_uuid: item?.uuid || '',
                                            phone: mobileNo,
                                          }
                                        );
                                        handleCallNow(
                                          e,
                                          item?.mobile_number,
                                          item?.country_code || '',
                                          item?.id
                                        );
                                      }}
                                      disabled={item?.status !== 'approved'}
                                    >
                                      <CallNowIcon className="me-2" />
                                      Call Now
                                    </Button>
                                    <Button
                                      variant="contained"
                                      className="whatsapp-btn"
                                      onClick={(e) => {
                                        const whatsappNo = item?.whatsapp_number
                                          ? '<whatsapp_no>'
                                          : '';
                                        GAEvents(
                                          'Whatsapp_chat_button',
                                          'Whatsapp chat button',
                                          'clicked',
                                          {
                                            uuid: item?.uuid || '',
                                            company_name:
                                              item?.company_name || '',
                                            vendor_id: item?.id,
                                            vendor_uuid: item?.uuid || '',
                                            whatsapp: whatsappNo,
                                          }
                                        );
                                        handleWhatsapp(
                                          e,
                                          item?.whatsapp_number,
                                          item?.country_code || '',
                                          item?.id
                                        );
                                      }}
                                      disabled={item?.status !== 'approved'}
                                    >
                                      <WhatsappIcon className="me-2" />
                                      WhatsApp
                                    </Button>
                                  </CardContent>
                                </>
                              )}
                            </Card>
                          </ListItem>
                        );
                      })}
                  </List>
                </form>
              </Grid>
            </Grid>
          </Container>
        </ThemeProvider>,
        <WebB2BCategoryForm
          nearRef={nearRef}
          isLoading={isLoading}
          SearchComponent={SearchComponent}
          toggleCallBackDrawer={toggleCallBackDrawer}
          errors={errors}
          categoriesTree={categoriesTree}
          handleCategoryToggle={handleCategoryToggle}
          selCategory={selCategory}
          clearRatingData={clearRatingData}
          handleRatingAndCategoryFilter={handleRatingAndCategoryFilter}
          ratingArr={ratingArr}
          sortArr={sortArr}
          handleRatingToggle={handleRatingToggle}
          selRating={selRating}
          vendors={vendors}
          handleCallNow={handleCallNow}
          handleWhatsapp={handleWhatsapp}
          clearFilter={clearFilter}
          handleTerms={handleTerms}
          handlePrivacy={handlePrivacy}
          openInNewTab={openInNewTab}
          selectedVCategory={selectedVCategory}
          BuisnessType={true}
          handleShippingDelivery={handleShippingDelivery}
          handleCancellationRefund={handleCancellationRefund}
          handleContactUs={handleContactUs}
          handleClickHome={handleClickHome}
          handleClickCategory={handleClickCategory}
          selCity={selCity}
          setSelCity={setSelCity}
          nearByCity={nearByCity}
          setSelRating={setSelRating}
          setSelCategory={setSelCategory}
          selSort={selSort}
          setSelectedSort={setSelectedSort}
          getVendorList={getVendorList}
          handleCityToggle={handleCityToggle}
          filterVCity={filterVCity}
          allVCity={allVCity}
          setFilterVCity={setFilterVCity}
          clearSortData={clearSortData}
          clearAllCity={clearAllCity}
          pushMessage={pushMessage}
          setFilterParam={setFilterParam}
          subCategoryAttributes={subCategoryAttributes}
          setSubCategoryTreeAtrributes={setSubCategoryTreeAtrributes}
          getSubCategoryVendorList={getSubCategoryVendorList}
          userData={userData}
          loading={loading}
          setSelectedVCategoryUUID={setSelectedVCategoryUUID}
          setEnquiryData={setEnquiryData}
        />
      )}
    </>
  );
};

B2BCategoryForm.propTypes = {
  userData: PropTypes.object,
  vendors: PropTypes.array,
  getVendorList: PropTypes.func,
  loading: PropTypes.func,
  isLoading: PropTypes.bool,
  postEnquiry: PropTypes.func,
  enquiry: PropTypes.object,
  pushMessage: PropTypes.func,
  getEnquiryById: PropTypes.func,
  getCityMaster: PropTypes.func,
  allCity: PropTypes.array,
  b2bprodCategories: PropTypes.array,
  filterCity: PropTypes.array,
  setFilterCity: PropTypes.func,
  selectedVCategory: PropTypes.object,
  getVendorDetails: PropTypes.func,
  allVCity: PropTypes.array,
  setFilterVCity: PropTypes.func,
  filterVCity: PropTypes.array,
  postMemberContacted: PropTypes.func,
  setFilterCategory: PropTypes.func,
  setFilterSort: PropTypes.func,
  setVendorFilterCity: PropTypes.func,
  setFilterRating: PropTypes.func,
  saveFilterCity: PropTypes.array,
  saveFilterRating: PropTypes.array,
  saveFilterCategory: PropTypes.array,
  saveFilterSort: PropTypes.object,
  saveFilterNearMe: PropTypes.string,
  setFilterNearMe: PropTypes.func,
  setNearByClickState: PropTypes.func,
  markNearByClick: PropTypes.bool,
  searchVendor: PropTypes.array,
  saveSearchText: PropTypes.func,
  searchVendors: PropTypes.func,
  handleTerms: PropTypes.any,
  handlePrivacy: PropTypes.any,
  openInNewTab: PropTypes.func,
  setTemplateRender: PropTypes.func,
  handleShippingDelivery: PropTypes.func,
  handleCancellationRefund: PropTypes.func,
  handleContactUs: PropTypes.func,
  resetTemplateData: PropTypes.func,
  getProductCategories: PropTypes.func,
  selectedLvl1Categories: PropTypes.array,
  getSubCategoryVendorList: PropTypes.func,
  setSubCategoryTreeAtrributes: PropTypes.func,
  subCategoryAttributes: PropTypes.any,
  setSelectedVCategoryUUID: PropTypes.func,
  selectedVcategoryUUID: PropTypes.string,
  setEnquiryData: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { userData } = state.signin;
  const {
    vendors,
    enquiry,
    allVCity,
    filterVCity,
    saveFilterRating,
    saveFilterCategory,
    saveFilterCity,
    saveFilterNearMe,
    saveFilterSort,
    markNearByClick,
    subCategoryAttributes,
  } = state.b2bVendorlisting;
  const { isLoading } = state.root;
  const { allCity, filterCity } = state.profileDetails;
  const { b2bprodCategories, selectedLvl1Categories } = state.offeredServices;
  const { selectedVCategory, selectedVcategoryUUID } = state.b2bBusinessReducer;
  const { searchVendor } = state.solrSearch;
  return {
    userData,
    vendors,
    isLoading,
    allCity,
    filterCity,
    b2bprodCategories,
    selectedVCategory,
    enquiry,
    allVCity,
    filterVCity,
    saveFilterRating,
    saveFilterCategory,
    saveFilterCity,
    saveFilterNearMe,
    saveFilterSort,
    markNearByClick,
    searchVendor,
    subCategoryAttributes,
    selectedLvl1Categories,
    selectedVcategoryUUID,
  };
};

export default connect(mapStateToProps, {
  getVendorList,
  loading,
  postEnquiry,
  pushMessage,
  getEnquiryById,
  getCityMaster,
  setFilterCity,
  getVendorDetails,
  setFilterVCity,
  postMemberContacted,
  setFilterCategory,
  setFilterSort,
  setFilterRating,
  setVendorFilterCity,
  setFilterNearMe,
  setNearByClickState,
  saveSearchText,
  searchVendors,
  setTemplateRender,
  resetTemplateData,
  getProductCategories,
  getSubCategoryVendorList,
  setSubCategoryTreeAtrributes,
  setSelectedVCategoryUUID,
  setEnquiryData,
})(B2BCategoryForm);
